var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "4", justify: "center", align: "center" } },
            [
              _c("v-icon", { staticClass: "mx-1" }, [
                _vm._v(_vm._s(_vm.iconOne)),
              ]),
              _c("v-icon", { staticClass: "mx-1" }, [
                _vm._v(_vm._s(_vm.iconTwo)),
              ]),
              _c("v-icon", { staticClass: "mx-1" }, [
                _vm._v(_vm._s(_vm.iconThree)),
              ]),
              _c("v-icon", { staticClass: "mx-1" }, [
                _vm._v(_vm._s(_vm.iconFour)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "end", align: "center" } },
        [
          _vm._l(_vm.numberField, function (i) {
            return _c(
              "v-col",
              { key: i, attrs: { cols: "4" } },
              [
                _c(
                  "v-card",
                  { attrs: { height: "10vh", outlined: "", tile: "" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticStyle: { "font-size": "2em" },
                        attrs: { block: "", height: "100%" },
                        on: {
                          click: function ($event) {
                            return _vm.emitInput(i)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(i) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                { attrs: { height: "10vh", outlined: "", tile: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "font-size": "2em" },
                      attrs: { block: "", height: "100%" },
                      on: { click: _vm.deleteLastChar },
                    },
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v("fas fa-backspace"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }