import { make } from "vuex-pathify";

const getDefaultState = () => {
  return {
    showSettings: false,
    navigationIndex: null
  };
};

const state = getDefaultState();

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  ...make.mutations(state)
};

export default {
  namespaced: true,
  state,
  mutations
};
