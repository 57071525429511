var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { attrs: { grow: "", centered: "", "icons-and-text": "" } },
    [
      _c(
        "v-tab",
        [
          _vm._v("Daten des Mitarbeiter"),
          _c("v-icon", [_vm._v("mdi-account-edit")]),
        ],
        1
      ),
      _c(
        "v-tab",
        [_vm._v("Produkte"), _c("v-icon", [_vm._v("mdi-account-cog")])],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c("UserDetails", {
            attrs: { user: _vm.user, showDeleteButton: true },
            on: {
              deselectUser: _vm.deselectUser,
              deleted: _vm.deleteUser,
              updated: _vm.updateUser,
            },
          }),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c("UserProductsAssignment", {
            attrs: { user: _vm.user, products: _vm.products },
            on: {
              updated: _vm.updateUser,
              productChoice: _vm.productChoice,
              favouriteChoice: _vm.favouriteChoice,
              deselectUser: _vm.deselectUser,
              unassignAllProducts: _vm.unassignAllProducts,
              removeAllFavourites: _vm.removeAllFavourites,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }