<template>
  <v-tabs v-model="tab" icons-and-text>
    <v-tab>
      Dashboard
      <v-icon>
        $vuetify.icons.calendarDay
      </v-icon>
    </v-tab>
    <v-tab>
      Geräteliste
      <v-icon>
        $vuetify.icons.calendarMonth
      </v-icon>
    </v-tab>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <ControlDashboard />
      </v-tab-item>
      <v-tab-item>
        <ControlDevices />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { ref } from "@vue/composition-api";
import ControlDashboard from "@/components/Control/Dashboard";
import ControlDevices from "@/components/Control/Devices";

export default {
  components: {
    ControlDashboard,
    ControlDevices
  },
  setup(props, { emit }) {
    const tab = ref(null);
    return { tab };
  }
};
</script>
