var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("UserTable", {
                    attrs: {
                      users: _vm.users,
                      loading: _vm.loading,
                      isUserSelected: _vm.isUserSelected,
                    },
                    on: {
                      userSelected: _vm.handleUserSelected,
                      addUser: _vm.addUser,
                      getUsersServerless: _vm.getUsersServerless,
                    },
                  }),
                ],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-col",
                [
                  _vm.selectedUser == null
                    ? _c(
                        "v-card",
                        [
                          _c("v-card-text", [
                            _vm._v("Kein Mitarbeiter ausgewählt"),
                          ]),
                        ],
                        1
                      )
                    : _c("UserDetailsTabs", {
                        attrs: {
                          user: _vm.selectedUser,
                          products: _vm.products,
                        },
                        on: {
                          deleted: _vm.deleteUser,
                          updated: _vm.updateUser,
                          productChoice: _vm.productChoice,
                          favouriteChoice: _vm.favouriteChoice,
                          deselectUser: _vm.handleDeselectUser,
                          unassignAllProducts: _vm.unassignAllProducts,
                          removeAllFavourites: _vm.removeAllFavourites,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("ConnectedUsers")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }