var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { loading: _vm.loading } },
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            { staticClass: "text-center", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("v-icon", [_vm._v("mdi-account-multiple")])],
                1
              ),
              _c("v-col", { staticClass: "pa-0", attrs: { cols: "12" } }, [
                _vm._v(" Account-Verwaltung "),
              ]),
              _c("v-col", { attrs: { cols: "12" } }, [_c("v-divider")], 1),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.users,
              search: _vm.search,
              "sort-by": "name",
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function (ref) {
                  var items = ref.items
                  return [
                    _c(
                      "tbody",
                      _vm._l(items, function (item) {
                        return _c(
                          "tr",
                          {
                            key: item.username,
                            staticClass: "pointerCursor",
                            class: { selectedRow: item === _vm.selectedUser },
                            on: {
                              click: function ($event) {
                                return _vm.selectItem(item)
                              },
                            },
                          },
                          [
                            _c("td", [_vm._v(_vm._s(item.name))]),
                            _c("td", [_vm._v(_vm._s(item.username))]),
                            _c(
                              "td",
                              [_c("v-icon", [_vm._v("fa-chevron-right")])],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            "append-icon": "search",
                            label: "Suche",
                            "single-line": "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500px", persistent: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              tile: "",
                                              color: "primary",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" Mitarbeiter hinzufügen ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.dialog,
                              callback: function ($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog",
                            },
                          },
                          [
                            _c(
                              "UserDetails",
                              {
                                attrs: {
                                  user: _vm.editedItem,
                                  showDeleteButton: false,
                                },
                                on: { submitted: _vm.addUser },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "error" },
                                    on: { click: _vm.close },
                                  },
                                  [_vm._v("Abbrechen")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }