<template>
  <v-card tile :loading="gsmtp.loading" @click="$listeners['click']">
    <div class="text-center pt-3">
      <v-icon large :color="gsmtp.available ? 'green' : 'red'">
        {{ icon }}
      </v-icon>
    </div>
    <v-card-title style="white-space: nowrap">
      {{ gsmtp.name }}
    </v-card-title>
    <v-card-subtitle>
      <v-row dense>
        <v-col>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" color="#616161" v-on="on">
                timeline
              </v-icon>
              {{ gsmtp.stats.MessagesPerMinute }}
            </template>
            <span>Meldungen pro Minute</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          {{ gsmtp.environment }}
        </v-col>
      </v-row>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class GSMTPTile extends Vue {
  @Prop({ default: "$vuetify.icons.gsmtp" }) icon;
  @Prop() gsmtp;
}
</script>
