import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_URL
});

api.interceptors.request.use(
  request => {
    if (request.data == null) {
      request.data = {};
    }
    request.data.token =
      "aGFkZmg1aGFocmhhZ2poYWp0amg1NGhqenJqcmp6cjpnczUzMzc3Iw";
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let users = [];

// PRODUKTE-LISTE_ALLER_EPs_UND_ZPs
let products = [];

export default {
  users,
  products,
  amountOfProducedItems: 0,
  result: undefined,
  delete: function (user) {
    return api.post(
      "/pde-delete-user",
      { userid: user.id },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  insert: function (user) {
    return api.post("/pde-add-user", user, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json"
      }
    });
  },
  update: function (user) {
    return api.post(
      "/pde-update-user",
      {
        id: user.id,
        name: user.name,
        username: user.username,
        password: user.password,
        allProducts: user.allProducts
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  GetUserProductions: function (userId, maxDays) {
    return api.post(
      "/pde-get-user-productions",
      { userid: userId, maxDays: maxDays },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  InsertProductInUserProductList: function (userId, itemId) {
    return api.post(
      "/pde-assign-product",
      { userid: userId, productid: itemId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  RemoveProductInUserProductList: function (userId, itemId) {
    return api.post(
      "/pde-unassign-product",
      { userid: userId, productid: itemId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  UnassignAllProducts: function (userId) {
    return api.post(
      "/pde-unassign-all-products",
      { userid: userId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  RemoveAllFavourites: function (userId) {
    return api.post(
      "/pde-clear-favourites",
      { userid: userId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  InsertProductInUserFavouriteList: function (userId, itemId, isFavourite) {
    return api.post(
      "/pde-set-favourite",
      { userid: userId, productid: itemId, value: isFavourite },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  getUsersServerless(assignedProducts) {
    return api.post("/pde-get-all-users", { ReturnProductList: assignedProducts }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json"
      }
    });
  },
  getProductsServerless: function () {
    return api.post("/pde-get-all-products", null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json"
      }
    });
  },
  getAssignedProducts(userId) {
    return api.post(
      "/pde-get-assigned-products",
      { userid: userId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  getAssignments(userId) {
    return api.post(
      "/pde-get-assignments",
      { userid: userId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  getProductTree: function (value) {
    return api.post(
      "/pde-get-product-tree",
      { productid: value },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },

  addDirectory: function (name, userId, iconId, colorId) {
    return api.post(
      "/pde-add-directory",
      { name: name, userid: userId, iconId: iconId, colorId: colorId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },

  removeDirectory: function (id) {
    return api.post(
      "/pde-delete-directory",
      { directoryId: id },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },

  addToDirectory: function (userId, productId, directoryId) {
    return api.post(
      "/pde-assign-product-to-directory",
      { userId: userId, productId: productId, directoryId: directoryId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },

  removeFromDirectory: function (userId, productId, directoryId) {
    return api.post(
      "/pde-unassign-product-from-directory",
      { userId: userId, productId: productId, directoryId: directoryId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },

  updateSort: function (userId, products) {
    return api.post(
      "/pde-sort-assigned-products",
      { userid: userId, items: products },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  updateDirectory: function (directoryId, directoryName, iconId, colorId) {
    return api.post(
      "/pde-update-directory",
      {
        id: directoryId,
        name: directoryName,
        iconid: iconId,
        colorid: colorId
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  checkPassword: function (userId, password) {
    return api.post(
      "/pde-check-password",
      { userid: userId, password: password },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  setAmountOfProducedItems: function (
    userIdVal,
    productIdVal,
    amountVal,
    timestampVal
  ) {
    return api.post(
      "/pde-add-production",
      {
        userid: userIdVal,
        productId: productIdVal,
        amount: amountVal,
        timestamp: timestampVal
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  }
};
