<template>
  <div>
    <xlsx-read :file="file">
      <xlsx-json @parsed="parsed">
        <v-container>
          <v-row class="mainRow">
            <v-col :cols="4" order="first">
              <transition name="fade">
                <v-card
                  v-if="leftTable"
                  class="colCard mt-1"
                  :elevation="cardElevation"
                >
                  <v-row dense>
                    <v-col>
                      <v-tooltip transition="fade-transition" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <h3 v-bind="attrs" class="mt-4 mb-2" v-on="on">
                            Eingabe
                          </h3>
                        </template>
                        <span>
                          Bereits nach Merker X gefiltert und nach "Zuletzt bei
                          GS Eing. / auf X" sortiert
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="tableRow">
                    <v-col :cols="12">
                      <v-simple-table height="38em" class="simpleTable">
                        <tr>
                          <th>Nummer</th>
                          <th>Callnummer</th>
                          <th>Projektnummer</th>
                        </tr>
                        <tr v-for="(e2, index, idx) in inputArray" :key="idx">
                          <td>{{ index + 1 }}</td>
                          <td>{{ e2.Callnummer }}</td>
                          <td>{{ e2.Projektnummer }}</td>
                        </tr>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card>
              </transition>
            </v-col>
            <v-col :cols="4" order="last">
              <transition name="fade">
                <v-card
                  v-if="rightTable"
                  class="colCard mt-1"
                  :elevation="cardElevation"
                >
                  <v-row dense>
                    <v-col>
                      <v-tooltip transition="fade-transition" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <h3 v-bind="attrs" class="mt-4 mb-2" v-on="on">
                            Ausgabe
                          </h3>
                        </template>
                        <span>
                          Vorschau der fertigen CSV Datei
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="tableRow">
                    <v-col :cols="12">
                      <v-simple-table
                        id="outTableId"
                        ref="outTable"
                        height="38em"
                        class="simpleTable"
                      >
                        <tr
                          v-for="(outTableArray,
                          index,
                          i) in inArrayFinalFiltered"
                          :key="i"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>"{{ outTableArray.Callnummer }}"</td>
                          <td>"{{ hexAdress }}"</td>
                          <td>"{{ dataLength }}"</td>
                          <td>"{{ recordType }}"</td>
                        </tr>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card>
              </transition>
            </v-col>
            <v-col :cols="4">
              <v-card class="colCard mt-1" :elevation="cardElevation">
                <v-row class="mx-auto">
                  <h3 id="manual" class="mt-4 mb-2">Bearbeitung</h3>
                </v-row>
                <v-row class="interactiveRow mt-2 mb-auto">
                  <v-file-input
                    v-model="file"
                    class="mx-0"
                    label="Excel Datei aus CRM"
                    placeholder="Datei hochladen..."
                    clear-icon=""
                    prepend-icon=""
                    append-icon=""
                    append-outer-icon="fas fa-upload"
                    outlined
                    dense
                    accept=".xlsx, .xls, .csv"
                    @change="onChange"
                  />
                </v-row>
                <v-row class="interactiveRow mt-1">
                  <v-combobox
                    v-model="inputPN"
                    :rules="rulesPN"
                    :items="uniqueProjNr"
                    :disabled="!leftTable"
                    label="Projektnummer"
                    placeholder=" "
                    append-icon=""
                    append-outer-icon="fas fa-filter"
                    outlined
                    dense
                    maxlength="6"
                    class="CallnummerField mt-1"
                    @change="dataFilter"
                  />
                </v-row>
                <v-row class="interactiveRow mt-1">
                  <v-combobox
                    v-model="inputStartAtCN"
                    :items="uniqueCallNr"
                    :disabled="!rightTable"
                    :rules="rulesCN"
                    label="Callnummer"
                    placeholder=" "
                    append-icon=""
                    :hide-details="!rightTable"
                    append-outer-icon="fas fa-greater-than-equal"
                    outlined
                    dense
                    maxlength="6"
                    class="CallnummerField mt-1"
                    @change="limitCN"
                  />
                </v-row>
                <v-row class="downloadButton">
                  <v-btn
                    :disabled="!rightTable"
                    class="my-6"
                    color="primary"
                    @click="tableToExcel"
                  >
                    Tabelle Herunterladen
                  </v-btn>
                  <vue-blob-json-csv
                    id="excelDownload"
                    class="invisibleButtons"
                    tag-name="button"
                    file-type="csv"
                    :file-name="fileName"
                    title="test"
                    :data="outResult"
                  />
                </v-row>
                <v-row class="interactiveRow mt-4">
                  <v-switch
                    v-model="advancedEdit"
                    :disabled="!rightTable"
                    @mousedown="advancedChange"
                  >
                    <template v-slot:label>
                      <div>
                        <strong class="primary--text text--lighten-1">
                          weitere Optionen
                        </strong>
                      </div>
                    </template>
                  </v-switch>
                </v-row>
                <v-row class="interactiveRow">
                  <v-text-field
                    v-model="hexAdressInput"
                    :disabled="!advancedEdit"
                    label="Adresse"
                    append-icon=""
                    append-outer-icon="fas fa-location-arrow"
                    placeholder="FFE0"
                    outlined
                    dense
                    maxlength="8"
                    class="my-0"
                    @change="hexInput"
                  >
                  </v-text-field>
                </v-row>
                <v-row class="interactiveRow">
                  <v-select
                    v-model="dataLengthInput"
                    :disabled="!advancedEdit"
                    outlined
                    dense
                    append-icon=""
                    append-outer-icon="fas fa-list-ol"
                    class="my-1 pt-0"
                    label="Datenlänge"
                    :items="dataLengthList"
                    placeholder="3"
                    editable
                    @change="lengthInput"
                  ></v-select>
                </v-row>
                <v-row class="interactiveRow">
                  <v-select
                    v-model="recordTypeInput"
                    :disabled="!advancedEdit"
                    outlined
                    dense
                    append-icon=""
                    append-outer-icon="fas fa-project-diagram"
                    class="my-1"
                    label="Record Type"
                    :items="recordTypeList"
                    placeholder="B"
                    editable
                    @change="recordInput"
                  />
                </v-row>
                <v-row class="ResetButton mt-1">
                  <v-dialog v-model="dialogState" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        class="mb-1"
                        v-on="on"
                      >
                        Zurücksetzen
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Wirklich alle Eingaben zurücksetzten?
                      </v-card-title>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="dialogState = false"
                        >
                          Abbrechen
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="(dialogState = false), reloadPage()"
                        >
                          Zurücksetzen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </xlsx-json>
    </xlsx-read>
  </div>
</template>

<script src="FileSaver.js"></script>
<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import { XlsxRead, XlsxJson } from "vue-xlsx";
import { get } from "vuex-pathify";
const HtmlTableToJson = require("html-table-to-json");
export default {
  components: {
    XlsxRead,
    XlsxJson
  },
  setup(props, { emit }) {
    const fileName = ref("NamingError");
    const file = ref(null);
    const inArrayFinal = ref([]);
    const outResult = ref([]);
    const uniqueProjNr = ref([]);
    const uniqueCallNr = ref([]);
    const inputArray = ref([]);
    const resultArrayPN = ref([]);
    const recordTypeList = ref(["B", "L", "R"]);
    const leftTable = ref(false);
    const rightTable = ref(false);
    const advancedEdit = ref(false);
    const dialogState = ref(false);
    const inputPN = ref("");
    const inputStartAtCN = ref("");
    const formatedDate = ref("");
    const hexAdressInput = ref("");
    const dataLengthInput = ref("");
    const recordTypeInput = ref("");
    const dataLengthList = ref("");
    const hexAdress = ref("FFE0");
    const dataLength = ref("3");
    const recordType = ref("B");
    const rulesPN = ref([
      value => value.length >= 6 || "Projektnummern haben 6 Zeichen",
      value =>
        uniqueProjNr.value.length > 1 ||
        "Keine Projektnummern in dieser Exceltabelle",
      value =>
        uniqueProjNr.value.includes(value) ||
        "Diese Projektnummer ist nicht in der Exceltabelle enthalten"
    ]);

    const rulesCN = ref([
      value => value.length >= 6 || "Callnummern haben 6 Zeichen"
    ]);

    const inArrayFinalFiltered = computed(() => {
      return inArrayFinal.value.slice(0, 2000);
    });
    const cardElevation = computed(() => store.get("settings/cardElevation"));

    onMounted(() => {
      getDate();
      dataLengthList.value = Array.from(Array(20), (x, index) => index + 1);
    });
    function parsed(e) {
      inputArray.value = e;
      var projNrPreSort = [
        ...new Set(inputArray.value.map(item => item.Projektnummer))
      ];
      if (projNrPreSort.length > 1) {
        uniqueProjNr.value = projNrPreSort.sort();
      }
      var arr = JSON.parse(JSON.stringify(e).replace(/. /g, ""));
      var preFilterArray = arr.sort(
        (a, b) => a.ZuletzbeGEingauX - b.ZuletzbeGEingauX
      );
      inputArray.value = preFilterArray.filter(function(d) {
        return d.Merker == "X";
      });
      leftTable.value = true;
    }

    function onChange() {
      file.value = event.target.files ? event.target.files[0] : null;
      uniqueProjNr.value = [];
      leftTable.value = false;
    }
    function dataFilter() {
      var selProjNumber = inputPN.value;
      if (uniqueProjNr.value.includes(selProjNumber)) {
        rightTable.value = true;
        resultArrayPN.value = inputArray.value.filter(function(e) {
          return e.Projektnummer == selProjNumber;
        });
        inArrayFinal.value = resultArrayPN.value;
        fileName.value = formatedDate.value + "_" + selProjNumber;
        uniqueCallNr.value = [
          ...new Set(resultArrayPN.value.map(item => item.Callnummer))
        ];
      } else {
        rightTable.value = false;
      }
    }

    function limitCN() {
      var selStartAtCn = inputStartAtCN.value;
      var arrayCNOutput = inArrayFinal.value;
      var indexCN = arrayCNOutput.findIndex(x => x.Callnummer === selStartAtCn);
      var arrayLength = arrayCNOutput.length;
      if (indexCN >= 0) {
        inArrayFinal.value = arrayCNOutput.slice(indexCN);
      } else {
        dataFilter();
      }
    }
    function tableToExcel() {
      var outTableId = document.getElementById("outTableId");
      var outHtml = outTableId.outerHTML;
      var outJsonTables = HtmlTableToJson.parse(outHtml, { values: false });
      var outResultString = JSON.stringify(outJsonTables.results);
      var outResultStringSlice = outResultString.slice(1, -1);
      outResult.value = JSON.parse(outResultStringSlice);
      setTimeout(() => document.getElementById("excelDownload").click(), 10);
    }
    function recordInput() {
      recordType.value = recordTypeInput.value;
    }
    function hexInput() {
      hexAdress.value = hexAdressInput.value;
    }
    function lengthInput() {
      dataLength.value = dataLengthInput.value;
    }
    function advancedChange() {
      if (advancedEdit.value == true) {
        hexAdress.value = "FFE0";
        dataLength.value = "3";
        recordType.value = "B";
      }
    }
    function reloadPage() {
      window.location.reload();
    }
    function getDate() {
      var currentDate = new Date();
      formatedDate.value = new Date().toJSON().slice(0, 10);
    }
    return {
      fileName,
      file,
      inArrayFinal,
      outResult,
      uniqueProjNr,
      uniqueCallNr,
      inputArray,
      recordTypeList,
      leftTable,
      rightTable,
      advancedEdit,
      dialogState,
      inputPN,
      inputStartAtCN,
      formatedDate,
      hexAdressInput,
      dataLengthInput,
      recordTypeInput,
      dataLengthList,
      hexAdress,
      dataLength,
      recordType,
      rulesPN,
      rulesCN,
      inArrayFinalFiltered,
      cardElevation,
      parsed,
      onChange,
      dataFilter,
      limitCN,
      tableToExcel,
      recordInput,
      hexInput,
      lengthInput,
      advancedChange,
      reloadPage,
      getDate
    };
  }
};
</script>
<style>
body {
  background-color: white;
}
.mainRow {
  display: flex;
  justify-content: center;
  align-content: center;
  max-height: 60em;
}
.interactiveRow {
  display: flex;
  justify-content: center;
  height: 4em;
  max-height: 8em;
  margin: auto;
}
.CallnummerField {
  width: 50%;
  min-width: 7em;
}
.downloadButton {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  height: auto;
}
.ResetButton {
  display: flex;
  align-items: flex-end;
  margin: auto;
  justify-content: flex-end;
  height: 5em;
}
.colCard {
  min-width: 15em;
  height: 45em;
  padding: 1em 2em 1em;
}
.simpleTable {
  width: auto;
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
}
.tableRow {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 35em;
}
tr:nth-child(odd):hover,
tr:nth-child(even):hover {
  background-color: darkgrey;
}
tr {
  text-align: center;
}
tr:nth-child(even) {
  background-color: #b4b4b433;
}
td {
  border: 1px solid #b4b4b499;
}
th {
  border: 1px solid #b4b4b499;
  height: 2em;
  max-height: 2em;
}
h3,
h2 {
  margin: auto;
  text-align: center;
}
.invisibleButtons {
  height: 0px;
  width: 0px;
  background: transparent;
  border: none !important;
  font-size: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
