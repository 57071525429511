import { make } from "vuex-pathify";

const getDefaultState = () => {
  return {
    user: {
      username: null,
      firstName: null,
      lastName: null
    }
  };
};

const state = getDefaultState();

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  ...make.mutations(state)
};

export default {
  namespaced: true,
  state,
  mutations
};
