var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "nudge-top": "-5",
        transition: "slide-y-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "", text: "" } }, on),
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("mdi-account-circle"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-container",
        { attrs: { "pa-0": "" } },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.currentUsername)),
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(" App-Version: " + _vm._s(_vm.appVersion) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-item",
                { on: { click: _vm.toggleDarkMode } },
                [
                  _c(
                    "v-list-item-avatar",
                    [_c("v-icon", [_vm._v("invert_colors")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(_vm.invertedThemeName) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.showSettings } },
                [
                  _c(
                    "v-list-item-avatar",
                    [_c("v-icon", [_vm._v("settings")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(" Einstellungen ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.logoutUser } },
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _c("v-icon", { attrs: { color: "red" } }, [
                        _vm._v("power_settings_new"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { attrs: { color: "primary" } }, [
                        _vm._v("Ausloggen"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }