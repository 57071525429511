var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(" " + _vm._s(_vm.service.name) + " ")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Status")]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-simple-table", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Verbindungen "),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Conversion Queue "),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Send Queue "),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Listening "),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Legacy Proxy "),
                                        ]),
                                      ]),
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.service.stats.Connections
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.service.stats
                                                  .ConversionQueue
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.service.stats.SendQueue
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: _vm.service.stats
                                                    .IsListening
                                                    ? "green"
                                                    : "red",
                                                  dark: "",
                                                },
                                              },
                                              [_vm._v(" mdi-circle ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color: _vm
                                                                    .service
                                                                    .legacyProxy
                                                                    .IsMCDReachable
                                                                    ? "green"
                                                                    : "red",
                                                                  dark: "",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " mdi-circle "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              },
                                              [_c("span", [_vm._v("MCD")])]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color: _vm
                                                                    .service
                                                                    .legacyProxy
                                                                    .IsKSReachable
                                                                    ? "green"
                                                                    : "red",
                                                                  dark: "",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " mdi-circle "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Koppelserver"),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color: _vm
                                                                    .service
                                                                    .legacyProxy
                                                                    .IsInMaintenance
                                                                    ? "yellow"
                                                                    : "green",
                                                                  dark: "",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " mdi-circle "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Wartungsmodus"),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }