var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", text: "", fixed: "" } },
    [
      _c("QuickLinks"),
      _c(
        "v-btn",
        {
          attrs: { icon: "", disabled: _vm.isDashboardActive },
          on: {
            click: function ($event) {
              return _vm.navigateToDashboard()
            },
          },
        },
        [
          _c("v-icon", { attrs: { medium: "" } }, [
            _vm._v(" $vuetify.icons.home "),
          ]),
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-divider", {
        staticClass: "mx-2",
        attrs: { inset: "", vertical: "" },
      }),
      _c("UserMenu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }