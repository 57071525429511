var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { color: "primary", dark: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" " + _vm._s(_vm.currentEnv.title) + " ")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.environments, function (env, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.handleEnvSelection(env)
                        },
                      },
                    },
                    [_c("v-list-item-title", [_vm._v(_vm._s(env.title))])],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.services, function (service, i) {
        return _c(
          "v-col",
          { key: i, attrs: { cols: "3" } },
          [_c("service-card", { attrs: { service: service } })],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }