var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Nach Produktnummer suchen",
                      flat: "",
                      "hide-details": "",
                      clearable: "",
                      "clear-icon": "mdi-close-circle-outline",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "ALLE Produkte zuordnen" },
                    on: {
                      change: function ($event) {
                        return _vm.updateUser(_vm.user)
                      },
                    },
                    model: {
                      value: _vm.user.allProducts,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "allProducts", $$v)
                      },
                      expression: "user.allProducts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "no-box-shadow",
                    attrs: {
                      search: _vm.search,
                      headers: _vm.headers,
                      items: _vm.products,
                      "no-results-text": "",
                      "show-select": "",
                      selec: "",
                      "item-key": "number",
                    },
                    on: { "item-selected": _vm.productChoice },
                    scopedSlots: _vm._u([
                      {
                        key: "header.data-table-select",
                        fn: function () {
                          return undefined
                        },
                        proxy: true,
                      },
                      {
                        key: "item.ProductTypeId",
                        fn: function () {
                          return [
                            _c("img", {
                              attrs: { src: _vm.icon, width: "30" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.user.userProductList,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "userProductList", $$v)
                      },
                      expression: "user.userProductList",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "mx-auto",
                          attrs: { dark: "", tile: "", color: "grey primary" },
                        },
                        [_c("v-col", [_vm._v("Zugewiesene Produkte")])],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _vm.user.allProducts === true
                            ? _c(
                                "div",
                                {
                                  key: "title",
                                  staticClass:
                                    "title font-weight-light grey--text pa-4 text-center",
                                },
                                [
                                  _vm._v(
                                    " Der Mitarbeiter darf ALLE Produkte produzieren. "
                                  ),
                                ]
                              )
                            : _vm.user.userProductList.length === 0
                            ? _c(
                                "div",
                                {
                                  key: "title",
                                  staticClass:
                                    "title font-weight-light grey--text pa-4 text-center",
                                },
                                [
                                  _vm._v(
                                    " Dem Mitarbeiter sind keine Produkte zugeordnet. "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-scroll-x-transition",
                            { attrs: { group: "", "hide-on-leave": "" } },
                            _vm._l(
                              _vm.user.userProductList,
                              function (selection, i) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: i,
                                    staticClass: "ma-1",
                                    attrs: {
                                      color: "primary",
                                      dark: "",
                                      small: "",
                                      label: "",
                                      close: "",
                                      width: "100%",
                                    },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.unassignProduct(selection)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", small: "" } },
                                      [_vm._v("mdi-arrow-right")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          selection.number +
                                            " - " +
                                            selection.name
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { tile: "", color: "primary" },
              on: { click: _vm.deselectUser },
            },
            [_vm._v("Zurück")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { tile: "", color: "red", dark: "" },
              on: { click: _vm.removeAll },
            },
            [_vm._v(" Alle Zuweisungen entfernen ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }