var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 0,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "150px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { "prepend-icon": "event" },
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.computedDateFormatted
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "", small: "" } },
                                            [_vm._v("fas fa-calendar-week")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.showDatePicker,
                                callback: function ($$v) {
                                  _vm.showDatePicker = $$v
                                },
                                expression: "showDatePicker",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  "first-day-of-week": 1,
                                  max: _vm.today,
                                  "no-title": "",
                                  locale: "de-de",
                                  color: "primary",
                                  type: "month",
                                },
                                model: {
                                  value: _vm.date,
                                  callback: function ($$v) {
                                    _vm.date = $$v
                                  },
                                  expression: "date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v("Wöchentliche Verfügbarkeit"),
                              ]),
                              _c("v-card-text", [
                                _c("p", [_vm._v("Primär 99,3%")]),
                                _c("p", [_vm._v("Gesamt 97,3%%")]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v("Jährliche Verfügbarkeit"),
                              ]),
                              _c("v-card-text", [
                                _c("p", [_vm._v("Primär 99,3%")]),
                                _c("p", [_vm._v("Gesamt 97,3%")]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }