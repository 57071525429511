<template>
  <v-row>
    <v-col cols="auto">
      <v-btn tile x-large color="error" @click="cancel">
        <v-icon right class="pr-5">mdi-alert</v-icon>
        Abbrechen
      </v-btn>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto">
      <v-btn
        :disabled="amountError"
        x-large
        color="primary"
        dark
        @click="onSubmit"
      >
        Speichern
        <v-icon right>mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    amountError: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    function cancel() {
      emit("cancel");
    }
    function resetAmount() {
      emit("resetAmount");
    }
    function onSubmit() {
      emit("onSubmit");
    }
    return {
      cancel,
      resetAmount,
      onSubmit
    };
  }
};
</script>

<style>
.theme--light.v-input--is-disabled input {
  color: #444;
}
</style>
