<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="search"
            label="Nach Produktnummer suchen"
            flat
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          <v-checkbox
            v-model="user.allProducts"
            label="ALLE Produkte zuordnen"
            @change="updateUser(user)"
          >
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col>
          <v-data-table
            v-model="user.userProductList"
            :search="search"
            :headers="headers"
            :items="products"
            no-results-text
            show-select
            selec
            item-key="number"
            class="no-box-shadow"
            @item-selected="productChoice"
          >
            <template v-slot:header.data-table-select> </template>
            <!--template v-slot:item.isFavourite="{ item }">
              < :key="componentKey" is needed to rerender the column when a change occurs>
              <v-checkbox
                :key="componentKey"
                v-model="item.isFavourite"
                selected-color="red"
                on-icon="fan fa-star"
                color="#FFD700"
                off-icon="far fa-star"
                @change="favChoice(item)"
              >
              </v-checkbox>
            <template-->
            <template v-slot:item.ProductTypeId>
              <img :src="icon" width="30" />
            </template>
          </v-data-table>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col>
          <v-row>
            <v-sheet dark tile color="grey primary" class="mx-auto">
              <v-col>Zugewiesene Produkte</v-col>
            </v-sheet>
            <v-card-text>
              <div
                v-if="user.allProducts === true"
                key="title"
                class="title font-weight-light grey--text pa-4 text-center"
              >
                Der Mitarbeiter darf ALLE Produkte produzieren.
              </div>
              <div
                v-else-if="user.userProductList.length === 0"
                key="title"
                class="title font-weight-light grey--text pa-4 text-center"
              >
                Dem Mitarbeiter sind keine Produkte zugeordnet.
              </div>
              <v-scroll-x-transition group hide-on-leave>
                <v-chip
                  v-for="(selection, i) in user.userProductList"
                  :key="i"
                  color="primary"
                  dark
                  small
                  label
                  close
                  width="100%"
                  class="ma-1"
                  @click:close="unassignProduct(selection)"
                >
                  <v-icon left small>mdi-arrow-right</v-icon>
                  {{ selection.number + " - " + selection.name }}
                </v-chip>
              </v-scroll-x-transition>
            </v-card-text>
          </v-row>
          <!--v-divider class="mb-3" />
          <v-row>
            <v-sheet dark tile color="grey primary" class="mx-auto">
              <v-col>Zugewiesene Favoriten</v-col>
            </v-sheet>
            <v-card-text>
              <div
                v-if="userFavouriteList.length === 0"
                key="title"
                class="title font-weight-light grey--text pa-4 text-center"
              >
                Dem Mitarbeiter sind keine Favoriten zugeordnet.
              </div>
              <v-list
                v-else-if="userFavouriteList.length > 0"
                two-line
                subheader
              >
                <v-list-item v-for="(item, i) in userFavouriteList" :key="i">
                  <v-list-item-avatar>
                    <v-icon color="#FFD700"> fan fa-star</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                    <v-list-item-subtitle v-text="item.number" />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      small
                      color="primary"
                      @click="unassignFavourite(item)"
                    >
                      <v-icon small>
                        fas fa-times-circle
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn small color="error" @click="removeAllFavourites">
                      Alle Favoriten entfernen
                      <v-icon right small color="red lighten-4">
                        fas fa-trash
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-row-->
        </v-col>
      </v-row>
      <v-divider />
    </v-card-text>

    <v-card-actions>
      <v-btn tile color="primary" @click="deselectUser">Zurück</v-btn>
      <v-spacer></v-spacer>
      <v-btn tile color="red" dark @click="removeAll">
        Alle Zuweisungen entfernen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { JsonHubProtocol } from "@microsoft/signalr";
import { ref, watch, computed } from "@vue/composition-api";

export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const settings = ref([]);
    const componentKey = ref(0);
    const userFavouriteList = ref([]);
    const item = ref([]);
    const headers = ref([
      // {
      //   text: "Favorit",
      //   value: "isFavourite",
      //   align: "start"
      // },
      {
        text: "Typ",
        align: "start",
        value: "ProductTypeId"
      },
      { text: "Name", value: "name" },
      { text: "Produkt-Nr:", value: "number" }
    ]);
    const icon = ref(require("@/assets/gs-manager-tree-icon-zp.gif"));
    const search = ref(null);

    resetFavourites();
    watch(
      () => props.user,
      newValue => {
        resetFavourites();
      }
    );
    function resetFavourites() {
      userFavouriteList.value = [];
      for (let p = 0; p < props.products.length; p++) {
        props.products[p].isFavourite = false;
      }
      for (let i = 0; i < props.user.userProductList.length; i++) {
        if (props.user.userProductList[i].isFavourite) {
          var productId = props.user.userProductList[i].id;
          let productName = props.user.userProductList[i].name;
          let productNumber = props.user.userProductList[i].number;
          let index = props.products.findIndex(x => x.id === productId);
          props.products[index].isFavourite = true;
          userFavouriteList.value.push({
            id: productId,
            isFavourite: true,
            name: productName,
            number: productNumber
          });
        }
      }
      componentKey.value += 1;
    }
    function productChoice({ item, value }) {
      emit("productChoice", props.user.id, item.id, value);
      if (value === false) {
        unassignFavourite(item);
      }
    }
    function favChoice(val) {
      if (val.isFavourite) {
        let index = props.products.findIndex(x => x.id === val.id);
        let assignedProductIndex = props.user.userProductList.findIndex(
          x => x.id === val.id
        );
        if (assignedProductIndex == -1) {
          props.user.userProductList.push({
            id: val.id,
            isFavourite: true,
            name: val.name,
            number: val.number
          });
          unassignFavourite(val);
          emit("productChoice", props.user.id, val.id, true);
        } else {
          props.user.userProductList[assignedProductIndex].isFavourite = true;
          props.products[index].isFavourite = true;
          userFavouriteList.value.push({
            id: val.id,
            isFavourite: val.isFavourite,
            name: val.name,
            number: val.number
          });
          emit("favouriteChoice", props.user.id, val.id, true);
        }
      } else {
        unassignFavourite(val);
      }
      componentKey.value += 1;
    }
    function unassignFavourite(val) {
      let index = props.user.userProductList.findIndex(x => x.id === val.id);
      let index1 = props.products.findIndex(x => x.id === val.id);
      props.user.userProductList[index].isFavourite = false;
      props.products[index1].isFavourite = false;
      userFavouriteList.value = props.user.userProductList.filter(
        i => i.isFavourite === true
      );
      emit("favouriteChoice", props.user.id, val.id, false);
      componentKey.value += 1;
    }
    function updateUser(val) {
      emit("updated", val);
    }
    function deselectUser() {
      emit("deselectUser");
    }
    function removeAll() {
      props.user.userProductList = [];
      emit("unassignAllProducts", props.user.id);
      removeAllFavourites();
    }
    function removeAllFavourites() {
      let i;
      let p;
      for (i = 0; i < props.user.userProductList.length; i++) {
        props.user.userProductList[i].isFavourite = false;
      }
      for (p = 0; p < props.products.length; p++) {
        props.products[p].isFavourite = false;
      }
      userFavouriteList.value = [];
      emit("removeAllFavourites", props.user.id);
      componentKey.value += 1;
    }
    function unassignProduct(val) {
      unassignFavourite(val);
      emit("productChoice", props.user.id, val.id, false);
      props.user.userProductList.splice(
        props.user.userProductList.indexOf(val),
        1
      );
    }
    return {
      search,
      headers,
      icon,
      productChoice,
      favChoice,
      unassignFavourite,
      updateUser,
      deselectUser,
      removeAll,
      removeAllFavourites,
      unassignProduct
    };
  }
};
</script>
