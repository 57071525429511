var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "drop",
    {
      on: {
        drop: function ($event) {
          return _vm.handleDrop(_vm.sortedList)
        },
        dragover: function ($event) {
          return _vm.handleDragOver()
        },
        dragleave: function ($event) {
          return _vm.handleDragLeave()
        },
      },
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            "min-height": "140",
            "min-width": "40",
            outlined: _vm.outline,
          },
          on: {
            click: function ($event) {
              return _vm.handleDirectorySelection(_vm.sortedList)
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.handleDirectorySelection(_vm.sortedList)
            },
          },
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "font-md pb-7",
              staticStyle: { "overflow-y": "auto" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        value: _vm.sortedList.products.length,
                        content: _vm.sortedList.products.length,
                        color: _vm.badgeColor,
                        "offset-x": "6",
                        "offset-y": "3",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-1 mb-1",
                          attrs: {
                            size: "24",
                            color: _vm.sortedList.color.color,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.sortedList.icon.icon) + " ")]
                      ),
                    ],
                    1
                  ),
                  _vm.sortedList.isFavourite
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "ml-1 pb-1",
                          attrs: { color: "#FFD700" },
                        },
                        [_vm._v(" fan fa-star ")]
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.sortedList.name) + " "),
                ],
                1
              ),
            ]
          ),
          _c("v-card-text"),
          _c(
            "v-card-actions",
            { staticClass: "pt-3 mx-1" },
            [
              _c(
                "v-fade-transition",
                [
                  _vm.showMenu
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.moveMode || _vm.editMode,
                            small: "",
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteDirectory(_vm.sortedList)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fan fa-trash"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }