<template>
  <v-tabs grow icons-and-text>
    <v-tab>
      Gerätedaten
      <v-icon>
        perm_device_information
      </v-icon>
    </v-tab>
    <v-tab>
      Meldungen
      <v-icon>
        ring_volume
      </v-icon>
    </v-tab>
    <v-tab>
      Verfügbarkeit
      <v-icon>
        show_chart
      </v-icon>
    </v-tab>
    <v-tab-item>
      <DeviceDetails :device="device" />
    </v-tab-item>
    <v-tab-item>
      <DeviceMessages :device="device" />
    </v-tab-item>
    <v-tab-item>
      <DeviceAvailability :device="device" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import DeviceAvailability from "@/components/Control/DevicesTab/DetailsTabs/DeviceAvailability.vue";
import DeviceDetails from "@/components/Control/DevicesTab/DetailsTabs/DeviceDetails.vue";
import DeviceMessages from "@/components/Control/DevicesTab/DetailsTabs/DeviceMessages.vue";
export default {
  components: {
    DeviceAvailability,
    DeviceDetails,
    DeviceMessages
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
