import Vue from "vue";
import Vuex from "vuex";
import vuexPersist from "vuex-persist";
import VueDragDrop from "vue-drag-drop";

import pathify from "./pathify";

import auth from "./modules/auth";
import app from "./modules/app";
import settings from "./modules/settings";
import control from "./modules/control";

// pathify.debug();
Vue.use(VueDragDrop);
Vue.use(Vuex);
Vue.config.devtools = true;

const vuexLocal = new vuexPersist({
  // vuex-persist
  // localForage is async -> leads to wrong initial state because state is already applied before restore finished -> use localStorage instead
  storage: window.localStorage,
  supportCircular: true
});

const plugins = [pathify.plugin, vuexLocal.plugin];

const store = new Vuex.Store({
  modules: {
    auth,
    app,
    settings,
    control
  },
  plugins
});

export default store;
window.store = store;
