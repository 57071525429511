<template>
  <v-app>
    <vue-snotify />
    <AppToolbar v-if="!hideAppBar" />
    <v-main>
      <v-container
        fluid
        :style="[
          hideAppBar
            ? {
                padding: '0px',
              }
            : {
                'padding-left': globalPadding + 'px',
                'padding-right': globalPadding + 'px',
              },
        ]"
      >
        <router-view />
        <v-bottom-sheet v-model="showSettingsDialog">
          <v-sheet class="text-center">
            <Settings />
          </v-sheet>
        </v-bottom-sheet>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Get, Sync } from "vuex-pathify";

import AppToolbar from "./components/Toolbar.vue";
import Settings from "./components/Settings.vue";
import shortcuts from "./data/shortcuts";

@Component({
  components: {
    AppToolbar,
    Settings
  }
})
export default class App extends Vue {
  hideAppBar = false;

  @Sync("app/showSettings") showSettingsDialog;
  @Get("settings/primaryColor") color;
  @Get("settings/darkMode") darkMode;
  @Get("settings/globalPadding") globalPadding;
  @Get("auth/user") loggedInUser;

  @Watch("color")
  onColorChanged() {
    this.applyColor();
  }

  get shortcuts() {
    return shortcuts;
  }

  mounted() {
    this.applyColor();
    this.applyDarkMode();

    if (this.loggedInUser.attributes) {
      this.hideAppBar =
        this.loggedInUser.attributes.hideAppBar != undefined
          ? this.loggedInUser.attributes.hideAppBar[0]
          : false;
    }

    const defaultApp =
      this.loggedInUser.attributes.defaultApp != undefined
        ? this.loggedInUser.attributes.defaultApp[0]
        : undefined;
    if (defaultApp && this.$route.name != defaultApp) {
      const route = this.$router.options.routes.find(
        route => route.name == defaultApp
      );
      if (route) {
        this.$router.push(route.path);
      }
    }
  }

  applyDarkMode() {
    this.$vuetify.theme.dark = this.darkMode;
  }

  applyColor() {
    this.$vuetify.theme.themes.dark.primary = this.color;
    this.$vuetify.theme.themes.light.primary = this.color;
  }
}
</script>
