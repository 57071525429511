import { make } from "vuex-pathify";

const getDefaultState = () => {
  return {
    darkMode: false,
    primaryColor: "#475A7C",
    cardElevation: 3,
    globalPadding: 12
  };
};

const state = getDefaultState();

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  ...make.mutations(state)
};

export default {
  namespaced: true,
  state,
  mutations
};
