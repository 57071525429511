<template>
  <v-card tile :loading="server.loading" @click="$listeners['click']">
    <div class="text-center pt-3">
      <v-icon large :color="server.available ? 'green' : 'red'">
        {{ icon }}
      </v-icon>
    </div>
    <v-card-title class="text-center">
      {{ server.name }}
    </v-card-title>
    <v-card-subtitle>
      <v-row dense>
        <v-col>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" color="#616161" small v-on="on">
                {{ iconConnections }}
              </v-icon>
              {{ server.stats.Connections }}
            </template>
            <span>Verbindungen</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          {{ server.environment }}
        </v-col>
      </v-row>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { mdiConnection } from "@mdi/js";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class VDSTile extends Vue {
  @Prop({ default: "$vuetify.icons.gsmtp" }) icon;
  @Prop() server;

  iconConnections = mdiConnection;
}
</script>
