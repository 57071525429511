var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "", color: "rgba(0, 0, 0, 0)" } },
            [
              _c(
                "v-card-text",
                { staticClass: "py-0" },
                [
                  _c("v-icon", { attrs: { small: "", color: "primary" } }, [
                    _vm._v(" $vuetify.icons.hub "),
                  ]),
                  _vm._v(" Apps "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            _vm._l(_vm.availableLinks, function (link, index) {
              return _c(
                "v-col",
                {
                  key: index,
                  attrs: { cols: "12", sm: "6", md: "4", lg: "3", xl: "2" },
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: { height: "200", outlined: "", tile: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigate(link, index + 1)
                        },
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "fill-height" },
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { align: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-icon", {
                                    attrs: { large: "", color: "primary" },
                                    domProps: {
                                      textContent: _vm._s(link.meta.icon),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _vm._v(" " + _vm._s(link.name) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }