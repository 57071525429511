var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-file-input", {
        attrs: {
          transition: "scroll-x-transition",
          "truncate-length": "50",
          "prepend-icon": _vm.icon,
          outlined: "",
          label: _vm.label,
          accept: ".xlsx, .xls, .csv",
        },
        on: { change: _vm.fileUploaded, "click:clear": _vm.clickClear },
      }),
      _c(
        "xlsx-read",
        { attrs: { file: _vm.file } },
        [_c("xlsx-json", { on: { parsed: _vm.parsed } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }