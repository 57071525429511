<template>
  <v-card>
    <v-card-title>
      {{ service.name }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>Status</v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Verbindungen
                      </th>
                      <th class="text-left">
                        Conversion Queue
                      </th>
                      <th class="text-left">
                        Send Queue
                      </th>
                      <th class="text-left">
                        Listening
                      </th>
                      <th class="text-left">
                        Legacy Proxy
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ service.stats.Connections }}
                      </td>
                      <td>
                        {{ service.stats.ConversionQueue }}
                      </td>
                      <td>
                        {{ service.stats.SendQueue }}
                      </td>
                      <td>
                        <v-icon
                          :color="service.stats.IsListening ? 'green' : 'red'"
                          dark
                        >
                          mdi-circle
                        </v-icon>
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              :color="
                                service.legacyProxy.IsMCDReachable
                                  ? 'green'
                                  : 'red'
                              "
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-circle
                            </v-icon>
                          </template>
                          <span>MCD</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              :color="
                                service.legacyProxy.IsKSReachable
                                  ? 'green'
                                  : 'red'
                              "
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-circle
                            </v-icon>
                          </template>
                          <span>Koppelserver</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              :color="
                                service.legacyProxy.IsInMaintenance
                                  ? 'yellow'
                                  : 'green'
                              "
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-circle
                            </v-icon>
                          </template>
                          <span>Wartungsmodus</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true
    }
  }
};
</script>
