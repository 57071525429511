var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      staticClass: "quick-link",
      attrs: {
        "offset-y": "",
        origin: "left top",
        transition: "slide-y-transition",
        "content-class": "quicklink-dropdown",
        right: "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  { staticClass: "my-0", attrs: { icon: "", large: "" } },
                  on
                ),
                [_c("v-icon", { attrs: { medium: "" } }, [_vm._v("apps")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _c(
        "div",
        { staticClass: "dropdown-content" },
        [
          _c("div", { staticClass: "dropdown-top d-custom-flex primary" }, [
            _c("span", { staticClass: "white--text fw-bold ml-3" }, [
              _vm._v(" Apps "),
            ]),
          ]),
          _c(
            "v-list",
            { attrs: { rounded: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { mandatory: "" },
                  model: {
                    value: _vm.navigationIndex,
                    callback: function ($$v) {
                      _vm.navigationIndex = $$v
                    },
                    expression: "navigationIndex",
                  },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.navigateToDashboard()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v(" $vuetify.icons.home "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Home")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.apps, function (app, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: app.title,
                        on: {
                          click: function ($event) {
                            return _vm.mount(app, index + 1)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", {
                              attrs: { color: "primary" },
                              domProps: { textContent: _vm._s(app.icon) },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(app.title) },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }