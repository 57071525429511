var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      attrs: { "icons-and-text": "" },
      model: {
        value: _vm.tab,
        callback: function ($$v) {
          _vm.tab = $$v
        },
        expression: "tab",
      },
    },
    [
      _c(
        "v-tab",
        [
          _vm._v(" SIM-Kündigung "),
          _c("v-icon", [_vm._v(" $vuetify.icons.sim ")]),
        ],
        1
      ),
      _c(
        "v-tab",
        [
          _vm._v(" Vertragsübersicht "),
          _c("v-icon", [_vm._v(" $vuetify.icons.glasses ")]),
        ],
        1
      ),
      _c(
        "v-tab",
        [
          _vm._v(" Rahmenverträge "),
          _c("v-icon", [_vm._v(" $vuetify.icons.excel ")]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c("SingleSim", {
                attrs: { uploadedCallnumbers: _vm.uploadedCallnumbers },
                on: {
                  markAsCanceled: _vm.markAsCanceled,
                  markAsUncanceled: _vm.markAsUncanceled,
                },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c("UploadedSims", {
                attrs: { uploadedCallnumbers: _vm.uploadedCallnumbers },
                on: { callnumberDelete: _vm.callnumberDelete },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c("ContractsOverview", {
                on: { contractUploaded: _vm.contractUploaded },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }