<template>
  <v-app-bar app text fixed>
    <QuickLinks />
    <v-btn icon :disabled="isDashboardActive" @click="navigateToDashboard()">
      <v-icon medium>
        $vuetify.icons.home
      </v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-divider inset vertical class="mx-2" />
    <UserMenu />
  </v-app-bar>
</template>

<script>
import QuickLinks from "@/components/QuickLinks";
import UserMenu from "@/components/UserMenu";
import { get } from "vuex-pathify";
import { ref, computed, onMounted } from "@vue/composition-api";

export default {
  components: {
    QuickLinks,
    UserMenu
  },
  setup(props, context) {
    const keycloak = context.root.$keycloak;
    const navigationIndex = ref(context.root.$route.navigationIndex);
    const isDashboardActive = computed(
      () => context.root.$route.path == "/dashboard"
    );
    const hasDevRole = ref(false);
    const environment = ref(1);

    function navigateToDashboard() {
      if (isDashboardActive.value == false) {
        navigationIndex.value = 0;
        context.root.$router.push("/dashboard");
      }
    }

    onMounted(() => {
      hasDevRole.value = keycloak.hasResourceRole("dev");
      environment.value = hasDevRole.value ? 0 : 1;
    });

    return {
      isDashboardActive,
      hasDevRole,
      environment,
      navigateToDashboard
    };
  }
};
</script>

<style lang="scss" scoped>
@media print {
  .print {
    margin-top: -250px !important;
  }
}
.v-btn:before {
  content: none;
}

.highlightRed {
  color: white !important;
  background-color: red !important;
}
</style>
