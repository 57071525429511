<template>
  <v-row dense>
    <v-col v-if="selectedUser[0].allProducts == true" cols="4">
      <CompleteProductList
        :selectedUser="selectedUser"
        :products="products"
        @handleSelectedProduct="handleSelectedProduct"
      />
    </v-col>
    <v-col
      v-if="
        selectedUser[0].userProductList.length > 0 &&
        selectedUser[0].allProducts == true
      "
      cols="auto"
    >
      <v-divider vertical />
    </v-col>
    <v-col v-if="selectedUser[0].userProductList.length > 0">
      <ProductFavouriteCards
        :currentStep="currentStep"
        :selectedUser="selectedUser"
        :directoryId="directoryId"
        :directoryOpened="directoryOpened"
        @handleListChange="handleListChange"
        @handleSelectedProduct="handleSelectedProduct"
        @addDirectory="addDirectory"
        @handleMoveToDirectory="handleMoveToDirectory"
        @handleRemoveFromDirectory="handleRemoveFromDirectory"
        @handleDirectoryOpened="handleDirectoryOpened"
        @deleteDirectory="deleteDirectory"
        @updateDirectory="updateDirectory"
      />
    </v-col>
    <v-col v-else align="center" justify="center">
      <span>Diesem Mitarbeiter sind keine Produkte zugeordnet</span>
    </v-col>
  </v-row>
</template>

<script>
import CompleteProductList from "@/components/PDE/CompleteProductList";
import ProductFavouriteCards from "@/components/PDE/ProductFavouriteCards";

export default {
  components: {
    CompleteProductList,
    ProductFavouriteCards,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    directoryOpened: {
      type: Boolean,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    selectedUser: {
      type: Array,
      required: true,
    },
    directoryId: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    function addDirectory(name, userId, iconId, colorId) {
      emit("addDirectory", name, userId, iconId, colorId);
    }
    function deleteDirectory(id) {
      emit("deleteDirectory", id);
    }
    function handleSelectedProduct(val) {
      emit("handleSelectedProduct", val);
    }
    function handleMoveToDirectory(userId, productId, directoryId) {
      emit("handleMoveToDirectory", userId, productId, directoryId);
    }
    function handleRemoveFromDirectory(userId, productId, directoryId) {
      emit("handleRemoveFromDirectory", userId, productId, directoryId);
    }
    function handleListChange(userId, products) {
      emit("handleListChange", userId, products);
    }
    function handleDirectoryOpened() {
      emit("handleDirectoryOpened");
    }
    function updateDirectory(directoryId, directoryName, iconId, colorId) {
      emit("updateDirectory", directoryId, directoryName, iconId, colorId);
    }
    return {
      addDirectory,
      deleteDirectory,
      handleSelectedProduct,
      handleMoveToDirectory,
      handleRemoveFromDirectory,
      handleListChange,
      handleDirectoryOpened,
      updateDirectory,
    };
  },
};
</script>
