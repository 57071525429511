import axios from "axios";

const api = axios.create();

export default {
  getStats: function(server) {
    return api.get(server.url + ":" + server.port + "/api/v1/stats");
  },
  getLegacyProxyStats: function(server) {
    return api.get(server.url + ":" + server.legacyProxyPort + "/api/v1/stats");
  },
  getAllDevices: function(server, userData) {
    return api.get(server.url + ":" + server.port + "/api/v1/alldevices", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json"
      },
      userData: userData
    });
  },
  getAllAvailabilities: function(server) {
    return api.get(
      server.url + ":" + server.port + "/api/v1/devices/availabilities",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  },
  getDeviceAvailabilities: function(server, deviceId) {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            data: JSON.parse(
              '{"Item1": {"DeviceID": 941002, "From": "2021-01-13T00:00:00", "Until": "2021-01-20T00:00:00", "Availability": 99.8532, "AvailabilityPrimary": 99.8532, "IsWeekly": true}, "Item2": {"DeviceID": 941002, "From": "2020-01-08T00:00:00", "Until": "2021-01-06T00:00:00", "Availability": 99.8684, "AvailabilityPrimary": 99.7592, "IsWeekly": false}, "Item3": {"ID": 941002, "CurrentlyConnectedTo": "RZ32AES1", "FirstRouteState": {"Route": 1, "State": 1, "LastStateChange": "2021-01-22T11:03:20.118934"}, "SecondRouteState": {"Route": 2, "State": 2, "LastStateChange": "2021-01-22T09:02:08.698934"}}}'
            )
          }),
        1000
      )
    );
    // return api.get(
    //   server.url +
    //     ":" +
    //     server.port +
    //     "/api/v1/devices/" +
    //     deviceId +
    //     "/availabilities",
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/vnd.example.v1+json"
    //     }
    //   }
    // );
  },
  getMonthsWhichContainEventsOfDevice: function(server, deviceId) {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            data: JSON.parse(
              '["2021-01-01T00:00:00", "2021-02-01T00:00:00", "2020-12-01T00:00:00"]'
            )
          }),
        1000
      )
    );
    // return api.get(
    //   server.baseUrl +
    //     ":" +
    //     server.port +
    //     "/api/v1/devices/" +
    //     deviceId +
    //     "/events/months",
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/vnd.example.v1+json"
    //     }
    //   }
    // );
  },
  getDeviceEvents: function(server, deviceId, month, year) {
    return api.get(
      server.url +
        ":" +
        server.port +
        "/api/v1/devices/" +
        deviceId +
        "/events",
      {
        params: {
          month: month,
          year: year
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json"
        }
      }
    );
  }
};
