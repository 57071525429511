export default [
  {
    name: "help",
    key: "F1",
    description: "Hilfe ein- und ausblenden",
    keyCodes: ["f1"]
  },
  {
    name: "dashboard",
    key: "F2",
    description: "Zum Dashboard navigieren",
    keyCodes: ["f2"]
  },
  {
    name: "appdrawer",
    key: "F4",
    description: "Appdrawer öffnen",
    keyCodes: ["f4"]
  },
  {
    name: "settings",
    key: "Strg/Cmd + Komma",
    description: "Einstellungen öffnen",
    keyCodes: ["command+,", "ctrl+,"]
  }
];
