var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.saveDialog,
            callback: function ($$v) {
              _vm.saveDialog = $$v
            },
            expression: "saveDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", [_vm._v(" Folgende Daten wurden erfasst: ")]),
              _c("v-card-text", [
                _vm.selectedProductIds.length > 0
                  ? _c("div", { staticClass: "text--primary" }, [
                      _vm._v(" Zwischenprodukt (Pro.-Bez.): "),
                      _c("br"),
                      _c("p", { staticClass: "text-h5" }, [
                        _vm._v(
                          " " + _vm._s(_vm.selectedProductIds[0].name) + " "
                        ),
                      ]),
                      _c("br"),
                      _vm._v(" Eingetragende Stückzahl: "),
                      _c("br"),
                      _c("p", { staticClass: "text-h5" }, [
                        _vm._v(_vm._s(_vm.amount)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        tile: "",
                        large: "",
                        color: "error",
                        dark: "",
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.saveDialog = false
                        },
                      },
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        tile: "",
                        large: "",
                        color: "primary",
                        dark: "",
                        depressed: "",
                      },
                      on: { click: _vm.onSubmit },
                    },
                    [
                      _vm._v(" Bestätigen "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-arrow-right-bold"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "v-card",
            {
              staticClass: "p-0",
              staticStyle: { "overflow-y": "auto" },
              attrs: { "max-height": "600" },
            },
            [
              _vm.selectedProductIds.length > 0
                ? _c("v-treeview", {
                    attrs: { items: _vm.selectedProductIds, "open-all": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                [
                                  item.number !=
                                  _vm.selectedProductIds[0].number
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "treeQuantity",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("v-label", [
                                            _vm._v(
                                              _vm._s(item.quantity + " × ")
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "treeIMG",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "itemPics",
                                        attrs: {
                                          src: _vm.icons[item.productTypeId],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "label",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "treeItems",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("v-label", [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "treeItems",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("v-label", [
                                        _vm._v(_vm._s(item.number)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "append",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                { staticClass: "pl-0 mr-0" },
                                [
                                  item.number ==
                                  _vm.selectedProductIds[0].number
                                    ? _c("v-dialog", {
                                        attrs: { width: "500" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            tile: "",
                                                            color: "primary",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("fas fa-images"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "default",
                                              fn: function (dialog) {
                                                return [
                                                  _c(
                                                    "v-card",
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "headline",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-card-subtitle", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.number
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c("v-img", {
                                                            attrs: {
                                                              src:
                                                                "http://pbpps.gselectronic.com/zp/" +
                                                                item.number +
                                                                ".jpg",
                                                            },
                                                            on: {
                                                              error: function (
                                                                $event
                                                              ) {
                                                                return _vm.setAltImg()
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-divider"),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    dialog.value = false
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Schließen "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2678747298
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [_c("v-divider", { attrs: { vertical: "" } })],
        1
      ),
      _c(
        "v-col",
        [
          _c(
            "NumPad",
            {
              attrs: { currentStep: _vm.currentStep },
              on: { setNumber: _vm.setNumpadNumber, reset: _vm.resetAmount },
            },
            [
              _c("NumPadTextfield", {
                attrs: {
                  slot: "prepend",
                  currentStep: _vm.currentStep,
                  amount: _vm.amount,
                },
                on: { setNumber: _vm.setTextfieldNumber },
                slot: "prepend",
              }),
              _c("NumPadButtons", {
                staticClass: "pt-4 mx-0",
                attrs: {
                  slot: "append",
                  amount: _vm.amount,
                  amountError: _vm.amountError,
                },
                on: {
                  cancel: _vm.stepToProductSelection,
                  onSubmit: function ($event) {
                    _vm.saveDialog = true
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }