<template>
  <v-tabs v-model="tab" icons-and-text>
    <v-tab>
      SIM-Kündigung
      <v-icon> $vuetify.icons.sim </v-icon>
    </v-tab>
    <v-tab>
      Vertragsübersicht
      <v-icon> $vuetify.icons.glasses </v-icon>
    </v-tab>
    <v-tab>
      Rahmenverträge
      <v-icon> $vuetify.icons.excel </v-icon>
    </v-tab>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SingleSim
          :uploadedCallnumbers="uploadedCallnumbers"
          @markAsCanceled="markAsCanceled"
          @markAsUncanceled="markAsUncanceled"
        />
      </v-tab-item>
      <v-tab-item>
        <UploadedSims
          @callnumberDelete="callnumberDelete"
          :uploadedCallnumbers="uploadedCallnumbers"
        />
      </v-tab-item>
      <v-tab-item>
        <ContractsOverview @contractUploaded="contractUploaded" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import SingleSim from "../components/AppCard/Sim/SingleSim.vue";
import api from "@/api/sim";
import UploadedSims from "../components/AppCard/Sim/UploadedSims.vue";
import ContractsOverview from "../components/AppCard/Sim/ContractsOverview.vue";

export default {
  components: { SingleSim, UploadedSims, ContractsOverview },
  setup(props, { emit }) {
    const tab = ref(null);
    const uploadedCallnumbers = ref([]);
    onMounted(() => {
      api.callnumbersGetAll().then((result) => {
        logResult(result.data);
      });
    });
    function logResult(data) {
      uploadedCallnumbers.value = data;
    }
    function contractUploaded(val) {
      uploadedCallnumbers.value = uploadedCallnumbers.value.concat(val);
    }
    function callnumberDelete(val) {
      const index = getIndexOfNumber(val.Rufnummer);
      uploadedCallnumbers.value[index];
      uploadedCallnumbers.value.splice(index, 1);
      api.callnumberDelete(val.Rufnummer);
    }
    function markAsCanceled(val) {
      const index = getIndexOfNumber(val);
      uploadedCallnumbers.value[index].IstGekuendigt = true;
    }
    function markAsUncanceled(val) {
      const index = getIndexOfNumber(val);
      uploadedCallnumbers.value[index].IstGekuendigt = false;
    }
    function getIndexOfNumber(number) {
      return uploadedCallnumbers.value.findIndex((e) => e.Rufnummer == number);
    }
    return {
      tab,
      uploadedCallnumbers,
      contractUploaded,
      callnumberDelete,
      markAsCanceled,
      markAsUncanceled,
      getIndexOfNumber,
    };
  },
};
</script>
