import axios from "axios";

const api = axios.create({
  baseURL: "https://ham.faas.gselectronic.com/function",
});

api.interceptors.request.use(
  (request) => {
    if (request.data == null) {
      request.data = {};
    }
    request.data.Token =
      "aGFkZmg1aGFocmhhZ2poYWp0amg1NGhqenJqcmp6cjpnczUzMzc3Iw";
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  callnumberAdd: function (contractName, contractData) {
    return api.post(
      "/callnumbers-add",
      {
        contractName: contractName,
        data: contractData

      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json",
        },
      }
    );
  },
  callnumbersCancel: function (cancelList) {
    return api.post(
      "/callnumbers-cancel",
      {
        Rufnummern: cancelList
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json",
        },
      }
    );
  },

  callnumberDelete: function (callnumber) {
    return api.post("/callnumbers-delete",
      { Rufnummer: callnumber },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.example.v1+json",
        },
      }
    )
  },

  callnumbersGetAll: function () {
    return api.post("/callnumbers-get-all", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json",
      },
    })
  }
};
