import Vue from "vue";
import store from "@/store";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import keyCloakConfig from "./keycloak";

class AuthService {
  roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

  constructor() {
    this.logout = this.logout.bind(this);
    this.loginOAuth = this.loginOAuth.bind(this);
    this.hasResourceRole = this.hasResourceRole.bind(this);
  }

  loginOAuth(callback, overwriteUrl) {
    if (overwriteUrl) {
      keyCloakConfig.url = overwriteUrl;
    }
    
    Vue.use(VueKeyCloak, {
      init: {
        onLoad: "login-required",
        checkLoginIframe: false
      },
      config: keyCloakConfig,
      scope: "api:read",
      onReady: kc => {
        kc.loadUserProfile().then(profile => {
          store.set("auth/user", profile);
          callback(true);
        });
      },
      onError: _ => {
        callback(false);
      }
    });
  }

  logout() {
    return new Promise(function(resolve) {
      Vue.prototype.$keycloak.logoutFn();
      store.commit("auth/reset");
      resolve();
      // ipcRenderer.once("removeCookiesDone", () => resolve());
      // ipcRenderer.send("removeCookies", { domain: "www.wrike.com" });
    });
  }

  hasResourceRole(resourceRole) {
    if (Vue.prototype.$keycloak == null) return true;
    return Vue.prototype.$keycloak.hasResourceRole(resourceRole);
  }
}

export default AuthService;

// loginWithWrike() {
//   const client_id = "U6ThHcNk";
//   const client_secret =
//     "WkUAcOCGMnUFEi6GLdhGJPDpjCgQL0XZ0Kh2TNKE02FRwgEKm8uGUL30Mx9r210e";

//   const callback_url = "https://localhost/callback";

//   return new Promise(function(resolve) {
//     ipcRenderer.once("oauth", (_, code) => {
//       axios
//         .post(
//           "https://www.wrike.com/oauth2/token" +
//             "?client_id=" +
//             client_id +
//             "&client_secret=" +
//             client_secret +
//             "&grant_type=authorization_code" +
//             "&redirect_uri=" +
//             callback_url +
//             "&code=" +
//             code
//         )
//         .then(authResult => {
//           var expirationDate = new Date();
//           expirationDate.setSeconds(
//             expirationDate.getSeconds() + authResult.data.expires_in
//           );

//           axios
//             .get("https://www.wrike.com/api/v4/contacts?me", {
//               headers: {
//                 Authorization: "Bearer " + authResult.data.access_token
//               }
//             })
//             .then(response => {
//               const me = response.data.data[0];

//               store.set("auth/user", {
//                 username: "wrikeusername",
//                 firstName: me.firstName,
//                 lastName: me.lastName
//               });

//               store.set(
//                 "auth/session",
//                 _.merge(store.get("auth/session"), {
//                   lastLoginProvider: "wrike",
//                   wrike: {
//                     accessToken: authResult.data.access_token,
//                     refreshToken: authResult.data.refresh_token,
//                     expiresAt: expirationDate
//                   }
//                 })
//               );

//               resolve(code);
//             });
//         });
//     });

//     const auth_url =
//       "https://www.wrike.com/oauth2/authorize/v4?" +
//       "client_id=" +
//       client_id +
//       "&redirect_uri=" +
//       callback_url +
//       "&response_type=code";

//     ipcRenderer.send("oauth", {
//       auth_url,
//       callback_url,
//       cookie_domain: "www.wrike.com"
//     });
//   });
// }
