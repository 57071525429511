<template>
  <v-row dense>
    <v-col>
      <v-stepper v-model="currentStep">
        <v-stepper-header outlined tile>
          <v-stepper-step step="1"> Benutzerauswahl </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> PIN-Eingabe </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Produktauswahl </v-stepper-step>
          <v-divider />
          <v-stepper-step step="4"> Produktion </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <UserSelection
              v-if="currentStep == 1"
              :users="users"
              @userSelected="handleUserClicked"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-overlay absolute :value="!passwordInputEnabled">
              <v-progress-circular
                :size="70"
                :width="10"
                color="blue"
                indeterminate
              />
            </v-overlay>
            <UserPasswordInput
              v-if="currentStep == 2"
              :currentStep="currentStep"
              @input="handlePasswordInput"
              @backspace="handlePasswordDelete"
            />

            <v-row>
              <v-col>
                <v-btn tile x-large color="error" @click="stepBack">
                  Zurück
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <ProductSelection
              v-if="currentStep == 3"
              :selectedUser="selectedUser"
              :directoryId="directoryId"
              :products="products"
              :currentStep="currentStep"
              :directoryOpened="directoryOpened"
              @handleListChange="handleListChange"
              @handleMoveToDirectory="handleMoveToDirectory"
              @handleRemoveFromDirectory="handleRemoveFromDirectory"
              @handleDirectoryOpened="handleDirectoryOpened"
              @addDirectory="addDirectory"
              @deleteDirectory="deleteDirectory"
              @handleSelectedProduct="handleSelectedProduct"
              @updateDirectory="updateDirectory"
            />
            <v-row align="center">
              <v-col :cols="5">
                <v-row>
                  <v-btn
                    tile
                    class="ml-4 mr-2"
                    x-large
                    color="error"
                    @click="stepToFirst"
                  >
                    Abmelden
                  </v-btn>
                  <v-btn
                    v-if="directoryOpened"
                    class="mx-1"
                    tile
                    x-large
                    color="error"
                    @click="handleDirectoryOpened()"
                  >
                    Zurück
                  </v-btn>
                </v-row>
              </v-col>
              <v-col>
                <div align="right">
                  <v-dialog v-model="displayProductionHistory">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn x-large v-bind="attrs" v-on="on"> Historie </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5">
                        Erfasste Produktionen
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          :headers="productionHistoryTableHeaders"
                          :items="productionHistoryTableItems"
                          :items-per-page="10"
                          class="elevation-1"
                        />
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="displayProductionHistory = false"
                        >
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <Production
              :selectedUser="selectedUser"
              :selectedProductIds="selectedProductIds"
              :amount="amount"
              :currentStep="currentStep"
              @onSubmit="handleProductionSubmit"
              @stepToFirst="stepToFirst"
              @stepToProductSelection="stepToProductSelection"
              @handleDirectoryOpened="handleDirectoryOpened"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-snackbar
        v-model="snackbar"
        timeout="4000"
        right
        :color="snackbarColor"
      >
        {{ snackbarText }}
      </v-snackbar>
      <v-btn
        v-if="currentStep == 1"
        icon
        absolute
        bottom
        right
        color="red"
        @click="logout"
      >
        <v-icon>power_settings_new</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import UserSelection from "@/components/PDE/UserSelection.vue";
import UserPasswordInput from "@/components/PDE/UserPasswordInput.vue";
import ProductSelection from "@/components/PDE/ProductSelection.vue";
import Production from "@/components/PDE/Production.vue";
import { ref, watch } from "@vue/composition-api";
import api from "@/api/pps";
import AuthService from "@/auth/AuthService";
import Promise from "bluebird";

export default {
  components: {
    UserSelection,
    UserPasswordInput,
    ProductSelection,
    Production,
  },
  setup(props, { emit }) {
    const users = ref([]);
    const products = ref([]);
    const selectedUser = ref([]);
    const selectedProductIds = ref([]);
    const directoryId = ref({});
    const amount = ref(0);
    const currentStep = ref(1);
    const passwordInput = ref("");
    const passwordInputEnabled = ref(true);
    const directoryOpened = ref(false);
    const snackbar = ref(false);
    const snackbarText = ref("");
    const snackbarColor = ref("red");
    const displayProductionHistory = ref(false);
    const productionHistoryTableHeaders = ref([
      { text: "Datum", align: "start", sortable: true, value: "date" },
      { text: "Produktnummer", value: "product.number" },
      { text: "Produktname", value: "product.name" },
      { text: "Anzahl", value: "amount" },
    ]);
    const productionHistoryTableItems = ref([]);
    api.getUsersServerless(false).then((result) => {
      users.value = result.data.sort((a, b) =>
        a.username.localeCompare(b.username)
      );
    });

    watch(
      () => passwordInput.value,
      (newValue) => {
        if (newValue.length === 4) {
          passwordInputEnabled.value = false;
          handleUserSelected();
          Promise.all([
            api.checkPassword(selectedUser.value[0].id, passwordInput.value),
            api.getAssignedProducts(selectedUser.value[0].id),
            api.getAssignments(selectedUser.value[0].id),
          ])
            .spread(
              (
                checkPasswordResult,
                getAssignedProductsResult,
                getAssignmentsResult
              ) => {
                passwordInput.value = "";
                passwordInputEnabled.value = true;
                selectedUser.value[0].userProductList =
                  getAssignedProductsResult.data;
                selectedUser.value[0].userAssignments =
                  getAssignmentsResult.data;

                stepToProductSelection();
              }
            )
            .catch((err) => {
              passwordInput.value = "";
              passwordInputEnabled.value = true;
              stepBack();
            });
        } else {
          passwordInputEnabled.value = true;
        }
      }
    );
    watch(
      () => snackbar.value,
      (newValue) => {
        if (newValue === false) {
          snackbarText.value = "";
          snackbarColor.value = "";
        }
      }
    );
    watch(
      () => displayProductionHistory.value,
      (newValue) => {
        if (newValue) {
          api
            .GetUserProductions(selectedUser.value[0].id, 30)
            .then((result) => {
              productionHistoryTableItems.value = result.data
                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) //nach Datum bzw. timestamp sortieren
                .map((e) => ({
                  ...e,
                  date: new Date(Date.parse(e.timestamp)).toLocaleDateString(
                    "de-DE",
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  ),
                })); //timestamp formatieren und als neues Feld "date" hinzufügen
            });
        }
      }
    );
    function stepToFirst() {
      directoryOpened.value = false;
      selectedUser.value = [];
      passwordInput.value = "";
      currentStep.value = 1;
      amount.value = 0;
    }
    function stepToProduction() {
      currentStep.value = 4;
      amount.value = 0;
    }
    function stepForward() {
      passwordInput.value = "";
      currentStep.value += 1;
    }
    function stepBack() {
      currentStep.value -= 1;
      passwordInput.value = "";
      if (currentStep.value == 1) {
        selectedUser.value = [];
      }
    }
    function stepToProductSelection() {
      currentStep.value = 3;
      amount.value = 0;
    }
    function handleUserClicked(user) {
      selectedUser.value = [user];
      stepForward();
    }
    function handleUserSelected() {
      if (selectedUser.value[0].allProducts) {
        api.getProductsServerless().then((result) => {
          products.value = result.data;
        });
      }

      if (selectedUser.value[0].userProductList == null) {
        selectedUser.value[0].userProductList = [];
      }
    }
    function handlePasswordInput(val) {
      passwordInput.value += val;
    }
    function handlePasswordDelete() {
      passwordInput.value = passwordInput.value.substring(
        0,
        passwordInput.value.length - 1
      );
    }
    function handleSelectedProduct(val) {
      api.getProductTree(val).then((result) => {
        selectedProductIds.value = result.data;
      });

      currentStep.value = 4;
    }
    function addDirectory(name, userId, iconId, colorId) {
      api
        .addDirectory(name, userId, iconId, colorId)
        .then((result) => {
          directoryId.value = result.data;
          snackbar.value = true;
          snackbarText.value = "Der neue Ordner wurde erstellt";
          snackbarColor.value = "green";
        })
        .catch((result) => {
          snackbar.value = true;
          snackbarText.value = "Der Ordner konnte nicht erstellt werden";
          snackbarColor.value = "red";
        });
    }

    function updateDirectory(directoryId, directoryName, iconId, colorId) {
      api
        .updateDirectory(directoryId, directoryName, iconId, colorId)
        .then((result) => {
          snackbar.value = true;
          snackbarText.value = "Der Ordner wurde geändert";
          snackbarColor.value = "green";
        })
        .catch((result) => {
          snackbar.value = true;
          snackbarText.value = "Der Ordner konnte nicht geändert werden";
          snackbarColor.value = "red";
        });
    }
    function deleteDirectory(id) {
      api
        .removeDirectory(id)
        .then((result) => {
          snackbar.value = true;
          snackbarText.value = "Der Ordner wurde gelöscht";
          snackbarColor.value = "orange";
        })
        .catch((result) => {
          snackbar.value = true;
          snackbarText.value = "Der Ordner konnte nicht gelöscht werden";
          snackbarColor.value = "red";
        });
    }
    function handleDirectoryOpened() {
      directoryOpened.value = !directoryOpened.value;
    }
    function handleMoveToDirectory(userId, productId, directoryId) {
      api
        .addToDirectory(userId, productId, directoryId)
        .then((result) => {
          snackbar.value = true;
          snackbarText.value = "Das Produkt wurde dem Ornder zugewiesen";
          snackbarColor.value = "green";
        })
        .catch((result) => {
          snackbar.value = true;
          snackbarText.value =
            "Das Produkt konnte dem Ordner nicht hinzugefügt werden";
          snackbarColor.value = "red";
        });
    }
    function handleRemoveFromDirectory(userId, productId, directoryId) {
      api
        .removeFromDirectory(userId, productId, directoryId)
        .then((result) => {
          snackbar.value = true;
          snackbarText.value = "Das Produkt wurde aus dem Ordner entfernt";
          snackbarColor.value = "orange";
        })
        .catch((result) => {
          snackbar.value = true;
          snackbarText.value =
            "Das Produkt konnte nicht aus dem Ordner entfernt werden";
          snackbarColor.value = "red";
        });
    }
    function handleListChange(userId, products) {
      api.updateSort(userId, products);
    }
    function handleProductionSubmit(userId, productId, amount, timestamp) {
      api
        .setAmountOfProducedItems(userId, productId, amount, timestamp)
        .then(() => {
          amount = 0;
        });
    }
    function logout() {
      AuthService.logout();
    }
    return {
      users,
      products,
      selectedUser,
      selectedProductIds,
      directoryId,
      amount,
      currentStep,
      passwordInputEnabled,
      directoryOpened,
      displayProductionHistory,
      productionHistoryTableHeaders,
      productionHistoryTableItems,
      stepToFirst,
      stepToProduction,
      stepForward,
      stepBack,
      stepToProductSelection,
      handleUserClicked,
      handleUserSelected,
      handlePasswordInput,
      handlePasswordDelete,
      handleSelectedProduct,
      addDirectory,
      updateDirectory,
      deleteDirectory,
      handleDirectoryOpened,
      handleMoveToDirectory,
      handleRemoveFromDirectory,
      handleListChange,
      handleProductionSubmit,
      logout,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>
