var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "", tile: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "fill-height" },
        [
          _c(
            "v-row",
            {
              staticStyle: { "text-align": "center" },
              attrs: { align: "center" },
            },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(" " + _vm._s(_vm.service.name) + " "),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                disabled: _vm.service.status != "Running",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.startService()
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                large: "",
                                                color: "green",
                                              },
                                            },
                                            [_vm._v("mdi-play")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [_c("v-col", [_vm._v(" Start ")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                disabled: _vm.service.status == "Running",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.stopService()
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                large: "",
                                                color: "red",
                                              },
                                            },
                                            [_vm._v("mdi-stop")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [_c("v-col", [_vm._v(" Stop ")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.restartService()
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                large: "",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("mdi-replay")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [_c("v-col", [_vm._v(" Restart ")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }