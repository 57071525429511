<template>
  <v-row align="center">
    <v-col cols="12">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ currentEnv.title }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(env, index) in environments"
            :key="index"
            @click="handleEnvSelection(env)"
          >
            <v-list-item-title>{{ env.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col v-for="(service, i) in services" :key="i" cols="3">
      <service-card :service="service" />
    </v-col>
  </v-row>
</template>

<script>

import ServiceCard from "@/components/ServiceManager/ServiceCard";
import services from "@/data/services";
import api from "@/api/servicemanager";

export default{
  components: {
    ServiceCard
  },  
  mounted() {
    this.currentEnv = this.environments[0]
  },
  watch: {
    currentEnv(val) {
      api.getServices(val.baseUrl).then(result => {
        console.log(result);
      })
    }
  },
  data() {
    return {
      currentEnv: { title: "" },
      environments: [
        { title: "Vision 2", baseUrl: "https://178.255.137.217:13516/serviceManager/vision02-007/api/v1" },
        // { title: "Vision 3", baseUrl: "http://vision3-007-IP/serviceManager/vision02-007" }
      ],
      services: services
    };
  },
  methods: {
    handleEnvSelection(env) {
      this.currentEnv = env;
    }
  },
}
</script>
