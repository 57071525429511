var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { staticClass: "py-0" },
        [
          _c("v-text-field", {
            staticClass: "font-size customLabel",
            attrs: {
              id: "customLabel",
              value: _vm.amount,
              placeholder: "0",
              rules: _vm.requiredRule,
              label: "Stückzahl",
              "hide-details": "",
              "single-line": "",
              outlined: "",
            },
            on: { input: _vm.setNumber },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }