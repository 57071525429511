var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Nach Produktnummer suchen",
                      flat: "",
                      "hide-details": "",
                      clearable: "",
                      "single-line": "",
                      "clear-icon": "mdi-close-circle-outline",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "cursor-pointer",
                    attrs: {
                      search: _vm.search,
                      headers: _vm.headers,
                      items: _vm.products,
                      "no-results-text": "",
                      "item-key": "number",
                    },
                    on: { "click:row": _vm.handleSelectedProduct },
                    scopedSlots: _vm._u([
                      {
                        key: "item.ProductTypeId",
                        fn: function () {
                          return [
                            _c("img", {
                              staticStyle: { "vertical-align": "middle" },
                              attrs: { src: _vm.icon, width: "30px" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }