<template>
  <v-card>
    <v-card-text>
      <v-card-title> Callnummer: {{ device.Callnumber }} </v-card-title>
      <v-card-subtitle>
        {{ device.Type }} ({{ device.Environment }})
      </v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>Verbindungen</v-card-title>
            <v-card-text>
              <p v-for="(channel, i) in device.Channels" :key="i">
                {{ channel.Address }}:{{ channel.Port }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Wöchentliche Verfügbarkeit</v-card-title>
            <v-card-text>
              <p>Primär 99,3%</p>
              <p>Gesamt 97,3%</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>Meldelinien</v-card-title>
            <v-card-text>
              <p>Scharf Nein</p>
              <p>Alarm Nein</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Jährliche Verfügbarkeit</v-card-title>
            <v-card-text>
              <p>Primär 87,5%</p>
              <p>Gesamt 90,6%</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>Letzte Meldungen</v-card-title>
            <v-card-text>
              Meldung:
              <p>Einbruch zurückgesetzt</p>
              <p>Letzter Routineanruf 06.05.2019 16:44:00</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    device: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
