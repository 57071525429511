<template>
  <v-row>
    <v-col v-for="user in users" :key="user.id" sm="3" lg="2">
      <v-card outlined tile height="150" @click="selectUser(user)">
        <v-container fill-height class="pa-0">
          <v-row justify="center" align="center" class="ma-0 text-h4">
            <v-col cols="auto" style="font-weight: bold;">
              {{ user.username }}
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    function selectUser(user) {
      emit("userSelected", user);
    }

    return { selectUser };
  }
};
</script>
<style scoped>
.selectedRow {
  font-weight: bolder;
}
</style>
