<template>
  <v-menu
    v-model="showMenu"
    offset-y
    origin="left top"
    transition="slide-y-transition"
    content-class="quicklink-dropdown"
    right
    class="quick-link"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon large class="my-0" v-on="on">
        <v-icon medium>apps</v-icon>
      </v-btn>
    </template>
    <div class="dropdown-content">
      <div class="dropdown-top d-custom-flex primary">
        <span class="white--text fw-bold ml-3">
          Apps
        </span>
      </div>
      <v-list rounded>
        <v-list-item-group v-model="navigationIndex" mandatory>
          <v-list-item @click="navigateToDashboard()">
            <v-list-item-icon>
              <v-icon color="primary">
                $vuetify.icons.home
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(app, index) in apps"
            :key="app.title"
            @click="mount(app, index + 1)"
          >
            <v-list-item-icon>
              <v-icon color="primary" v-text="app.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="app.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import { sync } from "vuex-pathify";
import MouseTrap from "mousetrap";
import shortcuts from "@/data/shortcuts";

export default {
  data: () => ({
    showMenu: false,
    apps: []
  }),
  computed: {
    navigationIndex: sync("app/navigationIndex"),
    selectedLink: {
      get: function() {
        return this.navigationIndex;
      },
      set: function(val) {
        this.navigationIndex = val;
      }
    }
  },
  mounted() {
    MouseTrap.bind(shortcuts.find(s => s.name == "appdrawer").keyCodes, () => {
      this.showMenu = !this.showMenu;
    });

    MouseTrap.bind(shortcuts.find(s => s.name == "dashboard").keyCodes, () => {
      this.navigateToDashboard();
    });

    this.$router.getLinksForCurrentUser().forEach(link =>
      this.apps.push({
        title: link.name,
        icon: link.meta.icon,
        isApp: false
      })
    );
  },
  destroyed() {
    MouseTrap.unbind(shortcuts.find(s => s.name == "appdrawer").keyCodes);
    MouseTrap.unbind(shortcuts.find(s => s.name == "dashboard").keyCodes);
  },
  methods: {
    navigateToDashboard() {
      if (this.$route.path != "/dashboard") {
        this.navigationIndex = 0;
        this.$router.push("/dashboard");
      }
    },
    mount(link, index) {
      if (this.navigationIndex != index) {
        this.navigationIndex = index;
        if (link.isApp) this.$router.replace({ name: "Plugin", params: link });
        else this.$router.push({ name: link.title });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn:before {
  content: none;
}
</style>
