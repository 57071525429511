var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "drag",
    {
      attrs: { draggable: _vm.moveMode },
      on: {
        dragstart: function ($event) {
          return _vm.handleDrag(_vm.sortedList)
        },
        dragend: _vm.itemDragHandler,
      },
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            disabled: _vm.itemDrag,
            "min-height": "140",
            "min-width": "40",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSelectedProduct(_vm.sortedList)
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.handleSelectedProduct(_vm.sortedList)
            },
          },
        },
        [
          _c("v-card-title", { staticClass: "font-md pb-1" }, [
            _c(
              "div",
              [
                _vm.sortedList.isFavourite
                  ? _c(
                      "v-icon",
                      { staticClass: "ml-1 pb-1", attrs: { color: "#FFD700" } },
                      [_vm._v(" fan fa-star ")]
                    )
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.sortedList.name) + " "),
              ],
              1
            ),
          ]),
          _c(
            "v-card-text",
            { staticClass: "font-md" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-1 pb-0" },
                    [
                      _c("v-dialog", {
                        attrs: { width: "500" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          tabindex: "-1",
                                          disabled: _vm.showMenu,
                                          icon: "",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("fas fa-images")])],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (dialog) {
                              return [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-title",
                                      { staticClass: "headline" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.sortedList.name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-card-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.sortedList.number) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src:
                                              "http://pbpps.gselectronic.com/zp/" +
                                              _vm.sortedList.number +
                                              ".jpg",
                                          },
                                          on: {
                                            error: function ($event) {
                                              return _vm.setAltImg()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              text: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                dialog.value = false
                                              },
                                            },
                                          },
                                          [_vm._v(" Schließen ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" " + _vm._s(_vm.sortedList.number) + " "),
                    ],
                    1
                  ),
                  _vm.isFolder
                    ? _c(
                        "v-col",
                        {
                          staticClass: "text-right pt-1 pb-0",
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  tabindex: "-1",
                                                  small: "",
                                                  icon: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.removeFromDirectory(
                                                      _vm.sortedList.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("fas fa-folder-minus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1081827529
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v("Produkt aus dem Order entfernen"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }