var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: { tile: "", loading: _vm.gsmtp.loading },
      on: { click: _vm.$listeners["click"] },
    },
    [
      _c(
        "div",
        { staticClass: "text-center pt-3" },
        [
          _c(
            "v-icon",
            {
              attrs: {
                large: "",
                color: _vm.gsmtp.available ? "green" : "red",
              },
            },
            [_vm._v(" " + _vm._s(_vm.icon) + " ")]
          ),
        ],
        1
      ),
      _c("v-card-title", { staticStyle: { "white-space": "nowrap" } }, [
        _vm._v(" " + _vm._s(_vm.gsmtp.name) + " "),
      ]),
      _c(
        "v-card-subtitle",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { color: "#616161" } },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" timeline ")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.gsmtp.stats.MessagesPerMinute) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Meldungen pro Minute")])]
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _vm._v(" " + _vm._s(_vm.gsmtp.environment) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }