<template>
  <v-row>
    <v-col cols="12">
      <v-card flat color="rgba(0, 0, 0, 0)">
        <v-card-text class="py-0">
          <v-icon small color="primary">
            $vuetify.icons.hub
          </v-icon>
          Apps
        </v-card-text>
      </v-card>
      <v-row>
        <!-- internal apps -->
        <v-col
          v-for="(link, index) in availableLinks"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-card height="200" outlined tile @click="navigate(link, index + 1)">
            <v-card-title class="fill-height">
              <v-row align="center" style="text-align: center">
                <v-col cols="12">
                  <v-icon large color="primary" v-text="link.meta.icon" />
                </v-col>
                <v-col cols="12">
                  {{ link.name }}
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <!-- <v-btn
      fixed
      dark
      fab
      bottom
      right
      color="primary"
      @click="displayAppStore = true"
    >
      <v-icon>fab fa-app-store-ios</v-icon>
    </v-btn> -->
  </v-row>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  computed: {
    availableLinks() {
      return this.$router.getLinksForCurrentUser();
    },
    navigationIndex: sync("app/navigationIndex")
  },
  mounted() {
    //this.$keycloak.loadUserProfile().success(x => console.log(x));
    this.navigationIndex = null;
  },
  methods: {
    navigate(to, index) {
      this.navigationIndex = index;
      this.$router.push(to.path);
    },
    mount(app, index) {
      this.navigationIndex = this.availableLinks.length + index;
      this.$router.replace({ name: "Plugin", params: app });
    }
  }
};
</script>
