var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("vue-snotify"),
      !_vm.hideAppBar ? _c("AppToolbar") : _vm._e(),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            {
              style: [
                _vm.hideAppBar
                  ? {
                      padding: "0px",
                    }
                  : {
                      "padding-left": _vm.globalPadding + "px",
                      "padding-right": _vm.globalPadding + "px",
                    },
              ],
              attrs: { fluid: "" },
            },
            [
              _c("router-view"),
              _c(
                "v-bottom-sheet",
                {
                  model: {
                    value: _vm.showSettingsDialog,
                    callback: function ($$v) {
                      _vm.showSettingsDialog = $$v
                    },
                    expression: "showSettingsDialog",
                  },
                },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "text-center" },
                    [_c("Settings")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }