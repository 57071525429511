<template>
  <v-row dense>
    <v-col>
      <v-row>
        <v-col cols="auto">
          <UserTable
            :users="users"
            :loading="loading"
            :isUserSelected="isUserSelected"
            @userSelected="handleUserSelected"
            @addUser="addUser"
            @getUsersServerless="getUsersServerless"
          />
        </v-col>
        <v-divider vertical />
        <v-col>
          <v-card v-if="selectedUser == null">
            <v-card-text>Kein Mitarbeiter ausgewählt</v-card-text>
          </v-card>
          <UserDetailsTabs
            v-else
            :user="selectedUser"
            :products="products"
            @deleted="deleteUser"
            @updated="updateUser"
            @productChoice="productChoice"
            @favouriteChoice="favouriteChoice"
            @deselectUser="handleDeselectUser"
            @unassignAllProducts="unassignAllProducts"
            @removeAllFavourites="removeAllFavourites"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <ConnectedUsers />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from "@vue/composition-api";
import UserTable from "@/components/PDEManager/UserTable";
import UserDetailsTabs from "@/components/PDEManager/UserDetailsTabs";
import ConnectedUsers from "@/components/PDEManager/ConnectedUsers";
import Promise from "bluebird";
import api from "@/api/pps";

export default {
  components: {
    UserTable,
    UserDetailsTabs,
    ConnectedUsers,
  },
  setup(props, { emit }) {
    const users = ref([]);
    const products = ref([]);
    const deletedUser = ref(null);
    const selectedUser = ref(null);
    const loading = ref(false);
    const isUserSelected = ref(false);

    loading.value = true;
    Promise.all([api.getUsersServerless(true), api.getProductsServerless()])
      .spread((usersResult, productsResult) => {
        users.value = usersResult.data;
        products.value = productsResult.data;
      })
      .finally(() => {
        loading.value = false;
      });

    function updateUser(val) {
      api.update(val);
    }
    function addUser(val) {
      api.insert(val).then((uid) => {
        return api.getUsersServerless().then((usersFromServer) => {
          users.value = usersFromServer.data;
        });
      });
    }
    function productChoice(userId, itemId, isToggled) {
      if (isToggled) {
        api
          .InsertProductInUserProductList(userId, itemId)
          .then(() => {
            this.$snotify.success("Das Produkt wurde zugewiesen 👍");
          })
          .catch(() =>
            this.$snotify.error("Das Produkt konnte nicht zugewiesen werden ")
          );
      } else {
        api
          .RemoveProductInUserProductList(userId, itemId)
          .then(() => {
            this.$snotify.info("Die Zuweisung wurde entfernt 👍");
          })
          .catch(() => {
            this.$snotify.error("Die Zuweisung konnte nicht entfernt werden");
          });
      }
    }
    function favouriteChoice(userId, itemId, isFavourite) {
      api
        .InsertProductInUserFavouriteList(userId, itemId, isFavourite)
        .then(() => {});
    }
    function getUsersServerless() {
      return api.getUsersServerless().then((usersFromServer) => {
        users.value = usersFromServer.data;
      });
    }
    function deleteUser(val) {
      api.delete(val).then((uid) => {
        return api.getUsersServerless().then((usersFromServer) => {
          users.value = usersFromServer.data;
        });
      });
      selectedUser.value = null;
    }
    function handleDeselectUser() {
      selectedUser.value = null;
      isUserSelected.value = false;
    }
    function unassignAllProducts(userId) {
      api
        .UnassignAllProducts(userId)
        .then(() => {
          this.$snotify.info("Alle Zuweisungen wurden entfernt 👍");
        })
        .catch(() => {
          this.$snotify.error("Die Zuweisungen konnten nicht entfernt werden");
        });
    }
    function removeAllFavourites(userId) {
      api.RemoveAllFavourites(userId);
    }
    function handleUserSelected(user) {
      if (user == null) {
        isUserSelected.value = false;
      } else {
        isUserSelected.value = true;
      }

      if (user.userProductList == null) {
        user.userProductList = [];
      }
      selectedUser.value = user;
    }
    return {
      users,
      products,
      deletedUser,
      selectedUser,
      loading,
      isUserSelected,
      updateUser,
      addUser,
      productChoice,
      favouriteChoice,
      getUsersServerless,
      deleteUser,
      handleDeselectUser,
      unassignAllProducts,
      removeAllFavourites,
      handleUserSelected,
    };
  },
};
</script>
