<template>
  <v-row>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="5"><DevicesList v-model="selectedDevice" /> </v-col>
        <!--v-col cols="auto">
          <v-divider vertical></v-divider>
        </v-col-->
        <v-col v-if="selectedDevice != null" cols="7">
          <v-row dense>
            <v-col>
              <!-- <component
                :is="VDSDetailsTabs"
                :device="selectedDevice"
              ></component> -->
              <DetailsTabs :device="selectedDevice" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DevicesList from "@/components/Control/DevicesTab/DevicesList.vue";
import DetailsTabs from "@/components/Control/DevicesTab/DetailsTabs.vue";
import { ref, computed } from "@vue/composition-api";

export default {
  components: {
    DetailsTabs,
    DevicesList
    // GSMTPDetailsTabs
  },
  setup(props) {
    const selectedDevice = ref(null);
    const vdsServices = computed(() => {
      return servers.VDS;
    });
    const gsmtps = computed(() => {
      return servers.GSMTP;
    });

    return { selectedDevice, vdsServices, gsmtps };
  }
};
</script>
