var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c("v-card-title", [
            _vm._v(" Callnummer: " + _vm._s(_vm.device.Callnumber) + " "),
          ]),
          _c("v-card-subtitle", [
            _vm._v(
              " " +
                _vm._s(_vm.device.Type) +
                " (" +
                _vm._s(_vm.device.Environment) +
                ") "
            ),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Verbindungen")]),
                      _c(
                        "v-card-text",
                        _vm._l(_vm.device.Channels, function (channel, i) {
                          return _c("p", { key: i }, [
                            _vm._v(
                              " " +
                                _vm._s(channel.Address) +
                                ":" +
                                _vm._s(channel.Port) +
                                " "
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v("Wöchentliche Verfügbarkeit"),
                      ]),
                      _c("v-card-text", [
                        _c("p", [_vm._v("Primär 99,3%")]),
                        _c("p", [_vm._v("Gesamt 97,3%")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Meldelinien")]),
                      _c("v-card-text", [
                        _c("p", [_vm._v("Scharf Nein")]),
                        _c("p", [_vm._v("Alarm Nein")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Jährliche Verfügbarkeit")]),
                      _c("v-card-text", [
                        _c("p", [_vm._v("Primär 87,5%")]),
                        _c("p", [_vm._v("Gesamt 90,6%")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Letzte Meldungen")]),
                      _c("v-card-text", [
                        _vm._v(" Meldung: "),
                        _c("p", [_vm._v("Einbruch zurückgesetzt")]),
                        _c("p", [
                          _vm._v("Letzter Routineanruf 06.05.2019 16:44:00"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }