var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("DevicesList", {
                    model: {
                      value: _vm.selectedDevice,
                      callback: function ($$v) {
                        _vm.selectedDevice = $$v
                      },
                      expression: "selectedDevice",
                    },
                  }),
                ],
                1
              ),
              _vm.selectedDevice != null
                ? _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("DetailsTabs", {
                                attrs: { device: _vm.selectedDevice },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }