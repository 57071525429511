var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-stepper",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-stepper-header",
                { attrs: { outlined: "", tile: "" } },
                [
                  _c("v-stepper-step", { attrs: { step: "1" } }, [
                    _vm._v(" Benutzerauswahl "),
                  ]),
                  _c("v-divider"),
                  _c("v-stepper-step", { attrs: { step: "2" } }, [
                    _vm._v(" PIN-Eingabe "),
                  ]),
                  _c("v-divider"),
                  _c("v-stepper-step", { attrs: { step: "3" } }, [
                    _vm._v(" Produktauswahl "),
                  ]),
                  _c("v-divider"),
                  _c("v-stepper-step", { attrs: { step: "4" } }, [
                    _vm._v(" Produktion "),
                  ]),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _vm.currentStep == 1
                        ? _c("UserSelection", {
                            attrs: { users: _vm.users },
                            on: { userSelected: _vm.handleUserClicked },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c(
                        "v-overlay",
                        {
                          attrs: {
                            absolute: "",
                            value: !_vm.passwordInputEnabled,
                          },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 70,
                              width: 10,
                              color: "blue",
                              indeterminate: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.currentStep == 2
                        ? _c("UserPasswordInput", {
                            attrs: { currentStep: _vm.currentStep },
                            on: {
                              input: _vm.handlePasswordInput,
                              backspace: _vm.handlePasswordDelete,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    tile: "",
                                    "x-large": "",
                                    color: "error",
                                  },
                                  on: { click: _vm.stepBack },
                                },
                                [_vm._v(" Zurück ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _vm.currentStep == 3
                        ? _c("ProductSelection", {
                            attrs: {
                              selectedUser: _vm.selectedUser,
                              directoryId: _vm.directoryId,
                              products: _vm.products,
                              currentStep: _vm.currentStep,
                              directoryOpened: _vm.directoryOpened,
                            },
                            on: {
                              handleListChange: _vm.handleListChange,
                              handleMoveToDirectory: _vm.handleMoveToDirectory,
                              handleRemoveFromDirectory:
                                _vm.handleRemoveFromDirectory,
                              handleDirectoryOpened: _vm.handleDirectoryOpened,
                              addDirectory: _vm.addDirectory,
                              deleteDirectory: _vm.deleteDirectory,
                              handleSelectedProduct: _vm.handleSelectedProduct,
                              updateDirectory: _vm.updateDirectory,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: 5 } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-4 mr-2",
                                      attrs: {
                                        tile: "",
                                        "x-large": "",
                                        color: "error",
                                      },
                                      on: { click: _vm.stepToFirst },
                                    },
                                    [_vm._v(" Abmelden ")]
                                  ),
                                  _vm.directoryOpened
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            tile: "",
                                            "x-large": "",
                                            color: "error",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDirectoryOpened()
                                            },
                                          },
                                        },
                                        [_vm._v(" Zurück ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-col", [
                            _c(
                              "div",
                              { attrs: { align: "right" } },
                              [
                                _c(
                                  "v-dialog",
                                  {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { "x-large": "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" Historie ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.displayProductionHistory,
                                      callback: function ($$v) {
                                        _vm.displayProductionHistory = $$v
                                      },
                                      expression: "displayProductionHistory",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5" },
                                          [_vm._v(" Erfasste Produktionen ")]
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c("v-data-table", {
                                              staticClass: "elevation-1",
                                              attrs: {
                                                headers:
                                                  _vm.productionHistoryTableHeaders,
                                                items:
                                                  _vm.productionHistoryTableItems,
                                                "items-per-page": 10,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("v-divider"),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.displayProductionHistory = false
                                                  },
                                                },
                                              },
                                              [_vm._v(" OK ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "4" } },
                    [
                      _c("Production", {
                        attrs: {
                          selectedUser: _vm.selectedUser,
                          selectedProductIds: _vm.selectedProductIds,
                          amount: _vm.amount,
                          currentStep: _vm.currentStep,
                        },
                        on: {
                          onSubmit: _vm.handleProductionSubmit,
                          stepToFirst: _vm.stepToFirst,
                          stepToProductSelection: _vm.stepToProductSelection,
                          handleDirectoryOpened: _vm.handleDirectoryOpened,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: "4000", right: "", color: _vm.snackbarColor },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
          ),
          _vm.currentStep == 1
            ? _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    absolute: "",
                    bottom: "",
                    right: "",
                    color: "red",
                  },
                  on: { click: _vm.logout },
                },
                [_c("v-icon", [_vm._v("power_settings_new")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }