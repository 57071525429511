export const icons = [
    { name: "Ordner", icon: "fas fa-folder", id: 0 },
    { name: "Haus", icon: "fas fa-home", id: 1 },
    { name: "Karton", icon: "fas fa-box", id: 2 },
    { name: "Welt", icon: "fas fa-globe-africa", id: 3 },
    { name: "Flagge", icon: "fas fa-flag", id: 4 },
    { name: "Lkw", icon: "fas fa-truck", id: 5 },
    { name: "Etikett", icon: "fas fa-tag", id: 6 },
    { name: "Lesezeichen", icon: "fas fa-bookmark", id: 7 },
    { name: "Archiv", icon: "fas fa-archive", id: 8 },
    { name: "Spock", icon: "fas fa-hand-spock", id: 9 },
    { name: "Herz", icon: "fas fa-heart", id: 10 },
    { name: "Lager", icon: "fas fa-warehouse", id: 11 },
    { name: "Kafee", icon: "fas fa-mug-hot", id: 12 }
  ],
  colors = [
    { name: "Grau", color: "#7f7f7f", id: 0 },
    { name: "Schwarz", color: "#000000", id: 1 },
    { name: "Rot", color: "#CF2233", id: 2 },
    { name: "Orange", color: "#F26946", id: 3 },
    { name: "Gelb", color: "#FAE80B", id: 4 },
    { name: "Grün", color: "#0C8142", id: 5 },
    { name: "Blau", color: "#2D63AF", id: 6 },
    { name: "Lila", color: "#4D3292", id: 7 },
    { name: "Pink", color: "#F9CDE0", id: 8 },
    { name: "Braun", color: "#81592F", id: 9 },
    { name: "ESECA Orange", color: "#f99a35", id: 10 },
    { name: "GS Blau", color: "#213173", id: 11 },
    { name: "Vitakt Grün", color: "#75B727", id: 12 }
  ];
