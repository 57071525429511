<template>
  <v-card>
    <v-row>
      <v-col cols="auto"> <v-card-title> Übsersicht </v-card-title></v-col>
      <v-col cols="auto">
        <v-text-field
          append-icon="mdi-magnify"
          single-line
          hide-details
          v-model="tableSearch"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :search="tableSearch"
          class="justify-center my-2"
          :headers="tableHeaders"
          :items="canceledCallnumbers"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="error" @click="callnumberDelete(item)">
              <v-icon>fan fa-trash</v-icon></v-btn
            >
          </template>
          <template v-slot:item.IstGekuendigt="{ item }">
            <template v-if="item.IstGekuendigt">Ja</template>
            <template v-if="!item.IstGekuendigt">Nein</template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ref, computed } from "@vue/composition-api";

export default {
  components: {},
  props: {
    uploadedCallnumbers: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const tableSearch = ref("");

    const tableHeaders = ref([
      { text: "", value: "actions", sortable: false },
      { text: "Rahmenvertrag", value: "Rahmenvertrag" },
      { text: "Kundennummer", value: "Kundennummer" },
      { text: "GPFirmenname", value: "GPFirmenname" },
      { text: "GPStraße", value: "GPStraße" },
      { text: "GPWohnort", value: "GPWohnort" },
      { text: "GPPLZ", value: "GPPLZ" },
      { text: "GPLand", value: "GPLand" },
      { text: "Profilnummer", value: "Profilnummer" },
      { text: "Rufnummer", value: "Rufnummer" },
      { text: "Kundenkonto", value: "Kundenkonto" },
      { text: "REFirmenname", value: "REFirmenname" },
      { text: "gekündigt", value: "IstGekuendigt" },
      { text: "REStraße", value: "REFirmenname" },
      { text: "REWohnort", value: "REWohnort" },
      { text: "REPLZ", value: "REPLZ" },
      { text: "RELand", value: "RELand" },
      { text: "Rechnungszahlart", value: "Rechnungszahlart" },
      { text: "Kreditinstitut", value: "Kreditinstitut" },
      { text: "IBAN", value: "IBAN" },
      { text: "BIC", value: "BIC" },
      { text: "Rechnungsmedium", value: "Rechnungsmedium" },
    ]);

    const uniquieContractHeaders = computed(() => {
      const uniqueValues = [];
      props.uploadedCallnumbers.forEach((obj) => {
        if (!uniqueValues.includes(obj.Rahmenvertrag)) {
          uniqueValues.push(obj.Rahmenvertrag);
        }
      });
      return uniqueValues;
    });
    const canceledCallnumbers = computed(() => {
      return filterByIstGekuendigt(props.uploadedCallnumbers);
    });

    function filterByIstGekuendigt(array) {
      return array.filter(function (obj) {
        return obj.IstGekuendigt === false;
      });
    }
    function callnumberDelete(item) {
      emit("callnumberDelete", item);
    }

    return {
      tableHeaders,
      tableSearch,
      canceledCallnumbers,
      uniquieContractHeaders,
      filterByIstGekuendigt,
      callnumberDelete,
    };
  },
};
</script>
