var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(" Aktuelle PPS-Benutzer ")]),
      _vm.hasConnectedUsers
        ? _c(
            "v-card-text",
            _vm._l(_vm.connectedUsers, function (user, index) {
              return _c(
                "v-list-item",
                { key: index },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      user.writeAccess
                        ? _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v(" $vuetify.icons.ppsWriter "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(_vm._s(user.username))])],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("v-card-text", [
            _vm._v(" Zurzeit ist kein Mitarbeiter in PPS eingeloggt. "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }