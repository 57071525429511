<template>
  <v-card :loading="loading">
    <v-card-title class="pa-0">
      <v-row class="text-center" dense>
        <v-col cols="12">
          <v-icon>mdi-account-multiple</v-icon>
        </v-col>
        <v-col cols="12" class="pa-0">
          Account-Verwaltung
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        sort-by="name"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.username"
              :class="{ selectedRow: item === selectedUser }"
              class="pointerCursor"
              @click="selectItem(item)"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.username }}</td>
              <td>
                <v-icon>fa-chevron-right</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Suche"
              single-line
              hide-details
            />
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn tile color="primary" v-bind="attrs" v-on="on">
                  Mitarbeiter hinzufügen
                </v-btn>
              </template>
              <UserDetails
                :user="editedItem"
                :showDeleteButton="false"
                @submitted="addUser"
              >
                <v-btn color="error" @click="close">Abbrechen</v-btn>
              </UserDetails>
            </v-dialog>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import UserDetails from "@/components/PDEManager/UserDetails";

export default {
  components: {
    UserDetails
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      required: true
    },
    isUserSelected: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const search = ref("");
    const dialog = ref(false);
    const headers = ref([
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Benutzername",
        value: "username"
      },
      {
        text: "",
        value: "action",
        sortable: false
      }
    ]);
    const selectedUser = ref({});
    const defaultItem = ref({
      name: "",
      username: "",
      password: ""
    });
    const editedItem = ref({
      name: "",
      username: "",
      password: ""
    });
    watch(
      () => dialog.value,
      newValue => {
        newValue || close();
      }
    ),
      watch(
        () => props.isUserSelected,
        newValue => {
          if (!props.isUserSelected) {
            selectItem(selectedUser.value);
          }
        }
      );
    function selectItem(item) {
      if (selectedUser.value == item) {
        selectedUser.value = null;
        emit("userSelected", null);
      } else {
        selectedUser.value = item;
        emit("userSelected", selectedUser.value);
      }
    }
    function close() {
      dialog.value = false;
      setTimeout(() => {
        editedItem.value = Object.assign({}, defaultItem.value);
      }, 300);
    }
    function addUser(val) {
      emit("addUser", val);
      close();
    }
    function getUsersServerless() {
      emit("getUsersServerless");
    }
    return {
      search,
      dialog,
      headers,
      selectedUser,
      editedItem,
      selectItem,
      close,
      addUser
    };
  }
};
</script>
