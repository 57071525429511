export default {
  VDS: [
    {
      name: "RZ30AES1",
      url: "http://91.190.144.227",
      port: "13562",
      legacyProxyPort: "13561",
      api: "api/v1",
      environment: "Live"
    },
    {
      name: "RZ32AES2",
      url: "http://178.255.137.243",
      port: "13562",
      legacyProxyPort: "13561",
      api: "api/v1",
      environment: "Live"
    },
    {
      name: "RZ130AESV1",
      url: "http://178.255.137.238",
      port: "13562",
      legacyProxyPort: "13561",
      api: "api/v1",
      environment: "Vision"
    }
  ],
  GSMTP: [
    {
      name: "GSMQ30",
      url: "https://gsmq30.gselectronic.com",
      port: "13566",
      api: "api/v1",
      environment: "Live"
    },
    {
      name: "GSMQ32",
      url: "https://gsmq32.gselectronic.com",
      port: "13566",
      api: "api/v1",
      environment: "Live"
    },
    {
      name: "GSMQ90",
      url: "https://rz90gsmtp.gselectronic.com",
      port: "13566",
      api: "api/v1",
      environment: "Live"
    },
    {
      name: "RZ130GSMTPV1",
      url: "https://gsmtpv1.gselectronic.com",
      port: "13566",
      api: "api/v1",
      environment: "Vision"
    }
  ]
};
