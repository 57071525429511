<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="user.name"
            :rules="nameRules"
            :counter="50"
            validate-on-blur
            label="Mitarbeiter"
            required
          />
          <v-text-field
            v-model="user.username"
            :rules="usernameRules"
            :counter="3"
            validate-on-blur
            label="Benutzername"
            required
          />
          <v-text-field
            v-model="user.password"
            autocomplete="current-password"
            label="Pin festlegen"
            required
            :rules="passwordRules"
            :append-icon="obfuscatePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="obfuscatePassword ? 'password' : 'text'"
            @click:append="obfuscatePassword = !obfuscatePassword"
          />
          <v-row>
            <v-col cols="auto">
              <v-btn tile color="primary" dark @click="deselectUser">
                Zurück
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                v-if="showDeleteButton"
                tile
                color="red"
                dark
                @click="deleteUser"
              >
                Mitarbeiter löschen
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn tile color="primary" dark @click="submit()">
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const valid = ref(false);
    const form = ref(null);
    const obfuscatePassword = ref(false);
    const nameRules = [
      v => !!v || "Der Name des Mitarbeiters ist Pflicht!",
      v =>
        v.length < 50 ||
        "Der Name des Mitarbeiters darf maximal 50 Zeichen lang sein.",
      v =>
        v.length >= 3 ||
        "Der Name des Mitarbeiters muss mindestens 3 Zeichen lang sein."
    ];
    const usernameRules = [
      v => !!v || "Der Benutzername für Mitarbeiter ist Pflicht!",
      v => v.length == 3 || "Der Benutzername (Kürzel) muss 3 Zeichen enthalten"
    ];
    const passwordRules = [
      v => !!v || "Eine Pin ist Pflicht!",
      v => v.length == 4 || "Der Pin muss 4 Zeichen lang sein. ",
      v => Number.isInteger(Number(v)) || "Der Pin muss aus Nummern bestehen."
    ];
    watch(
      () => props.user.username,
      newValue => {
        props.user.username = props.user.username.toUpperCase();
      }
    );
    watch(
      () => props.user,
      newValue => {
        clear();
      }
    );
    function deselectUser() {
      emit("deselectUser");
    }
    function deleteUser() {
      emit("deleted", props.user);
    }
    function submit() {
      form.value.validate();
      if (valid.value) {
        emit("updated", props.user);
        emit("submitted", props.user);
      }
    }
    function clear() {
      form.value.resetValidation();
    }
    return {
      valid,
      form,
      obfuscatePassword,
      nameRules,
      usernameRules,
      passwordRules,
      deselectUser,
      deleteUser,
      submit,
      clear
    };
  }
};
</script>
