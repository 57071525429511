import Vue from "vue";
import Vuetify from "vuetify";
import de from "vuetify/es5/locale/de";

// import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3f51b5",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c"
      },
      dark: {
        primary: "#3f51b5",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c"
      }
    }
  },
  lang: {
    locales: { de },
    current: "de"
  },
  icons: {
    iconfont: "fa" || "mdi",
    values: {
      sim:  "fa-solid fa-sim-card",
      warning: "fas fa-exclamation-triangle",
      home: "fas fa-home",
      smileRegular: "far fa-smile",
      smile: "fas fa-smile",
      frown: "fas fa-frown",
      meh: "fas fa-meh",
      hub: "fab fa-hubspot",
      star: "far fa-star",
      gsmtp: "fas fa-globe-americas",
      barchart: "far fa-chart-bar",
      heart: "fas fa-heart",
      calendarDay: "fas fa-calendar-day",
      calendarMonth: "fas fa-calendar-alt",
      glasses: "fas fa-glasses",
      toolbox: "fas fa-toolbox",
      manager: "local_shipping",
      ppsWriter: "fas fa-user-edit",
      info: "fas fa-info-circle",
      dev: "fab fa-dev",
      external: "fas fa-external-link-alt",
      satellite: "fas fa-satellite",
      excel: "fas fa-file-excel",
      servicemanager: "build"
    }
  }
});
