var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _c(
                  "p",
                  { staticClass: "text-h5 font-weight-bold text-center" },
                  [_vm._v("GSMTP")]
                ),
              ]),
              _c("v-col", [
                _c(
                  "p",
                  { staticClass: "text-h5 font-weight-bold text-center" },
                  [_vm._v("VDS")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    _vm._l(_vm.gsmtpObjs, function (gsmtp) {
                      return _c(
                        "v-col",
                        {
                          key: gsmtp.name,
                          attrs: { lg: "4", md: "6", cols: "12" },
                        },
                        [
                          _c("GSMTPTile", {
                            class:
                              gsmtp == _vm.currentServer
                                ? "selectedTile"
                                : "mx-2",
                            attrs: { gsmtp: gsmtp },
                            on: {
                              click: function ($event) {
                                return _vm.showGsmtpDetails(gsmtp)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("v-divider", { attrs: { vertical: "" } })],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    _vm._l(_vm.vdsObjs, function (vds) {
                      return _c(
                        "v-col",
                        {
                          key: vds.name,
                          attrs: { lg: "4", md: "6", cols: "12" },
                        },
                        [
                          _c("VDSTile", {
                            class:
                              vds == _vm.currentServer
                                ? "selectedTile"
                                : "mx-2",
                            attrs: { server: vds },
                            on: {
                              click: function ($event) {
                                return _vm.showVDSDetails(vds)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [_c("v-col", { attrs: { cols: "12" } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("v-divider", { attrs: { vertical: "" } })],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mx-2" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-simple-table", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Name")]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Modus")]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Colocation")]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Version")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.vdsObjs,
                                                function (vds) {
                                                  return _c(
                                                    "tr",
                                                    { key: vds.name },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(vds.name)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              vds.stats.Mode
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              vds.legacyProxy
                                                                .ActiveNode.ip
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              vds.stats.Version
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.currentComponent
                ? _c(
                    "v-col",
                    [
                      _vm.currentServer != undefined
                        ? _c(_vm.currentComponent, {
                            tag: "component",
                            attrs: { service: _vm.currentServer },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }