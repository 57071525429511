<template>
  <v-row dense>
    <v-col class="py-0">
      <v-text-field
        id="customLabel"
        :value="amount"
        placeholder="0"
        :rules="requiredRule"
        class="font-size customLabel"
        label="Stückzahl"
        hide-details
        single-line
        outlined
        @input="setNumber"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ref } from "@vue/composition-api";
export default {
  props: {
    amount: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const requiredRule = [value => !!value || "Pflichtfeld"];
    function setNumber(val) {
      emit("setNumber", val);
    }
    return { requiredRule, setNumber };
  }
};
</script>

<style>
.theme--light.v-input--is-disabled input {
  color: #444;
}

.customLabel label.v-label.theme--light {
  font-size: 2em;
  line-height: inherit;
  margin: auto 0;
  height: auto;
}
.customLabel label.v-label.theme--light {
  font-size: 2em;
  line-height: inherit;
  margin: auto 0;
  height: auto;
}

.customLabel label.v-label.v-label--active.theme--light,
.customLabel label.v-label.v-label--active.theme--dark {
  font-size: 1.5em;
  letter-spacing: 4px;
  line-height: inherit;
  margin: -7px 0 auto 0;
  height: auto;
}
</style>
