<template>
  <v-card>
    <v-data-table
      :loading="dataLoading"
      :sort-by.sync="sortBy"
      :items="filteredMessages"
      :search="search"
      :headers="messageHeaders"
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            clearable
            single-line
            hide-details
          />
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-4" v-bind="attrs" v-on="on"
                >{{ menuLabel
                }}<v-icon small right>fas fa-filter</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item
                v-for="(messageType, index) in messageTypes"
                :key="index"
                link
              >
                <v-list-item-title @click="filterMessages(messageTypes[index])">
                  {{ messageTypes[index] }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import apiGsmtp from "@/api/gsmtp";
import apiVds from "@/api/vds";

export default {
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const search = ref("");
    const dataLoading = ref(true);
    const count = ref(0);
    const menuLabel = ref("Filter");
    const sortBy = ref("ReceivedAtGSMTPTimestamp");
    const messageHeaders = ref([
      {
        text: "Empfangszeit",
        value: "ReceivedAtGSMTPTimestamp"
      },
      {
        text: "Meldung",
        value: "ReceivedData",
        sortable: false
      },
      {
        text: "ID",
        value: "ID"
      }
    ]);
    const messageTypes = ref([]);
    const filteredMessages = ref([]);
    const messages = ref([]);

    onMounted(() => {
      filteredMessages.value = messages.value;
      loadMessages();
    });

    watch(
      () => props.device,
      newValue => {
        loadMessages();
        filteredMessages.value = [];
      }
    );

    function loadMessages() {
      dataLoading.value = true;
      if (props.device.Type === "GSMTP") {
        apiGsmtp
          .getEventsData(
            "https://gsmq30.gselectronic.com" +
              ":" +
              props.device.Origin.port +
              "/" +
              props.device.Origin.api,
            "&callnumber=" + props.device.Callnumber
          )
          .then(result => {
            combineMessage(result.data.values);
          }),
          apiGsmtp
            .getEventsData(
              "https://gsmq32.gselectronic.com" +
                ":" +
                props.device.Origin.port +
                "/" +
                props.device.Origin.api,
              "&callnumber=" + props.device.Callnumber
            )
            .then(result => {
              combineMessage(result.data.values);
            }),
          apiGsmtp
            .getEventsData(
              "https://rz90gsmtp.gselectronic.com" +
                ":" +
                props.device.Origin.port +
                "/" +
                props.device.Origin.api,
              "&callnumber=" + props.device.Callnumber
            )
            .then(result => {
              combineMessage(result.data.values);
            }),
          apiGsmtp
            .getEventsData(
              "https://gsmtpv1.gselectronic.com" +
                ":" +
                props.device.Origin.port +
                "/" +
                props.device.Origin.api,
              "&callnumber=" + props.device.Callnumber
            )
            .then(result => {
              combineMessage(result.data.values);
            });
      } else {
        dataLoading.value = false;
      }
    }
    function filterMessages(value) {
      menuLabel.value = value;

      if (value == "alle Meldungen") {
        filteredMessages.value = messages.value;
      } else {
        filteredMessages.value = messages.value.filter(i =>
          value.includes(i.ReceivedData)
        );
      }
    }
    function combineMessage(data) {
      count.value++;
      if (count.value == 4) {
        dataLoading.value = false;
        filterMessages("alle Meldungen");
        count.value = 0;
      } else {
        if (data.length > 0) {
          messages.value = messages.value.concat(data);
        }
      }
      messageTypes.value = [
        ...new Set(messages.value.map(item => item.ReceivedData))
      ];
      messageTypes.value.push("alle Meldungen");
    }

    return {
      search,
      dataLoading,
      count,
      menuLabel,
      sortBy,
      messageHeaders,
      messageTypes,
      filteredMessages,
      messages
    };
  }
};
</script>

<style></style>
