<template>
  <v-menu offset-y nudge-top="-5" transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon text v-on="on">
        <v-icon color="primary">mdi-account-circle</v-icon>
      </v-btn>
    </template>
    <v-container pa-0>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ currentUsername }}</v-list-item-title>
            <v-list-item-subtitle>
              App-Version: {{ appVersion }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item @click="toggleDarkMode">
          <v-list-item-avatar>
            <v-icon>invert_colors</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ invertedThemeName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="showSettings">
          <v-list-item-avatar>
            <v-icon>settings</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Einstellungen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logoutUser">
          <v-list-item-avatar>
            <v-icon color="red">power_settings_new</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title color="primary">Ausloggen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </v-menu>
</template>

<script>
import AuthService from "@/auth/AuthService";
import { get, sync } from "vuex-pathify";

const auth = new AuthService();
const { logout } = auth;

export default {
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    loggedInUser: get("auth/user"),
    darkMode: sync("settings/darkMode"),
    showSettingsDialog: sync("app/showSettings"),
    invertedThemeName() {
      return this.$vuetify.theme.dark ? "Light mode" : "Dark mode";
    },
    currentUsername() {
      if (this.loggedInUser.firstName && this.loggedInUser.lastName) {
        const fullName =
          this.loggedInUser.firstName + " " + this.loggedInUser.lastName;
        const splittedName = fullName.split(" ");

        if (splittedName.length >= 2) {
          //returns "<FirstName> <First letter of last name>."
          return splittedName[0] + " " + splittedName[1][0] + ".";
        }

        return this.loggedInUser.fullName;
      }

      return "<empty>";
    }
  },
  watch: {
    darkMode(val) {
      this.$vuetify.theme.dark = val;
    }
  },
  methods: {
    applyDarkMode() {},
    logoutUser() {
      logout().then(() => {
        this.$router.push("/login");
      });
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
    },
    showSettings() {
      this.showSettingsDialog = true;
    }
  }
};
</script>
