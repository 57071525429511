var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredDevices,
          search: _vm.search,
          "items-per-page": 10,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function (ref) {
              var items = ref.items
              return [
                _c(
                  "tbody",
                  _vm._l(items, function (item) {
                    return _c(
                      "tr",
                      {
                        key: item.callnumber,
                        staticClass: "pointerCursor",
                        class: { selectedRow: item === _vm.selectedDevice },
                        on: {
                          click: function ($event) {
                            return _vm.getDeviceData(item)
                          },
                        },
                      },
                      [
                        item.Callnumber != null
                          ? _c("td", [
                              _vm._v(" " + _vm._s(item.Callnumber) + " "),
                            ])
                          : _c("td", [
                              _vm._v(" " + _vm._s(item.Origin.name) + " "),
                            ]),
                        _c(
                          "td",
                          [_c("v-icon", [_vm._v("fa-chevron-right")])],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "", justify: "space-around" } },
                          [
                            _c("v-col", [_vm._v("Übertragungsart")]),
                            _vm._l(_vm.types, function (type) {
                              return _c(
                                "v-col",
                                { key: type, attrs: { cols: "12" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      label: type,
                                      value: type,
                                    },
                                    model: {
                                      value: _vm.selectedTypes,
                                      callback: function ($$v) {
                                        _vm.selectedTypes = $$v
                                      },
                                      expression: "selectedTypes",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                        _c(
                          "v-row",
                          { attrs: { dense: "", justify: "space-around" } },
                          [
                            _c("v-col", [_vm._v("Umgebung")]),
                            _vm._l(_vm.environments, function (env) {
                              return _c(
                                "v-col",
                                { key: env, attrs: { cols: "12" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      label: env,
                                      value: env,
                                    },
                                    model: {
                                      value: _vm.selectedEnvironment,
                                      callback: function ($$v) {
                                        _vm.selectedEnvironment = $$v
                                      },
                                      expression: "selectedEnvironment",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                  ],
                  1
                ),
                _c(
                  "v-toolbar",
                  { attrs: { flat: "", dense: "" } },
                  [
                    _c("v-text-field", {
                      ref: "textInput",
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: "Suche",
                        clearable: "",
                        "single-line": "",
                        "hide-details": "",
                      },
                      on: {
                        "click:clear": function ($event) {
                          return _vm.clearDevice()
                        },
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", loading: _vm.loading },
                        on: { click: _vm.loadAllData },
                      },
                      [
                        _vm._v(" Aktualisieren "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v("refresh"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }