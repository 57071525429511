<template>
  <v-tabs grow centered icons-and-text>
    <v-tab>Daten des Mitarbeiter<v-icon>mdi-account-edit</v-icon></v-tab>
    <v-tab>Produkte<v-icon>mdi-account-cog</v-icon></v-tab>
    <v-tab-item>
      <UserDetails
        :user="user"
        :showDeleteButton="true"
        @deselectUser="deselectUser"
        @deleted="deleteUser"
        @updated="updateUser"
      />
    </v-tab-item>
    <v-tab-item>
      <UserProductsAssignment
        :user="user"
        :products="products"
        @updated="updateUser"
        @productChoice="productChoice"
        @favouriteChoice="favouriteChoice"
        @deselectUser="deselectUser"
        @unassignAllProducts="unassignAllProducts"
        @removeAllFavourites="removeAllFavourites"
      />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import UserDetails from "@/components/PDEManager/UserDetails";
import UserProductsAssignment from "@/components/PDEManager/UserProductsAssignment";

export default {
  components: {
    UserDetails,
    UserProductsAssignment
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    function productChoice(userId, itemId, isToggled) {
      emit("productChoice", userId, itemId, isToggled);
    }
    function favouriteChoice(userId, itemId, isFavourite) {
      emit("favouriteChoice", userId, itemId, isFavourite);
    }
    function deleteUser(val) {
      emit("deleted", val);
    }
    function updateUser(val) {
      emit("updated", val);
    }
    function deselectUser() {
      emit("deselectUser");
    }
    function unassignAllProducts(userId) {
      emit("unassignAllProducts", userId);
    }
    function removeAllFavourites(userId) {
      emit("removeAllFavourites", userId);
    }
    return {
      productChoice,
      favouriteChoice,
      deleteUser,
      updateUser,
      deselectUser,
      unassignAllProducts,
      removeAllFavourites
    };
  }
};
</script>
