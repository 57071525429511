// Place the following into its own module file for easy import.
import Vue from "vue";
import Snotify, { SnotifyPosition } from "vue-snotify";

import "vue-snotify/styles/material.css";

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightBottom,
    timeout: 4000
  }
});
