var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: { tile: "", "x-large": "", color: "error" },
              on: { click: _vm.cancel },
            },
            [
              _c("v-icon", { staticClass: "pr-5", attrs: { right: "" } }, [
                _vm._v("mdi-alert"),
              ]),
              _vm._v(" Abbrechen "),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                disabled: _vm.amountError,
                "x-large": "",
                color: "primary",
                dark: "",
              },
              on: { click: _vm.onSubmit },
            },
            [
              _vm._v(" Speichern "),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v("mdi-content-save"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }