import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

axios.interceptors.request.use(
  request => {
    const keycloak = Vue.prototype.$keycloak;
    if (keycloak) {
      const token = keycloak.token;
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
        request.headers.GSSecurity =
          "C553C02C - 6828 - 42AE - 8CEB - 2FE3397B678D";
      }
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
