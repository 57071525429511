<template>
  <v-stepper v-model="stepCount">
    <v-stepper-header>
      <v-divider></v-divider>
      <v-stepper-step :complete="stepCount > 2" step="1">
        Rufnummer kündigen
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2">Übersicht</v-stepper-step><v-divider></v-divider>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row class="ma-auto">
          <v-col>
            <v-row class="justify-center">
              <v-card-title class="text-h5 font-weight-bold"
                >Eingabe</v-card-title
              ></v-row
            >
            <v-text-field
              class="mt-2 mb-8"
              v-model="phonenumberInput"
              label="Rufnummer"
              placeholder=""
              outlined
              prefix="+"
              hide-details
              prepend-icon="fas fa-search"
              @click:prepend="submitSearch(phonenumberInput)"
              @input="submitSearch(phonenumberInput)"
              @keyup.enter="submitSearch(phonenumberInput)"
            ></v-text-field>
            <v-scroll-y-transition group>
              <SimFileUpload
                v-for="index in numberOfLists"
                :key="index"
                :label="labelList"
                :icon="iconList"
                :iconRemove="iconRemove"
                @clickClear="decreaseNumber(1)"
                @parsed="listUploaded"
              ></SimFileUpload>
            </v-scroll-y-transition>
            <v-row class="justify-center my-2">
              <v-btn
                :disabled="!phonenumberValid"
                @click="addToCancel"
                color="success"
                >Kündigen</v-btn
              >
            </v-row>
            <template v-if="phonenumberData != undefined && phonenumberValid">
              <v-simple-table class="justify-center my-2">
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(value, key) in phonenumberData"
                      :key="value.Rufnummer"
                    >
                      <td>
                        {{ key }}
                      </td>
                      <td>
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <v-row
              class="justify-center my-2"
              v-else-if="phonenumberInput != ''"
            >
              <v-banner rounded color="warning">
                <v-icon slot="icon">$vuetify.icons.warning</v-icon>
                Die angegebene Rufnummer ist in keinem der Rahmenverträge
                enthalten
              </v-banner>
            </v-row>
          </v-col>

          <v-col
            class="mx-2"
            v-for="(contract, index) in contracts"
            :key="index"
            ><v-card>
              <v-card-title class="text-h5 font-weight-bold justify-center">{{
                contract
              }}</v-card-title>
              <v-list>
                <v-list-item
                  :value="true"
                  v-for="(item, i) in sortedContracts[contract]"
                  :key="i"
                >
                  <v-list-item-subtitle>{{ item }}</v-list-item-subtitle>
                  <v-list-item-action>
                    <v-btn
                      fab
                      x-small
                      color="primary"
                      @click="submitSearch(item)"
                      ><v-icon>fas fa-info-circle</v-icon></v-btn
                    >
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn
                      fab
                      x-small
                      color="error"
                      @click="removeFromCancelList(item, contract)"
                      ><v-icon>fa-times-circle</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          @click="stepCount++"
          :disabled="cancelList.length < 1"
        >
          Weiter
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-row class="justify-center">
                  <v-card-title class="text-h5 font-weight-bold text-center"
                    >Ausgabe</v-card-title
                  >
                </v-row>
                <v-row>
                  <v-col
                    class="mx-2"
                    v-for="(contract, index) in contracts"
                    :key="index"
                    ><v-card>
                      <v-list
                        ><v-list-item>
                          <v-list-item-title>{{ contract }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :value="true"
                          v-for="(item, i) in sortedContracts[contract]"
                          :key="i"
                        >
                          <v-list-item-subtitle>{{
                            item
                          }}</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-card>
                    <v-col>
                      <v-row class="justify-center my-2">
                        <xlsx-workbook>
                          <xlsx-sheet
                            :collection="sheet.data"
                            v-for="sheet in sheets"
                            :key="sheet.name"
                            :sheet-name="sheet.name"
                          />
                          <xlsx-download :filename="createName(contract)">
                            <v-btn
                              class="ma-2"
                              @click="createXlsx(contract)"
                              color="success"
                              >Herunterladen</v-btn
                            >
                          </xlsx-download>
                        </xlsx-workbook>
                        <v-dialog v-model="downloadDialog" width="500">
                          <v-card>
                            <v-card-title>Fast geschafft!</v-card-title>
                            <v-card-text>
                              Nur noch die Excel Datei aus deinem Download
                              Ordner an die Mail anhängen und abschicken!
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="primary"
                                @click="downloadDialog = false"
                                >Ja</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-btn color="primary" @click="stepCount++"> Weiter </v-btn>
        <v-btn text @click="stepCount--">Zurück</v-btn>
      </v-stepper-content>
    </v-stepper-items></v-stepper
  >
</template>

<script>
import { ref } from "@vue/composition-api";
import SimFileUpload from "./SimFileUpload.vue";
import api from "@/api/sim";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
export default {
  components: { SimFileUpload, XlsxWorkbook, XlsxSheet, XlsxDownload },
  props: {
    uploadedCallnumbers: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const downloadDialog = ref(false);
    const uploadedList = ref([]);
    const contractPreview = ref([]);
    const labelList = ref("Liste mit Rufnummern hochladen...");
    const labelContracts = ref("Rahmenvertrag hinzufügen...");
    const iconList = ref("fas fa-list");
    const iconContract = ref("fas fa-file-excel");
    const iconRemove = ref("fa-times-circle");
    const stepCount = ref(1);
    const phonenumberInput = ref("");
    const uploadedContracts = ref(["29394 (T1&V1)", "HE351 (T1)"]);
    const uploadedContractDetails = ref([]);
    const sortedContracts = ref({});
    const contracts = ref([]);
    const cancelList = ref([]);
    const numberOfLists = ref(1);
    const numberOfUploads = ref(1);
    const phonenumberData = ref([]);
    const phonenumberValid = ref(false);
    const sheets = ref([]);
    const mailToBody = ref(
      "Sehr%20geehrte%20Dame%2C%20Sehr%20geehrter%20Herr%2C%0A%0Aals%20Anlage%20erhalten%20Sie%20von%20uns%20eine%20Liste%20mit%20SIM-Karten%2FRufnummern.%20Ich%20m%C3%B6chte%20Sie%20hiermit%20bitten,%20diese%20SIM-Karten%2FRufnummern%20zu%20k%C3%BCndigen.%20Ich%20bin%20eine%20Prozessberechtigte%20Person%20(GSKDB)%20f%C3%BCr%20GSelectronic."
    );
    const mailtoData = ref({
      "29394 (T1&V1)": {
        name: "placeholder",
        providerMail: "servicecentergk.nbg-o@telekom.de",
        mailSubject:
          "K%C3%BCndigung%20der%20SIM-Karten%20GSelectronic%20(29394%20T1%26V1)",
        mailCC: "carmen.liebig@gselectronic.com",
      },
      "HE351 (T1)": {
        name: "placeholder",
        providerMail: "servicecentergk.by@telekom.de",
        mailSubject:
          "K%C3%BCndigung%20der%20SIM-Karten%20GSelectronic%20(HE351%20T1)",
        mailCC: "carmen.liebig@gselectronic.com",
      },
      unknown: {
        name: "placeholder",
        providerMail: "tristan.schoenweitz@gselectronic.com", //F%C3%BCr%20diesen%20Rahmenvertrag%20ist%20keine%20E-Mail-Adresse%20hinterlegt
        mailSubject:
          "K%C3%BCndigung%20der%20SIM-Karten%20GSelectronic%20(unbekannter%20Rahmenvertrag)",
        mailCC: "carmen.liebig@gselectronic.com",
      },
    });
    const contractGroupOne = ref("29394 (T1&V1)");
    const contractGroupTwo = ref("HE351 (T1)");
    const importantKeys = ref([
      "Rahmenvertrag",
      "Kundennummer",
      "GP/Firmenname",
      "GP/Straße",
      "GP/Wohnort",
      "GP/PLZ",
      "GP/Land",
      "GP/Adresszusatz",
      "Karten-/Profilnummer",
      "Rufnummer",
      "Bindefristende",
      "Kundenkonto",
      "RE/Firmenname",
      "RE/Straße",
      "RE/Wohnort",
      "RE/PLZ",
      "RE/Land",
      "Rechnungszahlart",
      "Kreditinstitut",
      "IBAN",
      "BIC",
      "Rechnungsmedium",
      "Kostenstelle",
      "Kostenstellennutzer",
    ]);
    const tableHeaders = ref([
      { text: "Rahmenvertrag", value: "Rahmenvertrag" },
      { text: "Kundennummer", value: "Kundennummer" },
      { text: "GPFirmenname", value: "GPFirmenname" },
      { text: "GPStraße", value: "GPStraße" },
      { text: "GPWohnort", value: "GPWohnort" },
      { text: "GPPLZ", value: "GPPLZ" },
      { text: "GPLand", value: "GPLand" },
      { text: "GPAdresszusatz", value: "GPAdresszusatz" },
      { text: "Profilnummer", value: "Profilnummer" },
      { text: "Rufnummer", value: "Rufnummer" },
      { text: "Bindefristende", value: "Bindefristende" },
      { text: "Kundenkonto", value: "Kundenkonto" },
      { text: "REFirmenname", value: "REFirmenname" },
      { text: "REStraße", value: "REFirmenname" },
      { text: "REWohnort", value: "REWohnort" },
      { text: "REPLZ", value: "REPLZ" },
      { text: "RELand", value: "RELand" },
      { text: "Rechnungszahlart", value: "Rechnungszahlart" },
      { text: "Kreditinstitut", value: "Kreditinstitut" },
      { text: "IBAN", value: "IBAN" },
      { text: "BIC", value: "BIC" },
      { text: "Rechnungsmedium", value: "Rechnungsmedium" },
      { text: "Kostenstelle", value: "Kostenstelle" },
      { text: "Kostenstellennutzer", value: "Kostenstellennutzer" },
    ]);
    function decreaseNumber(step) {
      if (step == 0) {
        numberOfUploads.value--;
      } else {
        numberOfLists.value--;
      }
    }

    function submitSearch(number) {
      if (number.startsWith("0")) {
        phonenumberInput.value = "49" + phonenumberInput.value.slice(1);
        number = "49" + number.slice(1);
      }

      const phoneNumberDetails = phonenumberDetails(number);
      phonenumberData.value = phoneNumberDetails;
      phonenumberValid.value = phoneNumberDetails !== undefined;
    }
    function phonenumberDetails(number) {
      return props.uploadedCallnumbers[
        props.uploadedCallnumbers.findIndex((e) => e.Rufnummer == number)
      ];
    }

    function listUploaded(data) {
      numberOfLists.value++;
      uploadedList.value = data.flatMap(Object.values).map(String);
      uploadedList.value.forEach((phoneNumber) => {
        submitSearch(phoneNumber);
        if (phonenumberValid.value) {
          addToCancel();
        }
      });
    }

    function addToCancel() {
      if (
        !cancelList.value.includes(phonenumberData.value.Rufnummer) &&
        phonenumberData.value != undefined
      ) {
        cancelList.value.push(phonenumberData.value.Rufnummer);
      }

      if (phonenumberData.value.Rahmenvertrag.startsWith("29394")) {
        if (contractGroupOne.value in sortedContracts.value) {
          sortedContracts.value[contractGroupOne.value].push(
            phonenumberData.value.Rufnummer
          );
        } else {
          sortedContracts.value[contractGroupOne.value] = [];
          sortedContracts.value[contractGroupOne.value].push(
            phonenumberData.value.Rufnummer
          );
        }
        contracts.value.push(contractGroupOne.value);
        sortedContracts.value[contractGroupOne.value] = [
          ...new Set(sortedContracts.value[contractGroupOne.value]),
        ];
      } else if (phonenumberData.value.Rahmenvertrag.startsWith("HE351")) {
        if (contractGroupTwo.value in sortedContracts.value) {
          sortedContracts.value[contractGroupTwo.value].push(
            phonenumberData.value.Rufnummer
          );
        } else {
          sortedContracts.value[contractGroupTwo.value] = [];
          sortedContracts.value[contractGroupTwo.value].push(
            phonenumberData.value.Rufnummer
          );
        }
        contracts.value.push(contractGroupTwo.value);
        sortedContracts.value[contractGroupTwo.value] = [
          ...new Set(sortedContracts.value[contractGroupTwo.value]),
        ];
      } else {
        if (phonenumberData.value.Rahmenvertrag in sortedContracts.value) {
          sortedContracts.value[phonenumberData.value.Rahmenvertrag].push(
            phonenumberData.value.Rufnummer
          );
        } else {
          sortedContracts.value[phonenumberData.value.Rahmenvertrag] = [];
          sortedContracts.value[phonenumberData.value.Rahmenvertrag].push(
            phonenumberData.value.Rufnummer
          );
        }
        contracts.value.push(phonenumberData.value.Rahmenvertrag);
        sortedContracts.value[phonenumberData.value.Rahmenvertrag] = [
          ...new Set(
            sortedContracts.value[phonenumberData.value.Rahmenvertrag]
          ),
        ];
      }
      emit("markAsCanceled", phonenumberData.value.Rufnummer);
      phonenumberValid.value = false;
      phonenumberData.value = [];
      contracts.value = [...new Set(contracts.value)];
    }

    function removeFromCancelList(number, contract) {
      cancelList.value = cancelList.value.filter((e) => e !== number);
      const index = sortedContracts.value[contract].indexOf(number);
      if (index > -1) {
        sortedContracts.value[contract].splice(index, 1);
      }
      if (sortedContracts.value[contract].length == 0) {
        delete sortedContracts.value[contract];
        const contractIndex = contracts.value.indexOf(contract);
        contracts.value.splice(contractIndex, 1);
      }
      emit("markAsUncanceled", number);
      phonenumberInput.value = "1";
      phonenumberInput.value = "";
      phonenumberValid.value = false;
    }

    function createName(contract) {
      const date = new Date();
      const secs = ("0" + date.getSeconds()).slice(-2);
      const mins = ("0" + date.getMinutes()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + date.getMonth()).slice(-2);
      const year = date.getFullYear();
      return [contract, day, month, year, hours, mins, secs].join("-") + ".xlsx";
    }

    function createXlsx(contract) {
      downloadDialog.value = true;
      sheets.value = [];
      const cancelData = [];
      for (let i = 0; i < cancelList.value.length; i++) {
        const phoneData = phonenumberDetails(cancelList.value[i]);
        if (contract == contractGroupOne.value) {
          if (phoneData.Rahmenvertrag.startsWith("29394")) {
            cancelData.push(phoneData);
          }
        } else if (contract == contractGroupTwo.value) {
          if (phoneData.Rahmenvertrag.startsWith("HE351")) {
            cancelData.push(phoneData);
          }
        } else {
          if (phoneData.Rahmenvertrag == contract) {
            cancelData.push(phoneData);
          }
        }
      }
      sendData(cancelData.map((obj) => obj.Rufnummer));
      sheets.value.push({ name: contract, data: [...cancelData] });
      if (contract == contractGroupOne.value) {
        window.location =
          "mailto:" +
          mailtoData.value[contract].providerMail +
          "?cc=" +
          mailtoData.value[contract].mailCC +
          "&subject=" +
          mailtoData.value[contract].mailSubject +
          "&body=" +
          mailToBody.value;
        contractCancelList = filteredNumbers();
      } else if (contract == contractGroupTwo.value) {
        window.location =
          "mailto:" +
          mailtoData.value[contract].providerMail +
          "?cc=" +
          mailtoData.value[contract].mailCC +
          "&subject=" +
          mailtoData.value[contract].mailSubject +
          "&body=" +
          mailToBody.value;
      } else {
        window.location =
          "mailto:" +
          mailtoData.value["unknown"].providerMail +
          "?cc=" +
          mailtoData.value["unknown"].mailCC +
          "&subject=" +
          mailtoData.value["unknown"].mailSubject +
          "&body=" +
          mailToBody.value;
      }
    }
    function sendData(data) {
      api.callnumbersCancel(data);
    }

    return {
      downloadDialog,
      mailtoData,
      contractPreview,
      stepCount,
      sheets,
      contracts,
      sortedContracts,
      numberOfUploads,
      numberOfLists,
      uploadedContractDetails,
      uploadedContracts,
      cancelList,
      phonenumberData,
      phonenumberValid,
      phonenumberInput,
      contractGroupOne,
      contractGroupTwo,
      mailToBody,
      tableHeaders,
      importantKeys,
      labelList,
      labelContracts,
      iconList,
      iconContract,
      iconRemove,
      addToCancel,
      phonenumberDetails,
      sendData,
      decreaseNumber,
      removeFromCancelList,
      submitSearch,
      createXlsx,
      createName,
      listUploaded,
    };
  },
};
</script>
