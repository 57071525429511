import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "@babel/polyfill";
import "./plugins";
import "./componentsAutoimport";
import "./assets/scss/_style.scss";
import "./assets/fonts/fonts.css";
import VueBlobJsonCsv from "vue-blob-json-csv";
import VueCompositionApi from "@vue/composition-api";

Vue.use(VueBlobJsonCsv);
Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueCompositionApi);

import AuthService from "./auth/AuthService";
const auth = new AuthService();
const { loginOAuth } = auth;

loginOAuth((success) => {
  if (success) {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");    
  }
  else {
    //Fallback
    loginOAuth(() => {
        new Vue({
          router,
          store,
          vuetify,
          render: (h) => h(App),
        }).$mount("#app");    
    }, "https://ham.oidc.gselectronic.com/auth/");
  }
});
