var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-data-table", {
        attrs: {
          loading: _vm.dataLoading,
          "sort-by": _vm.sortBy,
          items: _vm.filteredMessages,
          search: _vm.search,
          headers: _vm.messageHeaders,
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortBy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "", dense: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: "Suche",
                        clearable: "",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c("v-spacer"),
                    _c(
                      "v-menu",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "ml-4" },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(_vm._s(_vm.menuLabel)),
                                    _c(
                                      "v-icon",
                                      { attrs: { small: "", right: "" } },
                                      [_vm._v("fas fa-filter")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          _vm._l(
                            _vm.messageTypes,
                            function (messageType, index) {
                              return _c(
                                "v-list-item",
                                { key: index, attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.filterMessages(
                                            _vm.messageTypes[index]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.messageTypes[index]) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }