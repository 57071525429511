var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-2" },
    [
      _c("v-col", { attrs: { cols: "2" } }),
      _c("v-col", { attrs: { cols: "8" } }, [
        _c(
          "h2",
          { attrs: { align: "center", justify: "center" } },
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "mx-1 mb-1",
                                on: {
                                  click: function ($event) {
                                    _vm.directoryDialog = true
                                  },
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { color: _vm.color } }, [
                              _vm._v(_vm._s(_vm.icon)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_c("span", [_vm._v("Ordner bearbeiten")])]
            ),
            _vm._v(
              " Zugeordnete Produkte/" +
                _vm._s(_vm.sortedList[_vm.directoryIndex].name) +
                " "
            ),
          ],
          1
        ),
      ]),
      _c("v-col", { attrs: { cols: "2" } }),
      _c(
        "v-row",
        _vm._l(_vm.sortedList[_vm.directoryIndex].products, function (val, i) {
          return _c(
            "v-col",
            { key: i, attrs: { cols: "3" } },
            [
              _c("ProductCard", {
                class: "",
                attrs: {
                  editMode: false,
                  itemDrag: false,
                  moveMode: false,
                  showMenu: false,
                  isFolder: true,
                  recentDirectory: 0,
                  sortedList: _vm.sortedList[_vm.directoryIndex].products[i],
                },
                on: {
                  handleSelectedProduct: _vm.handleSelectedProduct,
                  removeFromDirectory: _vm.removeFromDirectory,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.directoryDialog,
            callback: function ($$v) {
              _vm.directoryDialog = $$v
            },
            expression: "directoryDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", [_vm._v(" Ordner bearbeiten ")]),
              _c(
                "v-card-actions",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Ordnername",
                          placeholder: "Neuer Ordner",
                          required: "",
                          rules: _vm.nameRule,
                        },
                        model: {
                          value: _vm.dirNameInput,
                          callback: function ($$v) {
                            _vm.dirNameInput = $$v
                          },
                          expression: "dirNameInput",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          label: "Icon",
                          items: _vm.allIcons,
                          "item-text": "name",
                          "item-value": "icon",
                          "return-object": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(" " + _vm._s(data.item.icon) + " "),
                                ]),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: {
                                      color: _vm.selectedIconColor.color,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(data.item.icon) + " ")]
                                ),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedIcon,
                          callback: function ($$v) {
                            _vm.selectedIcon = $$v
                          },
                          expression: "selectedIcon",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          label: "Farbe",
                          items: _vm.allColors,
                          "item-text": "name",
                          "item-value": "color",
                          "return-object": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c("v-avatar", {
                                  staticClass: "mr-2",
                                  attrs: { color: data.item.color, size: "20" },
                                }),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c("v-avatar", {
                                  staticClass: "mr-2",
                                  attrs: { color: data.item.color, size: "20" },
                                }),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedIconColor,
                          callback: function ($$v) {
                            _vm.selectedIconColor = $$v
                          },
                          expression: "selectedIconColor",
                        },
                      }),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4",
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              _vm.directoryDialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4",
                          attrs: {
                            type: "submit",
                            disabled: !_vm.valid,
                            color: "success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateDirectory(
                                _vm.sortedList[_vm.directoryIndex].id,
                                _vm.dirNameInput,
                                _vm.selectedIcon,
                                _vm.selectedIconColor
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(" fas fa-check ")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }