<template>
  <v-row dense>
    <v-col class="py-0">
      <v-card elevation="0">
        <v-card-text class="pt-0">
          <slot name="prepend"></slot>
          <v-row dense justify="center" align="center">
            <v-col v-for="i in numberField" :key="i" cols="4">
              <v-card outlined tile height="100">
                <v-btn
                  style="font-size: 2em"
                  block
                  height="100%"
                  @click="setNumber(i)"
                >
                  {{ i }}
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense justify="center" align="center">
            <v-col cols="4"><!-- empty --> </v-col>
            <v-col cols="4">
              <v-card outlined tile height="100">
                <v-btn
                  style="font-size: 2em"
                  block
                  height="100%"
                  @click="setNumber(0)"
                >
                  0
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card outlined tile height="100">
                <v-btn
                  color="error"
                  style="font-size: 2em"
                  block
                  height="100%"
                  @click="reset"
                >
                  = 0
                  <v-icon right>mdi-alert</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <slot name="append"></slot>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import MouseTrap from "mousetrap";
export default {
  props: {
    currentStep: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const numberField = ["7", "8", "9", "4", "5", "6", "1", "2", "3"];
    const allNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    function setNumber(i) {
      emit("setNumber", i);
    }
    function reset() {
      emit("reset");
    }
    watch(
      () => props.currentStep,
      first => {
        if (first == 4) {
          for (var i = 0; i < allNumbers.length; i++) {
            var o = allNumbers[i].toString();
            MouseTrap.bind(o, function(o) {
              setNumber(o.key);
            });
          }
        } else if (first == 1 || first == 3) {
          for (var i = 0; i < allNumbers.length; i++) {
            var o = allNumbers[i].toString();
            MouseTrap.unbind(o);
          }
        }
      }
    );
    return { numberField, setNumber, reset };
  }
};
</script>

<style>
.theme--light.v-input--is-disabled input {
  color: #444;
}
</style>
