var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.receivedData,
          "server-items-length": _vm.totalItemCount,
          options: _vm.pagination,
          "footer-props": _vm.footerProps,
          loading: _vm.loading,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "v-alert",
                  {
                    staticClass: "ma-2",
                    staticStyle: { color: "#fff" },
                    attrs: {
                      value: true,
                      color: "primary",
                      icon: _vm.noDataIcon,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.hasFilters
                            ? "Keine Daten verfügbar, bitte überprüfen Sie den Filter"
                            : "Keine Daten vorhanden"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "items",
            fn: function (props) {
              return _vm._l(_vm.tableHeaders, function (header, index) {
                return _c("td", { key: index, staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(props.item[header.value]) + " "),
                ])
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }