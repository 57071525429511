var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.nameRules,
                      counter: 50,
                      "validate-on-blur": "",
                      label: "Mitarbeiter",
                      required: "",
                    },
                    model: {
                      value: _vm.user.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "name", $$v)
                      },
                      expression: "user.name",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.usernameRules,
                      counter: 3,
                      "validate-on-blur": "",
                      label: "Benutzername",
                      required: "",
                    },
                    model: {
                      value: _vm.user.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "username", $$v)
                      },
                      expression: "user.username",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      autocomplete: "current-password",
                      label: "Pin festlegen",
                      required: "",
                      rules: _vm.passwordRules,
                      "append-icon": _vm.obfuscatePassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.obfuscatePassword ? "password" : "text",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.obfuscatePassword = !_vm.obfuscatePassword
                      },
                    },
                    model: {
                      value: _vm.user.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { tile: "", color: "primary", dark: "" },
                              on: { click: _vm.deselectUser },
                            },
                            [_vm._v(" Zurück ")]
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.showDeleteButton
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { tile: "", color: "red", dark: "" },
                                  on: { click: _vm.deleteUser },
                                },
                                [_vm._v(" Mitarbeiter löschen ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { tile: "", color: "primary", dark: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.submit()
                                },
                              },
                            },
                            [_vm._v(" Speichern ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }