var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._l(_vm.users, function (user) {
      return _c(
        "v-col",
        { key: user.id, attrs: { sm: "3", lg: "2" } },
        [
          _c(
            "v-card",
            {
              attrs: { outlined: "", tile: "", height: "150" },
              on: {
                click: function ($event) {
                  return _vm.selectUser(user)
                },
              },
            },
            [
              _c(
                "v-container",
                { staticClass: "pa-0", attrs: { "fill-height": "" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "ma-0 text-h4",
                      attrs: { justify: "center", align: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticStyle: { "font-weight": "bold" },
                          attrs: { cols: "auto" },
                        },
                        [_vm._v(" " + _vm._s(user.username) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }