<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="auto">
              <v-menu
                v-model="showDatePicker"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y
                min-width="150px"
              >
                <template v-slot:activator="{ on }">
                  <v-btn prepend-icon="event" v-on="on">
                    {{ computedDateFormatted }}
                    <v-icon right small>fas fa-calendar-week</v-icon>
                  </v-btn>
                </template>
                <!-- the watch on "date" closes the datePicker when a date was selected -->
                <v-date-picker
                  v-model="date"
                  :first-day-of-week="1"
                  :max="today"
                  no-title
                  locale="de-de"
                  color="primary"
                  type="month"
                />
                <!-- :max="today" -->
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card>
                <v-card-title>Wöchentliche Verfügbarkeit</v-card-title>
                <v-card-text>
                  <p>Primär 99,3%</p>
                  <p>Gesamt 97,3%%</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>Jährliche Verfügbarkeit</v-card-title>
                <v-card-text>
                  <p>Primär 99,3%</p>
                  <p>Gesamt 97,3%</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";

export default {
  setup(props) {
    const showDatePicker = ref(false);
    const date = ref(null);
    const today = ref(null);
    const month = "";
    const year = "";
    const messages = [
      {
        month: "01",
        year: "2019"
      },
      {
        month: "12",
        year: "2018"
      },
      {
        month: "11",
        year: "2018"
      }
    ];

    const computedDateFormatted = computed(() => {
      return formatDate(date.value);
    });

    watch(
      () => date.value,
      newValue => {
        showDatePicker.value = false;
      }
    );

    today.value = getToday();
    date.value = getToday();

    function getToday() {
      return new Date().toISOString().substr(0, 10);
    }

    function formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}.${year}`;
    }

    return {
      showDatePicker,
      date,
      today,
      month,
      year,
      messages,
      computedDateFormatted,
      getToday,
      formatDate
    };
  }
};
</script>

<style></style>
