var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { attrs: { grow: "", "icons-and-text": "" } },
    [
      _c(
        "v-tab",
        [
          _vm._v(" Gerätedaten "),
          _c("v-icon", [_vm._v(" perm_device_information ")]),
        ],
        1
      ),
      _c(
        "v-tab",
        [_vm._v(" Meldungen "), _c("v-icon", [_vm._v(" ring_volume ")])],
        1
      ),
      _c(
        "v-tab",
        [_vm._v(" Verfügbarkeit "), _c("v-icon", [_vm._v(" show_chart ")])],
        1
      ),
      _c(
        "v-tab-item",
        [_c("DeviceDetails", { attrs: { device: _vm.device } })],
        1
      ),
      _c(
        "v-tab-item",
        [_c("DeviceMessages", { attrs: { device: _vm.device } })],
        1
      ),
      _c(
        "v-tab-item",
        [_c("DeviceAvailability", { attrs: { device: _vm.device } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }