<template>
  <v-row dense>
    <v-col>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-textarea id="token" v-model="token" readonly auto-grow />
                  <v-btn class="ma-3" color="primary" @click="updateToken">
                    Token einfügen
                  </v-btn>
                  <v-btn
                    class="ma-3"
                    :color="buttonColor"
                    @click="copyTokenToClipboard"
                  >
                    Token kopieren
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Original</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-textarea v-model="decodedBase64" auto-grow />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-row>
            <v-col>
              <v-btn class="ma-3" @click="encodeBase64()">
                Encode =>
              </v-btn>
            </v-col>
          </v-row>          
          <v-row>
            <v-col>
              <v-btn class="ma-3" @click="decodeBase64()">
                <= Decode 
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Base64 Encoded</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-textarea v-model="encodedBase64" auto-grow />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Axios-Runner</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field v-model="url" label="URL:"></v-text-field>
                  <v-textarea v-model="testResult" readonly auto-grow />
                  <v-btn class="ma-3" :color="buttonColor" @click="runTest">
                    Execute
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default {
  data() {
    return {
      keyStr : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
      encodedBase64: "",
      decodedBase64: "",
      url: "",
      token: "",
      testResult: "",
      buttonColor: "primary",
      series: [
        {
          name: "Gold Medals",
          data: [40, 32, 34, 36, 45, 33, 34, 83, 36, 37, 44, 37, 35, 36, 46],
          color: "#f3ac32"
        },
        {
          name: "Silver Medals",
          data: [19, 25, 21, 26, 28, 31, 35, 60, 31, 34, 32, 24, 40, 38, 29],
          color: "#b8b8b8"
        },
        {
          name: "Bronze Medals",
          data: [17, 17, 16, 28, 34, 30, 25, 30, 27, 37, 25, 33, 26, 36, 29],
          color: "#bb6e36"
        }
      ],
      valueAxis: [
        {
          max: 180,
          line: {
            visible: false
          },
          minorGridLines: {
            visible: true
          }
        }
      ],
      categoryAxis: {
        categories: [
          1952,
          1956,
          1960,
          1964,
          1968,
          1972,
          1976,
          1984,
          1988,
          1992,
          1996,
          2000,
          2004,
          2008,
          2012
        ],
        majorGridLines: {
          visible: false
        }
      },
      tooltip: {
        visible: true,
        template: "#= series.name #: #= value #"
      }
    };
  },
  methods: {
    updateToken() {
      this.token = this.$keycloak.token;
      // let testingCodeToCopy = document.querySelector("#token");
      // testingCodeToCopy.select();
      // var successful = document.execCommand("copy");
      // this.buttonColor = successful ? "success" : "error";
      // setTimeout(() => {
      //   this.buttonColor = "primary";
      // }, 1000);

      /* unselect the range */
      // testingCodeToCopy.setAttribute("type", "hidden");
    },
    copyTokenToClipboard() {
      let testingCodeToCopy = document.querySelector("#token");
      testingCodeToCopy.select();
      let successful = document.execCommand("copy");
      this.buttonColor = successful ? "success" : "error";
      setTimeout(() => {
        this.buttonColor = "primary";
      }, 1000);
    },
    connectToPPSNotifier() {
      const connection = new HubConnectionBuilder()
        .withUrl("http://192.168.130.36:49994/notifications")
        .configureLogging(LogLevel.Information)
        .build();

      connection.start();
    },
    runTest() {
      axios({
        method: "get",
        url: this.url //"https://gsetime1.gselectronic.com:8443/atc/webservices?wsdl"
        // data: data_to_send
      })
        .then(response => {
          this.testResult = response;
          // console.log(response);
        })
        .catch(error => {
          this.testResult = error;
          // console.log(error);
        });
    },
    encodeBase64() {
      this.encodedBase64 = this.encode(this.decodedBase64);
    },
    decodeBase64() {      
      this.decodedBase64 = this.decode(this.encodedBase64);
    },
    encode(input) {
        let output = "";
        let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        let i = 0;

        input = this._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
            this.keyStr.charAt(enc1) + this.keyStr.charAt(enc2) +
            this.keyStr.charAt(enc3) + this.keyStr.charAt(enc4);
        }

        return output;
    },
    decode (input) {
        let output = "";
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = this.keyStr.indexOf(input.charAt(i++));
            enc2 = this.keyStr.indexOf(input.charAt(i++));
            enc3 = this.keyStr.indexOf(input.charAt(i++));
            enc4 = this.keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }

        output = this._utf8_decode(output);

        return output;
    },

    // private method for UTF-8 encoding
    _utf8_encode (string) {
        string = string.replace(/\r\n/g,"\n");
        let utftext = "";

        for (let n = 0; n < string.length; n++) {

            let c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    },

    // private method for UTF-8 decoding
    _utf8_decode (utftext) {
        let string = "";
        let i = 0;
        let c = 0, c1 = 0, c2 = 0, c3 = 0;

        while ( i < utftext.length ) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i+1);
                c3 = utftext.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    }

  }
};
</script>
