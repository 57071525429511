var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c("v-text-field", {
                attrs: {
                  "prepend-inner-icon": "search",
                  label: "Suche",
                  "single-line": "",
                  "hide-details": "",
                  clearable: "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSubmitSearch.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-0",
                  attrs: { icon: "", large: "" },
                  on: {
                    click: function ($event) {
                      return _vm.openLink()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("$vuetify.icons.external"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.filters, function (filter, index) {
        return _c(
          "v-chip",
          {
            key: index,
            attrs: { close: "" },
            on: {
              "click:close": function ($event) {
                return _vm.removeFilter(index)
              },
            },
          },
          [_vm._v(" " + _vm._s(filter) + " ")]
        )
      }),
      _c("div", { staticClass: "mb-4" }),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        grow: "",
                        "icons-and-text": "",
                        "slider-color": "primary",
                      },
                      model: {
                        value: _vm.active,
                        callback: function ($$v) {
                          _vm.active = $$v
                        },
                        expression: "active",
                      },
                    },
                    [
                      _vm._l(
                        _vm.tableDefinitions,
                        function (definition, index) {
                          return _c(
                            "v-tab",
                            { key: index },
                            [
                              _vm._v(" " + _vm._s(definition.label) + " "),
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(definition.icon) + " "),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._l(
                        _vm.tableDefinitions,
                        function (definition, index) {
                          return _c(
                            "v-tab-item",
                            { key: index },
                            [
                              _c("DataTable", {
                                attrs: {
                                  service: _vm.service,
                                  "column-definitions":
                                    definition.columnDefinitions,
                                  "api-suffix": definition.apiSuffix,
                                  filters: _vm.filters,
                                  search: _vm.searchDebounced,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }