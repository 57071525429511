<template>
  <div>
    <v-file-input
      transition="scroll-x-transition"
      truncate-length="50"
      :prepend-icon="icon"
      outlined
      :label="label"
      accept=".xlsx, .xls, .csv"
      @change="fileUploaded"
      @click:clear="clickClear"
    ></v-file-input>
    <xlsx-read :file="file">
      <xlsx-json @parsed="parsed"> </xlsx-json>
    </xlsx-read>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { XlsxRead, XlsxJson } from "vue-xlsx";

export default {
  components: { XlsxRead, XlsxJson },
  props: {
    icon: {
      icon: {
        type: String,
        required: true,
      },
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const file = ref(null);

    function fileUploaded(e) {
      file.value = e;
    }
    function clickClear() {
      emit("clickClear");
    }

    function parsed(data) {
      emit("parsed", data);
    }
    return {
      clickClear,
      file,
      parsed,
      fileUploaded,
    };
  },
};
</script>
