<template>
  <drop
    @drop="handleDrop(sortedList)"
    @dragover="handleDragOver()"
    @dragleave="handleDragLeave()"
  >
    <v-card
      min-height="140"
      min-width="40"
      :outlined="outline"
      @click="handleDirectorySelection(sortedList)"
      @keydown.enter="handleDirectorySelection(sortedList)"
    >
      <v-card-title style="overflow-y: auto" class="font-md pb-7">
        <div>
          <v-badge
            :value="sortedList.products.length"
            :content="sortedList.products.length"
            :color="badgeColor"
            offset-x="6"
            offset-y="3"
          >
            <v-icon size="24" class="mr-1 mb-1" :color="sortedList.color.color">
              {{ sortedList.icon.icon }}
            </v-icon>
          </v-badge>
          <v-icon
            v-if="sortedList.isFavourite"
            color="#FFD700"
            class="ml-1 pb-1"
          >
            fan fa-star
          </v-icon>
          {{ sortedList.name }}
        </div>
      </v-card-title>
      <v-card-text />
      <v-card-actions class="pt-3 mx-1">
        <v-fade-transition>
          <v-btn
            v-if="showMenu"
            :disabled="moveMode || editMode"
            small
            color="error"
            @click.stop="deleteDirectory(sortedList)"
          >
            <v-icon small>fan fa-trash</v-icon>
          </v-btn>
        </v-fade-transition>
      </v-card-actions>
    </v-card>
  </drop>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
export default {
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    moveMode: {
      type: Boolean,
      required: true
    },
    showMenu: {
      type: Boolean,
      required: true
    },
    recentDirectory: {
      type: Number,
      required: true
    },
    sortedList: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const redBadge = "#AD1F06";
    const outline = ref(false);
    const badgeColor = computed(() => {
      if (props.recentDirectory === props.sortedList.id) {
        return redBadge;
      } else {
        return props.sortedList.color.color;
      }
    });

    function handleDrop(val) {
      outline.value = false;
      if (props.editMode === false) {
        emit("handleDrop", val);
      }
    }
    function handleDragOver() {
      outline.value = true;
    }
    function handleDragLeave() {
      outline.value = false;
    }
    function handleDirectorySelection(val) {
      if (!props.moveMode && !props.editMode && !props.showMenu) {
        emit("handleDirectorySelection", val.id);
      }
    }
    function deleteDirectory(val) {
      emit("deleteDirectory", val.id);
    }
    return {
      badgeColor,
      redBadge,
      outline,
      handleDrop,
      handleDragOver,
      handleDragLeave,
      handleDirectorySelection,
      deleteDirectory
    };
  }
};
</script>

<style></style>
