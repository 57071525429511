<template>
  <v-card>
    <v-card-title> Aktuelle PPS-Benutzer </v-card-title>
    <v-card-text v-if="hasConnectedUsers">
      <v-list-item v-for="(user, index) in connectedUsers" :key="index">
        <v-list-item-icon>
          <v-icon v-if="user.writeAccess" color="primary">
            $vuetify.icons.ppsWriter
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ user.username }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-card-text v-else>
      Zurzeit ist kein Mitarbeiter in PPS eingeloggt.
    </v-card-text>
  </v-card>
</template>

<script>
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  computed
} from "@vue/composition-api";

export default {
  setup() {
    const connection = ref(null);
    const connectedUsers = ref([]);
    const currentWriter = ref("");
    const hasConnectedUsers = computed(() => connectedUsers.value.length > 0);

    watch(
      currentWriter,
      // callback
      val => {
        connectedUsers.value.forEach(user => {
          user.writeAccess = user.username == val;
        });
      }
    );

    function sortConnectedUsers() {
      connectedUsers.value.sort(function(a, b) {
        return ("" + a.username).localeCompare(b.username);
      });
    }

    onMounted(() => {
      connection.value = new HubConnectionBuilder()
        .withUrl(
          "https://ppsnotifier.ingress.bosg76nwzr.gsqmsnawqh.managed-kubernetes.com/notifications"
        )
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      connection.value.on("OnConnectedUsers", (users, writer) => {
        connectedUsers.value = [];
        users.forEach(user => {
          connectedUsers.value.push({
            username: user,
            writeAccess: user == writer
          });
        });
        currentWriter.value = writer;
        sortConnectedUsers();
      });

      connection.value.on("LoggedIn", user => {
        connectedUsers.value.push({
          username: user,
          writeAccess: currentWriter.value == user
        });
        sortConnectedUsers();
      });

      connection.value.on("LoggedOut", user => {
        const index = connectedUsers.value.findIndex(cu => cu.username == user);
        if (index > -1) {
          connectedUsers.value.splice(index, 1);
        }
      });
      connection.value.on("CurrentWriterChanged", result => {
        currentWriter.value = result;
      });

      connection.value.start().then(() => {
        connection.value.invoke("GetConnectedUsers");
        //.catch(e => console.log(e));
        //.catch(err => log.error(err.toString()));
      });
    });

    onUnmounted(() => {
      connection.value.stop();
    });

    return {
      connection,
      connectedUsers,
      currentWriter,
      hasConnectedUsers
    };
  }
};
</script>
