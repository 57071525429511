var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.showSettingsDialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Einstellungen")]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", dark: "", text: "" },
                      on: { click: _vm.openAccountSettings },
                    },
                    [_vm._v(" Accountverwaltung ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mx-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: _vm.cardElevation } },
                    [
                      _c("v-card-title", [_vm._v("Global")]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-slider", {
                            attrs: {
                              label: "Schattierung",
                              min: "0",
                              max: "24",
                              "tick-size": "1",
                              "thumb-size": "128",
                            },
                            model: {
                              value: _vm.cardElevation,
                              callback: function ($$v) {
                                _vm.cardElevation = $$v
                              },
                              expression: "cardElevation",
                            },
                          }),
                          _c("v-slider", {
                            attrs: {
                              label: "Seitenrand",
                              min: "12",
                              max: "450",
                              "tick-size": "5",
                              "thumb-size": "128",
                            },
                            model: {
                              value: _vm.globalPadding,
                              callback: function ($$v) {
                                _vm.globalPadding = $$v
                              },
                              expression: "globalPadding",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }