<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="11">
        <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Suche"
          single-line
          hide-details
          clearable
          @keyup.enter="onSubmitSearch"
        />
      </v-col>
      <v-col>
        <v-btn icon large class="my-0" @click="openLink()">
          <v-icon small>$vuetify.icons.external</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-chip
      v-for="(filter, index) in filters"
      :key="index"
      close
      @click:close="removeFilter(index)"
    >
      {{ filter }}
    </v-chip>
    <div class="mb-4" />
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-tabs v-model="active" grow icons-and-text slider-color="primary">
            <v-tab v-for="(definition, index) of tableDefinitions" :key="index">
              {{ definition.label }}
              <v-icon>
                {{ definition.icon }}
              </v-icon>
            </v-tab>
            <v-tab-item
              v-for="(definition, index) of tableDefinitions"
              :key="index"
            >
              <DataTable
                :service="service"
                :column-definitions="definition.columnDefinitions"
                :api-suffix="definition.apiSuffix"
                :filters="filters"
                :search="searchDebounced"
              />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import { ref, watch } from "@vue/composition-api";
import _ from "lodash";

export default {
  components: {
    DataTable
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const search = ref("");
    const searchDebounced = ref("");
    const active = ref(null);
    const filters = ref([]);
    const services = ref([]);
    const selectedService = ref([]);
    /* Convention between frontend and backend:
       equal labels must have equal props, otherwise the current filter-implmenetation will not
       be able to distinguish between columndefinitions of different tables with the same label
       e.g.: label of:
        /el -> Callnumber = "Callnummer"
           must match
        /pd -> Callnumber = "Callnummer"
       */
    const tableDefinitions = ref([
      {
        label: "Ereignisse",
        icon: "flash_on",
        apiSuffix: "/el",
        columnDefinitions: [
          { prop: "Callnumber", label: "Telefon-/Callnummer" },
          { prop: "ReceivedData", label: "Empfangene Daten" },
          { prop: "ReceivedAtGSMTPTimestamp", label: "Zeitpunkt" }
        ]
      },
      // {
      //   label: "Alarmdaten",
      //   icon: "alarm",
      //   apiSuffix: "/ad",
      //   columnDefinitions: [
      //     { prop: "ProjectNumber", label: "Projektnummer" },
      //     { prop: "Branch", label: "Nebenstelle" },
      //     { prop: "Description", label: "Beschreibung" },
      //     { prop: "Meldebyte", label: "Meldebyte" },
      //     { prop: "Statusbyte", label: "Statusbyte" },
      //     { prop: "Textnumber", label: "Textnummer" }
      //   ]
      // },
      // {
      //   label: "Telefondaten",
      //   icon: "phone",
      //   apiSuffix: "/pd",
      //   columnDefinitions: [
      //     { prop: "Callnumber", label: "Callnummer" },
      //     { prop: "Phone", label: "Telefonnummer" },
      //     { prop: "ProjectNumber", label: "Projektnummer" },
      //     { prop: "AdditionalInfo", label: "Beschreibung" }
      //   ]
      // }
    ]);
    watch(
      () => search.value,
      newValue => {
        debounceSearch();
      }
    );

    function debounceSearch() {
      _.debounce(function() {
        searchDebounced.value = search.value;
      }, 500);
    }
    function openLink() {
      window.open(
        `${selectedService.url}:${selectedService.port}/${selectedService.api}/stats`,
        "_blank"
      );
    }
    function onSubmitSearch() {
      filters.value.push(search.value);
      search.value = "";
    }
    function removeFilter(index) {
      filters.value.splice(index, 1);
      filters.value = [...filters.value];
    }
    return {
      search,
      searchDebounced,
      active,
      filters,
      services,
      tableDefinitions,
      debounceSearch,
      openLink,
      removeFilter,
      onSubmitSearch
    };
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.clickable {
  cursor: pointer;
}
</style>
