var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      attrs: { "icons-and-text": "" },
      model: {
        value: _vm.tab,
        callback: function ($$v) {
          _vm.tab = $$v
        },
        expression: "tab",
      },
    },
    [
      _c(
        "v-tab",
        [
          _vm._v(" Dashboard "),
          _c("v-icon", [_vm._v(" $vuetify.icons.calendarDay ")]),
        ],
        1
      ),
      _c(
        "v-tab",
        [
          _vm._v(" Geräteliste "),
          _c("v-icon", [_vm._v(" $vuetify.icons.calendarMonth ")]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab-item", [_c("ControlDashboard")], 1),
          _c("v-tab-item", [_c("ControlDevices")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }