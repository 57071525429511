var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { staticClass: "py-0" },
        [
          _c(
            "v-card",
            { attrs: { elevation: "0" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-0" },
                [
                  _vm._t("prepend"),
                  _c(
                    "v-row",
                    {
                      attrs: { dense: "", justify: "center", align: "center" },
                    },
                    _vm._l(_vm.numberField, function (i) {
                      return _c(
                        "v-col",
                        { key: i, attrs: { cols: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: { outlined: "", tile: "", height: "100" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "2em" },
                                  attrs: { block: "", height: "100%" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setNumber(i)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(i) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      attrs: { dense: "", justify: "center", align: "center" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "4" } }),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: { outlined: "", tile: "", height: "100" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "2em" },
                                  attrs: { block: "", height: "100%" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setNumber(0)
                                    },
                                  },
                                },
                                [_vm._v(" 0 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: { outlined: "", tile: "", height: "100" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "2em" },
                                  attrs: {
                                    color: "error",
                                    block: "",
                                    height: "100%",
                                  },
                                  on: { click: _vm.reset },
                                },
                                [
                                  _vm._v(" = 0 "),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-alert"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._t("append"),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }