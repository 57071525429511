import Vue from "vue";
import axios from "axios";

const api = axios.create();

api.interceptors.request.use(
    request => {
      const keycloak = Vue.prototype.$keycloak;
      if (keycloak) {
        const token = keycloak.token;
        if (token) {
          request.headers.Authorization = `Bearer ${token}`;
        }
      }
      return request;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

export default {
  getServices: function(baseUrl, servicesFilter) {
    return api.post(
      baseUrl + "/services",
      servicesFilter,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
  },
};
