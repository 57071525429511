<template>
  <v-col justify="center" align="center">
    <v-row justify="center" align="center">
      <v-col cols="4" justify="center" align="center">
        <v-icon class="mx-1">{{ iconOne }}</v-icon>
        <v-icon class="mx-1">{{ iconTwo }}</v-icon>
        <v-icon class="mx-1">{{ iconThree }}</v-icon>
        <v-icon class="mx-1">{{ iconFour }}</v-icon>
      </v-col>
    </v-row>
    <v-row justify="end" align="center">
      <v-col v-for="i in numberField" :key="i" cols="4">
        <v-card height="10vh" outlined tile>
          <v-btn
            style="font-size: 2em"
            block
            height="100%"
            @click="emitInput(i)"
          >
            {{ i }}
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card height="10vh" outlined tile>
          <v-btn
            style="font-size: 2em"
            block
            height="100%"
            @click="deleteLastChar"
          >
            <v-icon x-large>fas fa-backspace</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { type } from "@amcharts/amcharts4/core";
import MouseTrap from "mousetrap";
export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const allNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const numberField = [7, 8, 9, 4, 5, 6, 1, 2, 3, 0];
    const counter = ref(0);
    onMounted(() => {
      for (let i = 0; i < allNumbers.length; i++) {
        let o = allNumbers[i].toString();
        MouseTrap.bind(o, function (o) {
          emitInput(o.key);
        });
      }
    });
    onUnmounted(() => {
      counter.value = 4;
      clear();
      for (let i = 0; i < allNumbers.length; i++) {
        let o = allNumbers[i].toString();
        MouseTrap.unbind(o);
      }
    });
    function emitInput(input) {
      counter.value++;
      emit("input", input);
      pinDisplay();

      if (counter.value == 4) {
        clear();
      }
    }
    function deleteLastChar() {
      counter.value--;
      pinDisplay();
      emit("backspace");
    }
    function clear() {
      counter.value = 0;
      setTimeout(() => pinDisplay(), 400);
    }
    const iconOne = ref("far fa-circle");
    const iconTwo = ref("far fa-circle");
    const iconThree = ref("far fa-circle");
    const iconFour = ref("far fa-circle");
    function pinDisplay() {
      if (counter.value >= 1) {
        iconOne.value = "fas fa-circle";
      } else {
        iconOne.value = "far fa-circle";
      }
      if (counter.value >= 2) {
        iconTwo.value = "fas fa-circle";
      } else {
        iconTwo.value = "far fa-circle";
      }
      if (counter.value >= 3) {
        iconThree.value = "fas fa-circle";
      } else {
        iconThree.value = "far fa-circle";
      }
      if (counter.value >= 4) {
        iconFour.value = "fas fa-circle";
      } else {
        iconFour.value = "far fa-circle";
      }
    }

    return {
      iconOne,
      iconTwo,
      iconThree,
      iconFour,
      emitInput,
      deleteLastChar,
      numberField,
    };
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
