var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _vm.selectedUser[0].allProducts == true
        ? _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("CompleteProductList", {
                attrs: {
                  selectedUser: _vm.selectedUser,
                  products: _vm.products,
                },
                on: { handleSelectedProduct: _vm.handleSelectedProduct },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedUser[0].userProductList.length > 0 &&
      _vm.selectedUser[0].allProducts == true
        ? _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [_c("v-divider", { attrs: { vertical: "" } })],
            1
          )
        : _vm._e(),
      _vm.selectedUser[0].userProductList.length > 0
        ? _c(
            "v-col",
            [
              _c("ProductFavouriteCards", {
                attrs: {
                  currentStep: _vm.currentStep,
                  selectedUser: _vm.selectedUser,
                  directoryId: _vm.directoryId,
                  directoryOpened: _vm.directoryOpened,
                },
                on: {
                  handleListChange: _vm.handleListChange,
                  handleSelectedProduct: _vm.handleSelectedProduct,
                  addDirectory: _vm.addDirectory,
                  handleMoveToDirectory: _vm.handleMoveToDirectory,
                  handleRemoveFromDirectory: _vm.handleRemoveFromDirectory,
                  handleDirectoryOpened: _vm.handleDirectoryOpened,
                  deleteDirectory: _vm.deleteDirectory,
                  updateDirectory: _vm.updateDirectory,
                },
              }),
            ],
            1
          )
        : _c("v-col", { attrs: { align: "center", justify: "center" } }, [
            _c("span", [
              _vm._v("Diesem Mitarbeiter sind keine Produkte zugeordnet"),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }