<template>
  <v-card outlined tile>
    <v-card-title class="fill-height">
      <v-row align="center" style="text-align: center">
        <!-- <v-col cols="12">
            <v-icon large color="primary" v-text="link.meta.icon" />
        </v-col> -->
        <v-col cols="12">
          {{ service.name }}
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col>
              <v-card :disabled="service.status != 'Running'" @click="startService()">
                <v-card-title>
                  <v-row no-gutters>
                    <v-col cols="12">
                     <v-icon large color="green">mdi-play</v-icon>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      Start
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
           <v-col>
              <v-card :disabled="service.status == 'Running'" @click="stopService()">
                <v-card-title>
                  <v-row no-gutters>
                    <v-col cols="12">
                     <v-icon large color="red">mdi-stop</v-icon>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      Stop
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card @click="restartService()">
                <v-card-title>
                  <v-row no-gutters>
                    <v-col cols="12">
                     <v-icon large color="primary">mdi-replay</v-icon>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      Restart
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-btn :disabled="service.state == 'stopped'" class="mr-2">
            Start
          </v-btn>
          <v-btn :disabled="service.state == 'running'" class="mr-2">Stop</v-btn>
          <v-btn>Restart</v-btn> -->
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
 props: {
    service: {
      type: Object,
      required: true
    },
  },
  methods: {
    startService() {

    },
    stopService() {

    },
    restartService() {

    }
  },
}
</script>

<style>

</style>