var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      !_vm.directoryOpened
        ? [
            _c(
              "v-col",
              { attrs: { cols: "4" } },
              [
                _c(
                  "v-fade-transition",
                  [
                    _c(
                      "v-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showMenu,
                            expression: "showMenu",
                          },
                        ],
                        staticClass: "ml-1",
                        attrs: { align: "center", justify: "start" },
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-1",
                                              on: { click: _vm.numericSort },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.numericIcon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3919950233
                            ),
                          },
                          [_c("span", [_vm._v("nach Produktnummer sortieren")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-1",
                                              on: { click: _vm.alphabeticSort },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.alphabeticIcon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2761484729
                            ),
                          },
                          [_c("span", [_vm._v("nach Produktname sortieren")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-col", { attrs: { cols: "4" } }, [
              _c(
                "h2",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c("v-icon", { staticClass: "mr-1 mb-1" }, [
                    _vm._v("fas fa-tools"),
                  ]),
                  _vm._v(" Zugeordnete Produkte "),
                ],
                1
              ),
            ]),
            _c(
              "v-col",
              { attrs: { cols: "3" } },
              [
                _c(
                  "v-fade-transition",
                  [
                    _c(
                      "v-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showMenu,
                            expression: "showMenu",
                          },
                        ],
                        attrs: { align: "center", justify: "end" },
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-1",
                                              attrs: {
                                                color: _vm.moveMode
                                                  ? "primary"
                                                  : "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  ;(_vm.moveMode =
                                                    !_vm.moveMode),
                                                    (_vm.editMode = false)
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("fas fa-folder-open"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1351838712
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v("Produkte in Ordner verschieben"),
                            ]),
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-1",
                                              attrs: {
                                                color: _vm.editMode
                                                  ? "primary"
                                                  : "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  ;(_vm.editMode =
                                                    !_vm.editMode),
                                                    (_vm.moveMode = false)
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("fas fa-exchange-alt"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3035497464
                            ),
                          },
                          [_c("span", [_vm._v("Reihenfolge bearbeiten")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-1",
                                              on: {
                                                click: function ($event) {
                                                  _vm.directoryDialog = true
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("fas fa-folder-plus"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3511134909
                            ),
                          },
                          [_c("span", [_vm._v("Ordner hinzufügen")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pr-1", attrs: { cols: "1" } },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "ml-1",
                    attrs: { align: "center", justify: "start" },
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            color: _vm.showMenu
                                              ? "success"
                                              : "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleMenu.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "ml-1 mb-1" },
                                        [_vm._v("far fa-edit")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3863480403
                        ),
                      },
                      [_c("span", [_vm._v("Bearbeitungsmodus")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "draggable",
              {
                staticClass: "mx-2",
                attrs: {
                  tag: "v-row",
                  animation: 200,
                  disabled: !_vm.editMode,
                },
                model: {
                  value: _vm.sortedList,
                  callback: function ($$v) {
                    _vm.sortedList = $$v
                  },
                  expression: "sortedList",
                },
              },
              _vm._l(_vm.sortedList, function (val, i) {
                return _c(
                  "v-col",
                  { key: i, attrs: { cols: "3" } },
                  [
                    _vm.sortedList[i].type == "product"
                      ? _c("ProductCard", {
                          class: _vm.editMode || _vm.moveMode ? "shakeIt" : "",
                          attrs: {
                            editMode: _vm.editMode,
                            itemDrag: _vm.itemDrag,
                            moveMode: _vm.moveMode,
                            showMenu: _vm.showMenu,
                            isFolder: false,
                            sortedList: _vm.sortedList[i],
                          },
                          on: {
                            handleSelectedProduct: _vm.handleSelectedProduct,
                            handleDrag: _vm.handleDrag,
                            itemDragHandler: _vm.itemDragHandler,
                          },
                        })
                      : _c("DirectoryCard", {
                          class: _vm.editMode || _vm.moveMode ? "shakeIt" : "",
                          attrs: {
                            editMode: _vm.editMode,
                            moveMode: _vm.moveMode,
                            showMenu: _vm.showMenu,
                            recentDirectory: _vm.recentDirectory,
                            sortedList: _vm.sortedList[i],
                          },
                          on: {
                            handleDirectorySelection:
                              _vm.handleDirectorySelection,
                            deleteDirectory: _vm.deleteDirectory,
                            handleDrop: _vm.handleDrop,
                          },
                        }),
                  ],
                  1
                )
              }),
              1
            ),
          ]
        : [
            _c("OpenedDirectory", {
              attrs: {
                color: _vm.sortedList[_vm.directoryIndex].color.color,
                icon: _vm.sortedList[_vm.directoryIndex].icon.icon,
                sortedList: _vm.sortedList,
                directoryIndex: _vm.directoryIndex,
              },
              on: {
                handleSelectedProduct: _vm.handleSelectedProduct,
                removeFromDirectory: _vm.removeFromDirectory,
                updateDirectory: _vm.updateDirectory,
              },
            }),
          ],
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.directoryDialog,
            callback: function ($$v) {
              _vm.directoryDialog = $$v
            },
            expression: "directoryDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", [_vm._v(" Neuen Ordner erstellen ")]),
              _c(
                "v-card-actions",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Ordnername",
                          placeholder: "Neuer Ordner",
                          required: "",
                          rules: _vm.nameRules,
                        },
                        model: {
                          value: _vm.dirNameInput,
                          callback: function ($$v) {
                            _vm.dirNameInput = $$v
                          },
                          expression: "dirNameInput",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          label: "Icon",
                          items: _vm.allIcons,
                          "item-text": "name",
                          "item-value": "icon",
                          "return-object": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(" " + _vm._s(data.item.icon) + " "),
                                ]),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: _vm.dirIconColor.color },
                                  },
                                  [_vm._v(" " + _vm._s(data.item.icon) + " ")]
                                ),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dirIcon,
                          callback: function ($$v) {
                            _vm.dirIcon = $$v
                          },
                          expression: "dirIcon",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          label: "Farbe",
                          items: _vm.allColors,
                          "item-text": "name",
                          "item-value": "color",
                          "return-object": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c("v-avatar", {
                                  staticClass: "mr-1",
                                  attrs: { color: data.item.color, size: "20" },
                                }),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c("v-avatar", {
                                  staticClass: "mr-2",
                                  attrs: { color: data.item.color, size: "20" },
                                }),
                                _c("span", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dirIconColor,
                          callback: function ($$v) {
                            _vm.dirIconColor = $$v
                          },
                          expression: "dirIconColor",
                        },
                      }),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4",
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              _vm.directoryDialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4",
                          attrs: {
                            type: "submit",
                            disabled: !_vm.valid,
                            color: "success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addDirectory()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(" fas fa-folder-plus ")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }