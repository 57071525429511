<template>
  <v-row>
    <v-dialog v-model="saveDialog" max-width="600">
      <v-card tile>
        <v-card-title>
          Folgende Daten wurden erfasst:
        </v-card-title>
        <v-card-text>
          <div v-if="selectedProductIds.length > 0" class="text--primary">
            Zwischenprodukt (Pro.-Bez.):
            <br />
            <p class="text-h5">
              {{ selectedProductIds[0].name }}
            </p>
            <br />
            Eingetragende Stückzahl:
            <br />
            <p class="text-h5">{{ amount }}</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            tile
            large
            color="error"
            dark
            depressed
            @click="saveDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn tile large color="primary" dark depressed @click="onSubmit">
            Bestätigen
            <v-icon right>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="6">
      <v-card max-height="600" style="overflow-y: auto;" class="p-0">
        <v-treeview
          v-if="selectedProductIds.length > 0"
          :items="selectedProductIds"
          open-all
        >
          <template v-slot:prepend="{ item }">
            <v-row>
              <v-col
                v-if="item.number != selectedProductIds[0].number"
                cols="6"
                class="treeQuantity"
              >
                <v-label>{{ item.quantity + " × " }}</v-label>
              </v-col>
              <v-col cols="6" class="treeIMG">
                <v-img class="itemPics" :src="icons[item.productTypeId]" />
              </v-col>
            </v-row>
          </template>

          <template v-slot:label="{ item }">
            <v-row>
              <v-col cols="6" class="treeItems">
                <v-label>{{ item.name }}</v-label>
              </v-col>
              <v-col cols="12" class="treeItems">
                <v-label>{{ item.number }}</v-label>
              </v-col>
            </v-row>
          </template>
          <template v-slot:append="{ item }">
            <v-row class="pl-0 mr-0">
              <v-dialog
                v-if="item.number == selectedProductIds[0].number"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn tile color="primary" v-bind="attrs" v-on="on">
                    <v-icon>fas fa-images</v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="headline">
                      {{ item.name }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{ item.number }}
                    </v-card-subtitle>

                    <v-card-text>
                      <v-img
                        :src="
                          'http://pbpps.gselectronic.com/zp/' +
                            item.number +
                            '.jpg'
                        "
                        @error="setAltImg()"
                      />
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog.value = false">
                        Schließen
                      </v-btn>
                    </v-card-actions>
                  </v-card></template
                >
              </v-dialog></v-row
            ></template
          >
        </v-treeview>
      </v-card>
    </v-col>
    <v-col cols="auto">
      <v-divider vertical></v-divider>
    </v-col>
    <v-col>
      <NumPad
        :currentStep="currentStep"
        @setNumber="setNumpadNumber"
        @reset="resetAmount"
      >
        <NumPadTextfield
          slot="prepend"
          :currentStep="currentStep"
          :amount="amount"
          @setNumber="setTextfieldNumber"
        />
        <NumPadButtons
          slot="append"
          :amount="amount"
          :amountError="amountError"
          class="pt-4 mx-0"
          @cancel="stepToProductSelection"
          @onSubmit="saveDialog = true"
        />
      </NumPad>
    </v-col>
  </v-row>
</template>

<script>
import { watch, ref } from "@vue/composition-api";
import NumPadTextfield from "@/components/PDE/NumPadTextfield";
import NumPad from "@/components/PDE/NumPad";
import NumPadButtons from "@/components/PDE/NumPadButtons";

export default {
  components: {
    NumPadTextfield,
    NumPad,
    NumPadButtons
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    selectedUser: {
      type: Array,
      required: true
    },
    selectedProductIds: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const icons = {
      1: require("@/assets/gs-manager-tree-icon-ap.gif"),
      2: require("@/assets/gs-manager-tree-icon-zp.gif"),
      3: require("@/assets/gs-manager-tree-icon-ep.gif")
    };
    const placeholderImg = "http://pbpps.gselectronic.com/zp/507063630C.jpg";
    function setAltImg() {
      event.target.src = placeholderImg;
    }
    const amount = ref("");
    function resetAmount() {
      amount.value = "";
    }
    function setNumpadNumber(val) {
      amount.value = amount.value + val;
    }
    function setTextfieldNumber(val) {
      amount.value = val;
    }
    const amountError = ref(false);
    watch(amount, val => {
      if (val == "0" || val == "") {
        amountError.value = true;
      } else {
        amountError.value = false;
      }
    });
    const saveDialog = ref(false);
    function stepToProductSelection() {
      amount.value = "0";
      saveDialog.value = false;
      emit("stepToProductSelection");
    }
    function onSubmit() {
      const timestamp = new Date();
      emit(
        "onSubmit",
        props.selectedUser[0].id,
        props.selectedProductIds[0].id,
        amount.value,
        timestamp
      );
      stepToProductSelection();
    }
    return {
      amount,
      saveDialog,
      icons,
      setAltImg,
      resetAmount,
      setNumpadNumber,
      setTextfieldNumber,
      stepToProductSelection,
      onSubmit,
      amountError
    };
  }
};
</script>

<style>
.v-treeview .pa-4.v-sheet.theme--light label,
.v-treeview label.v-label.theme--light,
.v-treeview button,
.v-treeview input,
.v-treeview optgroup,
.v-treeview select,
.v-treeview textarea {
  color: #000;
  /* font-size: 1.3rem; */
}
.v-treeview .pa-4.v-sheet.theme--light {
  font-weight: bolder;
}
.theme--dark.v-data-table .selectedRow {
  font-weight: bolder;
  background-color: lightslategray;
}

.v-icon.v-icon:after {
  background: transparent !important;
}

.v-treeview .v-treeview-node--click > .v-treeview-node__root {
  cursor: default;
}

.treeItems {
  padding-top: 0;
  padding-bottom: 0;
  line-height: initial;
}

.v-treeview .itemPics {
  width: 65px;
}

.v-treeview-node__children .v-image.itemPics {
  width: 40px;
}
.v-treeview-node__children {
  opacity: 0.75;
}
.v-treeview-node__append {
  width: 50px;
  margin-right: 10px;
}
.row .treeItems:first-child .v-label {
  font-weight: 900;
}
.v-treeview.theme--dark .row .treeItems:first-child .v-label {
  font-weight: 400;
}
.v-treeview .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0px;
  margin-bottom: 15px;
  padding-bottom: 50px;
}
.theme--dark.v-card {
  background-color: #272727;
}
.v-treeview .v-treeview-node__content {
  background-color: #f5f5f5;
}
.v-application .theme--dark .primary--text {
  color: #25a0da !important;
  caret-color: #25a0da !important;
}
.v-treeview.theme--dark .v-treeview-node__content,
.v-treeview.theme--dark .v-treeview-node__children .treeQuantity.col.col-6,
.treeQuantity label.v-label.theme--dark {
  background-color: #272727;
}

.v-treeview.theme--dark .v-treeview-node__children .treeQuantity.col.col-6,
.treeQuantity label.v-label.theme--dark {
  background-color: #303030;
}
.treeQuantity label.v-label.theme--light,
.treeQuantity label.v-label.theme--dark {
  font-size: 1.5em;
  line-height: 1.5em;
}

.v-treeview .v-treeview-node__root {
  padding: 2px 0;
  border-top: 1px solid #25a0da;
}
.v-treeview .v-treeview-node.v-treeview-node--leaf:last-child {
  border-bottom: 1px solid #25a0da;
}
.treeIMG.col.col-6 {
  max-width: 100%;
  margin-left: 10px;
}
.v-treeview-node__children .treeIMG.col.col-6 {
  max-width: 100%;
  margin-left: 0px !important;
}
.v-treeview-node__children .treeQuantity.col.col-6 {
  background-color: #fff;
  padding-right: 10px !important;
  padding-left: 0 !important;
  max-width: fit-content;
}

.font-size input {
  font-size: 2.5em;
  font-weight: 900;
  padding-top: 1em;
  padding-bottom: 1em;
}

.customListCol.col.col-6 {
  max-height: 600px;
  margin-bottom: 20px;
  overflow-y: auto;
}
</style>
