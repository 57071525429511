<template>
  <v-row no-gutters>
    <v-col>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="showSettingsDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Einstellungen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn outlined dark text @click="openAccountSettings">
            Accountverwaltung
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row class="mx-2">
        <v-col cols="3">
          <v-card :elevation="cardElevation">
            <v-card-title>Global</v-card-title>
            <v-card-text>
              <v-slider
                v-model="cardElevation"
                label="Schattierung"
                min="0"
                max="24"
                tick-size="1"
                thumb-size="128"
              />
              <v-slider
                v-model="globalPadding"
                label="Seitenrand"
                min="12"
                max="450"
                tick-size="5"
                thumb-size="128"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  data() {
    return {
      notifications: false,
      sound: true,
      widgets: false
    };
  },
  computed: {
    showSettingsDialog: sync("app/showSettings"),
    cardElevation: sync("settings/cardElevation"),
    globalPadding: sync("settings/globalPadding")
  },
  methods: {
    openAccountSettings() {
      this.$keycloak.accountManagement();
    }
  }
};
</script>
