<template>
  <drag
    :draggable="moveMode"
    @dragstart="handleDrag(sortedList)"
    @dragend="itemDragHandler"
  >
    <v-card
      :disabled="itemDrag"
      min-height="140"
      min-width="40"
      @click.stop="handleSelectedProduct(sortedList)"
      @keydown.enter="handleSelectedProduct(sortedList)"
    >
      <v-card-title class="font-md pb-1">
        <div>
          <v-icon
            v-if="sortedList.isFavourite"
            color="#FFD700"
            class="ml-1 pb-1"
          >
            fan fa-star
          </v-icon>
          {{ sortedList.name }}
        </div>
      </v-card-title>
      <v-card-text class="font-md">
        <v-row>
          <v-col class="pt-1 pb-0">
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tabindex="-1"
                  :disabled="showMenu"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>fas fa-images</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="headline">
                    {{ sortedList.name }}
                  </v-card-title>
                  <v-card-subtitle>
                    {{ sortedList.number }}
                  </v-card-subtitle>

                  <v-card-text>
                    <v-img
                      :src="
                        'http://pbpps.gselectronic.com/zp/' +
                          sortedList.number +
                          '.jpg'
                      "
                      @error="setAltImg()"
                    />
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog.value = false">
                      Schließen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            {{ sortedList.number }}
          </v-col>
          <v-col v-if="isFolder" cols="2" class="text-right pt-1 pb-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }"
                ><v-btn
                  tabindex="-1"
                  v-bind="attrs"
                  small
                  icon
                  v-on="on"
                  @click.stop="removeFromDirectory(sortedList.id)"
                >
                  <v-icon>fas fa-folder-minus</v-icon>
                </v-btn>
              </template>
              <span>Produkt aus dem Order entfernen</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </drag>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    itemDrag: {
      type: Boolean,
      required: true
    },
    moveMode: {
      type: Boolean,
      required: true
    },
    showMenu: {
      type: Boolean,
      required: true
    },
    isFolder: {
      type: Boolean,
      required: true
    },
    sortedList: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const placeholderImg = "http://pbpps.gselectronic.com/zp/507063630C.jpg";
    function setAltImg() {
      event.target.src = placeholderImg;
    }
    function itemDragHandler() {
      if (props.editMode === false) {
        emit("itemDragHandler");
      }
    }
    function handleDrag(val) {
      if (props.editMode === false) {
        emit("handleDrag", val);
      }
    }
    function handleSelectedProduct(val) {
      if (!props.moveMode && !props.editMode && !props.showMenu) {
        emit("handleSelectedProduct", val.id);
      }
    }
    function removeFromDirectory(productId, directoryId) {
      emit("removeFromDirectory", productId, directoryId);
    }

    return {
      placeholderImg,
      setAltImg,
      itemDragHandler,
      handleDrag,
      handleSelectedProduct,
      removeFromDirectory
    };
  }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
