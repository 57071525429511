<template>
  <v-row dense>
    <v-col>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            label="Nach Produktnummer suchen"
            flat
            hide-details
            clearable
            single-line
            clear-icon="mdi-close-circle-outline"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="products"
            no-results-text
            item-key="number"
            class="cursor-pointer"
            @click:row="handleSelectedProduct"
          >
            <template v-slot:item.ProductTypeId>
              <img :src="icon" width="30px" style="vertical-align: middle" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "@vue/composition-api";
export default {
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const headers = [
      {
        text: "Typ",
        align: "start",
        sortable: true,
        value: "ProductTypeId"
      },
      { text: "Name", value: "name" },
      { text: "Produkt-Nr:", value: "number" }
    ];
    const icon = require("@/assets/gs-manager-tree-icon-zp.gif");
    const search = null;
    const items = computed(() => props.products);
    function handleSelectedProduct(val) {
      emit("handleSelectedProduct", val.id);
    }
    return { headers, icon, search, items, handleSelectedProduct };
  }
};
</script>

<style>
.cursor-pointer tr {
  cursor: pointer;
}
</style>
