export default [
    {
      name: "Service1",
      status: "Running"
    },
    {
      name: "Service2",
      status: "stopped"
    },
    {
      name: "Service3",
      status: "Running"
    }
  ];
