var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [_c("v-card-title", [_vm._v(" Übsersicht ")])],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.tableSearch,
                  callback: function ($$v) {
                    _vm.tableSearch = $$v
                  },
                  expression: "tableSearch",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                staticClass: "justify-center my-2",
                attrs: {
                  search: _vm.tableSearch,
                  headers: _vm.tableHeaders,
                  items: _vm.canceledCallnumbers,
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.actions",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", color: "error" },
                            on: {
                              click: function ($event) {
                                return _vm.callnumberDelete(item)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("fan fa-trash")])],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.IstGekuendigt",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.IstGekuendigt ? [_vm._v("Ja")] : _vm._e(),
                        !item.IstGekuendigt ? [_vm._v("Nein")] : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }