var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "xlsx-read",
        { attrs: { file: _vm.file } },
        [
          _c(
            "xlsx-json",
            { on: { parsed: _vm.parsed } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mainRow" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: 4, order: "first" } },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade" } },
                            [
                              _vm.leftTable
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "colCard mt-1",
                                      attrs: { elevation: _vm.cardElevation },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    transition:
                                                      "fade-transition",
                                                    bottom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "h3",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mt-4 mb-2",
                                                                  },
                                                                  "h3",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " Eingabe "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    696556512
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      ' Bereits nach Merker X gefiltert und nach "Zuletzt bei GS Eing. / auf X" sortiert '
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "tableRow" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: 12 } },
                                            [
                                              _c(
                                                "v-simple-table",
                                                {
                                                  staticClass: "simpleTable",
                                                  attrs: { height: "38em" },
                                                },
                                                [
                                                  _c("tr", [
                                                    _c("th", [
                                                      _vm._v("Nummer"),
                                                    ]),
                                                    _c("th", [
                                                      _vm._v("Callnummer"),
                                                    ]),
                                                    _c("th", [
                                                      _vm._v("Projektnummer"),
                                                    ]),
                                                  ]),
                                                  _vm._l(
                                                    _vm.inputArray,
                                                    function (e2, index, idx) {
                                                      return _c(
                                                        "tr",
                                                        { key: idx },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(index + 1)
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                e2.Callnummer
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                e2.Projektnummer
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: 4, order: "last" } },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade" } },
                            [
                              _vm.rightTable
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "colCard mt-1",
                                      attrs: { elevation: _vm.cardElevation },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    transition:
                                                      "fade-transition",
                                                    bottom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "h3",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mt-4 mb-2",
                                                                  },
                                                                  "h3",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " Ausgabe "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    153607333
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " Vorschau der fertigen CSV Datei "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "tableRow" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: 12 } },
                                            [
                                              _c(
                                                "v-simple-table",
                                                {
                                                  ref: "outTable",
                                                  staticClass: "simpleTable",
                                                  attrs: {
                                                    id: "outTableId",
                                                    height: "38em",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.inArrayFinalFiltered,
                                                  function (
                                                    outTableArray,
                                                    index,
                                                    i
                                                  ) {
                                                    return _c(
                                                      "tr",
                                                      { key: i },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(index + 1)
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            '"' +
                                                              _vm._s(
                                                                outTableArray.Callnummer
                                                              ) +
                                                              '"'
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            '"' +
                                                              _vm._s(
                                                                _vm.hexAdress
                                                              ) +
                                                              '"'
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            '"' +
                                                              _vm._s(
                                                                _vm.dataLength
                                                              ) +
                                                              '"'
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            '"' +
                                                              _vm._s(
                                                                _vm.recordType
                                                              ) +
                                                              '"'
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: 4 } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "colCard mt-1",
                              attrs: { elevation: _vm.cardElevation },
                            },
                            [
                              _c("v-row", { staticClass: "mx-auto" }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "mt-4 mb-2",
                                    attrs: { id: "manual" },
                                  },
                                  [_vm._v("Bearbeitung")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                { staticClass: "interactiveRow mt-2 mb-auto" },
                                [
                                  _c("v-file-input", {
                                    staticClass: "mx-0",
                                    attrs: {
                                      label: "Excel Datei aus CRM",
                                      placeholder: "Datei hochladen...",
                                      "clear-icon": "",
                                      "prepend-icon": "",
                                      "append-icon": "",
                                      "append-outer-icon": "fas fa-upload",
                                      outlined: "",
                                      dense: "",
                                      accept: ".xlsx, .xls, .csv",
                                    },
                                    on: { change: _vm.onChange },
                                    model: {
                                      value: _vm.file,
                                      callback: function ($$v) {
                                        _vm.file = $$v
                                      },
                                      expression: "file",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "interactiveRow mt-1" },
                                [
                                  _c("v-combobox", {
                                    staticClass: "CallnummerField mt-1",
                                    attrs: {
                                      rules: _vm.rulesPN,
                                      items: _vm.uniqueProjNr,
                                      disabled: !_vm.leftTable,
                                      label: "Projektnummer",
                                      placeholder: " ",
                                      "append-icon": "",
                                      "append-outer-icon": "fas fa-filter",
                                      outlined: "",
                                      dense: "",
                                      maxlength: "6",
                                    },
                                    on: { change: _vm.dataFilter },
                                    model: {
                                      value: _vm.inputPN,
                                      callback: function ($$v) {
                                        _vm.inputPN = $$v
                                      },
                                      expression: "inputPN",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "interactiveRow mt-1" },
                                [
                                  _c("v-combobox", {
                                    staticClass: "CallnummerField mt-1",
                                    attrs: {
                                      items: _vm.uniqueCallNr,
                                      disabled: !_vm.rightTable,
                                      rules: _vm.rulesCN,
                                      label: "Callnummer",
                                      placeholder: " ",
                                      "append-icon": "",
                                      "hide-details": !_vm.rightTable,
                                      "append-outer-icon":
                                        "fas fa-greater-than-equal",
                                      outlined: "",
                                      dense: "",
                                      maxlength: "6",
                                    },
                                    on: { change: _vm.limitCN },
                                    model: {
                                      value: _vm.inputStartAtCN,
                                      callback: function ($$v) {
                                        _vm.inputStartAtCN = $$v
                                      },
                                      expression: "inputStartAtCN",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "downloadButton" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "my-6",
                                      attrs: {
                                        disabled: !_vm.rightTable,
                                        color: "primary",
                                      },
                                      on: { click: _vm.tableToExcel },
                                    },
                                    [_vm._v(" Tabelle Herunterladen ")]
                                  ),
                                  _c("vue-blob-json-csv", {
                                    staticClass: "invisibleButtons",
                                    attrs: {
                                      id: "excelDownload",
                                      "tag-name": "button",
                                      "file-type": "csv",
                                      "file-name": _vm.fileName,
                                      title: "test",
                                      data: _vm.outResult,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "interactiveRow mt-4" },
                                [
                                  _c("v-switch", {
                                    attrs: { disabled: !_vm.rightTable },
                                    on: { mousedown: _vm.advancedChange },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("div", [
                                              _c(
                                                "strong",
                                                {
                                                  staticClass:
                                                    "primary--text text--lighten-1",
                                                },
                                                [_vm._v(" weitere Optionen ")]
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.advancedEdit,
                                      callback: function ($$v) {
                                        _vm.advancedEdit = $$v
                                      },
                                      expression: "advancedEdit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "interactiveRow" },
                                [
                                  _c("v-text-field", {
                                    staticClass: "my-0",
                                    attrs: {
                                      disabled: !_vm.advancedEdit,
                                      label: "Adresse",
                                      "append-icon": "",
                                      "append-outer-icon":
                                        "fas fa-location-arrow",
                                      placeholder: "FFE0",
                                      outlined: "",
                                      dense: "",
                                      maxlength: "8",
                                    },
                                    on: { change: _vm.hexInput },
                                    model: {
                                      value: _vm.hexAdressInput,
                                      callback: function ($$v) {
                                        _vm.hexAdressInput = $$v
                                      },
                                      expression: "hexAdressInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "interactiveRow" },
                                [
                                  _c("v-select", {
                                    staticClass: "my-1 pt-0",
                                    attrs: {
                                      disabled: !_vm.advancedEdit,
                                      outlined: "",
                                      dense: "",
                                      "append-icon": "",
                                      "append-outer-icon": "fas fa-list-ol",
                                      label: "Datenlänge",
                                      items: _vm.dataLengthList,
                                      placeholder: "3",
                                      editable: "",
                                    },
                                    on: { change: _vm.lengthInput },
                                    model: {
                                      value: _vm.dataLengthInput,
                                      callback: function ($$v) {
                                        _vm.dataLengthInput = $$v
                                      },
                                      expression: "dataLengthInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "interactiveRow" },
                                [
                                  _c("v-select", {
                                    staticClass: "my-1",
                                    attrs: {
                                      disabled: !_vm.advancedEdit,
                                      outlined: "",
                                      dense: "",
                                      "append-icon": "",
                                      "append-outer-icon":
                                        "fas fa-project-diagram",
                                      label: "Record Type",
                                      items: _vm.recordTypeList,
                                      placeholder: "B",
                                      editable: "",
                                    },
                                    on: { change: _vm.recordInput },
                                    model: {
                                      value: _vm.recordTypeInput,
                                      callback: function ($$v) {
                                        _vm.recordTypeInput = $$v
                                      },
                                      expression: "recordTypeInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ResetButton mt-1" },
                                [
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: { width: "500" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" Zurücksetzen ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.dialogState,
                                        callback: function ($$v) {
                                          _vm.dialogState = $$v
                                        },
                                        expression: "dialogState",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "headline grey lighten-2",
                                            },
                                            [
                                              _vm._v(
                                                " Wirklich alle Eingaben zurücksetzten? "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.dialogState = false
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Abbrechen ")]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.dialogState = false),
                                                        _vm.reloadPage()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Zurücksetzen ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }