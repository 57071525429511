<template>
  <v-row class="mx-2">
    <v-col cols="2"></v-col>
    <v-col cols="8">
      <h2 align="center" justify="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"
            ><v-btn
              class="mx-1 mb-1"
              v-bind="attrs"
              v-on="on"
              @click="directoryDialog = true"
            >
              <v-icon :color="color">{{ icon }}</v-icon>
            </v-btn>
          </template>
          <span>Ordner bearbeiten</span>
        </v-tooltip>
        Zugeordnete Produkte/{{ sortedList[directoryIndex].name }}
      </h2> </v-col
    ><v-col cols="2"></v-col>
    <v-row>
      <v-col
        v-for="(val, i) in sortedList[directoryIndex].products"
        :key="i"
        cols="3"
      >
        <ProductCard
          :editMode="false"
          :itemDrag="false"
          :moveMode="false"
          :showMenu="false"
          :class="''"
          :isFolder="true"
          :recentDirectory="0"
          :sortedList="sortedList[directoryIndex].products[i]"
          @handleSelectedProduct="handleSelectedProduct"
          @removeFromDirectory="removeFromDirectory"/></v-col
    ></v-row>
    <v-dialog v-model="directoryDialog" max-width="300">
      <v-card tile>
        <v-card-title>
          Ordner bearbeiten
        </v-card-title>
        <v-card-actions align="center" justify="center">
          <v-form ref="form" v-model="valid" @submit.prevent>
            <v-text-field
              v-model="dirNameInput"
              label="Ordnername"
              placeholder="Neuer Ordner"
              required
              :rules="nameRule"
            >
            </v-text-field>
            <v-select
              v-model="selectedIcon"
              label="Icon"
              :items="allIcons"
              item-text="name"
              item-value="icon"
              return-object
            >
              <template slot="item" slot-scope="data">
                <v-icon class="mr-2"> {{ data.item.icon }} </v-icon
                ><span>{{ data.item.name }}</span>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon class="mr-1" :color="selectedIconColor.color">
                  {{ data.item.icon }} </v-icon
                ><span>{{ data.item.name }}</span>
              </template>
            </v-select>
            <v-select
              v-model="selectedIconColor"
              label="Farbe"
              :items="allColors"
              item-text="name"
              item-value="color"
              return-object
            >
              <template slot="item" slot-scope="data">
                <v-avatar :color="data.item.color" size="20" class="mr-2" />
                <span>{{ data.item.name }}</span>
              </template>
              <template slot="selection" slot-scope="data">
                <v-avatar :color="data.item.color" size="20" class="mr-2" />
                <span>{{ data.item.name }}</span>
              </template>
            </v-select>
            <v-text-field v-show="false">
              <!--empty v-form can't handle single input forms-->
            </v-text-field>
            <v-btn color="error" class="mx-4" @click="directoryDialog = false">
              Abbrechen
            </v-btn>
            <v-btn
              type="submit"
              :disabled="!valid"
              color="success"
              class="mx-4"
              @click="
                updateDirectory(
                  sortedList[directoryIndex].id,
                  dirNameInput,
                  selectedIcon,
                  selectedIconColor
                )
              "
            >
              <v-icon>
                fas fa-check
              </v-icon>
            </v-btn>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ProductCard from "@/components/PDE/ProductCard.vue";
import { icons } from "@/data/pdeIcons";
import { colors } from "@/data/pdeIcons";
import { ref, watch } from "@vue/composition-api";
export default {
  components: {
    ProductCard
  },
  props: {
    sortedList: {
      type: Array,
      required: true
    },
    directoryIndex: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const directoryDialog = ref(false);
    const dirNameInput = ref("");
    const selectedIcon = ref({});
    const selectedIconColor = ref({});
    const allIcons = icons;
    const allColors = colors;
    const valid = ref(true);
    const nameRule = [v => (v || "").length >= 1 || "Pflichtfeld"];
    watch(
      () => directoryDialog.value,
      newValue => {
        if (newValue === true) {
          dirNameInput.value = props.sortedList[props.directoryIndex].name;
          selectedIcon.value = props.sortedList[props.directoryIndex].icon;
          selectedIconColor.value =
            props.sortedList[props.directoryIndex].color;
        }
      }
    );
    function updateDirectory(directoryId, directoryName, icon, color) {
      directoryDialog.value = false;
      emit("updateDirectory", directoryId, directoryName, icon.id, color.id);
    }
    function removeFromDirectory(productId) {
      emit(
        "removeFromDirectory",
        productId,
        props.sortedList[props.directoryIndex].id
      );
    }
    function handleSelectedProduct(productId) {
      emit("handleSelectedProduct", productId);
    }
    return {
      updateDirectory,
      removeFromDirectory,
      handleSelectedProduct,
      directoryDialog,
      dirNameInput,
      selectedIcon,
      selectedIconColor,
      allIcons,
      allColors,
      valid,
      nameRule
    };
  }
};
</script>
<style scoped></style>
