var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { staticClass: "mx-2", attrs: { cols: "4" } },
                [
                  _c("v-card-title", [_vm._v("Rahmenvertrag speichern")]),
                  _c("SimFileUpload", {
                    attrs: { label: _vm.labelContracts, icon: _vm.icon },
                    on: { parsed: _vm.addContract },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("v-divider", { attrs: { vertical: "" } })],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mx-2", attrs: { cols: "7" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h5 font-weight-bold" },
                        [_vm._v("Vorschau")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-data-table", {
                        staticClass: "justify-center my-2",
                        attrs: {
                          headers: _vm.tableHeaders,
                          items: _vm.uploadedContractDetails,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.sendData()
                            },
                          },
                        },
                        [_vm._v("Speichern")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }