import Vue from "vue";
import axios from "axios";

const api = axios.create();

api.interceptors.request.use(
  request => {
    const keycloak = Vue.prototype.$keycloak;
    if (keycloak) {
      const token = keycloak.token;
      if (token) {
        request.headers.GSSecurity =
          "C553C02C - 6828 - 42AE - 8CEB - 2FE3397B678D";
      }
    }
    return request;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default {
  getEventsData: function(baseUrl, callnumber, userData) {
    return api.get(baseUrl + "/el?limit=0&offset=0" + callnumber, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json"
      },
      userData: userData
    });
  },
  getCallnumbers: function(baseUrl, userData) {
    return api.get(baseUrl + "/cn", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json"
      },
      userData: userData
    });
  },
  getStats: function(baseUrl) {
    return api.get(baseUrl + "/stats", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/vnd.example.v1+json"
      }
    });
  }
};
